import { menuData, brandLink } from "../global/constants"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";


import labelImg from '../assets/images/label.png'

import { useSelector, useDispatch } from 'react-redux';




const Role = (props) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const location = useLocation()

    const dispatch = useDispatch();

    const role = useSelector(state => state.role);


    useEffect(() => {
        //asd()
    }, [])

    return <div className="name">{t("message.role") }：<span>{ t(`message.${role}`) }</span><span className="label-icon"><img className="hidden" src={labelImg} alt=""/></span></div>

}
export default Role