import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "../src/assets/css/all.css"
import "../src/assets/css/animate.css";
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import Header from './compontents/Header'
import Footer from './compontents/Footer'


import Home from './pages/Home'
import Game from './pages/Game'
import Animation from './pages/Animation'
import Bid from './pages/Bid'
import BidHistory from './pages/BidHistory'
import Buy from './pages/Buy'
import Dividend from './pages/Dividend'
import Mynft from './pages/Mynft'
//import Recommend from './pages/Recommend'
import Market from './pages/Market'
import LeaderBoard from './pages/LeaderBoard'
import Arena from './pages/Arena'
import ComingSoon from './pages/ComingSoon'

import Loading from './compontents/Loading'



import "./i18n"

import { useTranslation } from "react-i18next";
import { t } from "react-i18next";



import { BrowserRouter ,Routes , Route ,Navigate} from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import './firebase.js';

import { Provider } from "react-redux";
import { store } from "./model/store.js";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}> 
  <BrowserRouter >

   <ScrollToTop/>
  <Header />



    <div className='w-full relative'>
      <Loading></Loading>
  <Routes>
  
  <Route path="/game" element={<Game/>}></Route>
  <Route path="/animation" element={<Animation/>}></Route>
  <Route path="/bid" element={<Bid/>}></Route>
  <Route path="/bidhistory" element={<BidHistory/>}></Route>
  <Route path="/buy" element={<Buy/>}></Route>
  <Route path="/dividend" element={<Dividend/>}></Route>
  <Route path="/mynft" element={<Mynft/>}></Route>
  <Route path="/arena" element={<ComingSoon/>}></Route>
  <Route path="/market" element={<Market/>}></Route>
  <Route path="/leaderboard" element={<ComingSoon/>}></Route>

  <Route path="/" element={<Home/>}></Route>
  <Route path='*' element={<Navigate to='/' replace />} />

  </Routes>
  </div>
  <Footer />

  </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
