export const zh = {
  toast: {
    "mintedPet": "已經鑄造過聖殿騎寵，限量一隻",
    'switch bsc mainnet': "請使用BSC幣安智能鏈",
    'stake success': "質押成功",
    'stake error': "質押失敗",
    'stake cancel': "取消質押",
    'unstake success': "解除質押成功",
    'unstake error': "解除質押失敗",
    'unstake cancel': "取消解除質押",
    'buy error': "購買失敗",
    'buy error1': "購買失敗",
    'buy success': "購買成功",
    'buy cancel': "取消購買",
    'connect wallet': "請先連結錢包",
    'Not enough token': "您沒有足夠的代幣",
    'Not enough PACO': "您沒有足夠的PACO",
    'Not enough USDT': "您沒有足夠的USDT",
    'return calculating': "眾籌失敗，退回的金額需約30分鐘計算中，請稍等。",
    'notAllowClaimCenter': "眾籌失敗後可解除質押",
    "chooseNFT": "請選擇尚未質押的NFT",
    "chooseStakedNFT": "請選擇已質押的NFT",
    "withdraw0PACO": "沒有需要提款的PACO",
    "withdraw0USDT": "沒有需要提款的USDT",
    "withdraw success": "提款成功",
    "withdraw fail": "提款失敗",
    'withdraw cancel': "取消提款",
    "in bid Time": "眾籌正在進行中，尚未眾籌失敗。",
    "bid submit success": "提交眾籌成功",
    "bid submit fail": "提交眾籌失敗",
    "bid submit cancel": "取消提交眾籌",
    "bid rule1": "參加眾籌遊戲需要最少 100 USDT",
    "bid rule2": "參加眾籌遊戲需要百位整數USDT",

    "waitbid": "眾籌結束後開放",

    "noNetwork": "網路異常，請刷新網站重試",

    "copySuccess": "複製成功"


  },
  message: {
    importToken: "匯入",
    showAd1Title: "請鑄造一隻NFT並質押以便開始遊戲",
    showAd1Option1: "前往鑄造",
    showAd1Option2: "前往質押",

    $PACO: "購買 $PACO",
    loading: '處理中',
    needInputAddress: "請貼上正確的地址",
    needConnectWallet: "請先連結錢包",
    needChain: "請使用BSC幣安智能鏈",
    timer_day: "天",
    timer_hour: "時",
    timer_minute: "分",
    timer_second: "秒",
    partClaim: "強制出金",
    allClaim: "100%出金",
    claimPeriod: "已出金期數",
    claimValue: "出金金額",


    clickOpen: "點擊開啟",
    picked: "已選擇",
    nftunit: "張",


    dividendCue1: "目前未能獲得紅利",
    dividendCue2: "目前只能獲得",
    dividendCue3: "之紅利",
    dividendCue4: "目前能獲得",

    // 歷史眾籌
    bidhistory_title: '待領金額',
    claimed: "已提款",

    // 主選單
    home: '首頁',
    game: '遊戲',
    market: '市場',
    crypto: '白皮書',
    recommend: '我的推薦',
    buy$PACO: '購買 $PACO',
    miltNFT: '鑄造 NFT',
    linkWallet: '連結錢包',

    // 時間
    timeline_caption_1: '遊戲開始剩餘時間',
    timeline_caption_2: '結束時間倒數',
    timeline_caption_3: '遊戲已開始 倒數',
    timeline_caption_4: '眾籌結束剩餘時間',
    timeline_day: '天',
    timeline_hour: '時',
    timeline_minute: '分',
    timeline_second: '秒',
    start: '開始',
    end: '結束',
    reciprocal: '倒數',
    finish: '完成',

    //共用
    yes: '是',
    no: '否',
    confirm: '確認',
    cancel: '取消',
    understand: '了解',
    submit: '提交',

    // 首頁選單
    caption: '說明',
    characters: '召喚角色',
    features: '特點',
    map: '路線圖',
    team: '合作夥伴及投資者',

    // 首頁banner
    banner_caption_1: '歡迎來到 CryptoPaco',
    banner_caption_2: '永續循環的遊戲',
    banner_caption_3: '實現真正的邊玩邊賺',
    start_game: '開始遊戲',

    // 首頁說明
    caption_text_1: '「Crypto Paco是一個創建在Binance鏈上的NFT藝術品，蒐藏家可以進行召喚種族當您購買CryptoPaco NFT時，您不僅僅是購買一件藝術品，而是讓Paco成為您的信仰，另外增加定期舉辦聖殿眾籌、競技賽、抽獎、國家會議、聯盟，讓持有CryptoPaco NFT的您享有最大的利潤，甚至有機會參與世界殿堂大會享受至高無上的榮譽。',
    caption_text_2: '隨著CryptoPaco NFT持有者的人數增加，價格也會隨之上漲，智能合約寫入調漲機制，使早期持有者享受豐碩的果實，CryptoPaco是基於一種革命性的演算法編寫並帶來了高額回報。」',

    // 召喚角色
    characters_name_1: '精靈軍團',
    characters_name_2: '聖騎士軍團',
    characters_name_3: '魔族軍團',

    mounts_name_1: '星空騎寵',
    mounts_name_2: '大地騎寵',
    mounts_name_3: '藍天騎寵',

    // 特點
    features_title_1: '聖殿眾籌遊戲',
    features_text_1_1: '你可以使用USDT遊玩聖殿眾籌遊戲',
    features_text_1_2: '眾籌成功將獲得10%~15%獎勵',

    features_title_2: '鑄造 NFT',
    features_text_2_1: 'MountNFT提供10,000張免費鑄造',
    features_text_2_2: '可以使用USDT鑄造專屬於你的CryptoPacoNFT',

    features_title_3: '全球股東',
    features_text_3_1: '質押PACOCoin為CryptoPaco的股東',
    features_text_3_2: '依照持有數量獲得項目的利潤',

    features_title_4: '聯盟',
    features_text_4_1: '只要是三大種族的「使者」都可以創建自己的聯盟，也開放所有階級的三大種族都可參加',

    features_title_5: '社交',
    features_text_5_1: '你可以與全球玩家一起邊玩邊賺遊戲',
    features_text_5_2: '並且可以與全世界玩家合作贏取獎金',

    features_title_6: '競技場',
    features_text_6_1: '你可以參與宙斯碎片、智慧之泉暗黑之神的逆襲、創世聯盟戰等活動',
    features_text_6_2: '總獎金超過15億美元',

    // 路線圖
    map_title_1: '第一階段',
    map_txt_1_1: 'ICO啟動',
    map_txt_1_2: '測試網站上線',
    map_txt_1_3: '啟動社交媒體',
    map_txt_1_4: 'Paco Mounts NFT白名單部屬',

    map_title_2: '第二階段',
    map_txt_2_1: '智能合約部屬最終測試',
    map_txt_2_2: '正式網站上線',
    map_txt_2_3: 'Paco Mounts NFT開放鑄造',
    map_txt_2_4: '聖殿建造眾籌啟動',
    map_txt_2_5: '開啟代幣股份功能',

    map_title_3: '第三階段',
    map_title_3_h4: 'CryptoPacoNFT白名單部屬 ',
    map_title_3_p: 'CryptoPacoNFT開放鑄造',
    map_txt_3_1: '智慧之泉',
    map_txt_3_2: '宙斯碎片',
    map_txt_3_3: '黑暗之神的逆襲',
    map_txt_3_4: '創世聯盟戰',

    map_title_4: '第四階段',
    map_txt_4_1: 'NFT市場',
    map_txt_4_2: 'DeFi金融部屬',
    map_txt_4_3: '開啟世界殿堂會議',
    map_txt_4_4: '專屬APP啟動計畫',

    map_title_5: '第五階段',
    map_txt_5_1: '區塊鏈土地模式',
    map_txt_5_2: 'NFT虛實整合平台',

    //遊戲選單
    getNFT: '獲得NFT',
    myNFT: '我的NFT',
    bid: '眾籌遊戲',
    arena: '競技場',
    leaderboard: '排行榜',
    dividend: '紅利',
    role: '稱號',
    adventurer: "勇者",
    brave: '軍團',
    envoys: '使者',
    prophet: '先知',
    holySpirit: '聖靈',
    norole: '無',
    bidhistory: "待領金額",

    // 獲得NFT
    getNFT_title: '立即獲得屬於你的NFT',
    getNFT_MountNFT: '聖殿騎寵',
    free_milt: '免費鑄造',
    NFT_price: '現在價格',
    NFT_unit: '隻',
    milt: '鑄造',
    miltx3: '鑄造3隻',
    copy: '複製邀請',
    paste: '貼上邀請',
    getNFT_modal_1: '鑄造完成後是否直接質押遊戲？',
    getNFT_modal_2: '目前鑄造人數已滿，於UTC+ 0重置',

    // 動畫
    getNFT_text_1: '恭喜您獲得NFT',
    getNFT_text_2: '到',
    getNFT_text_3: '進行質押？',
    getNFT_text_4: '是否直接質押遊戲？',

    // $PACO
    buy_title: '預售',
    overage: '餘額',
    max: '最大值',
    buy: '購買',
    pledge: '質押',
    onpledge: '已質押',
    notpledge: '未質押',
    pledge_remove: '解除質押',
    remove: '解除',
    fixed: '鎖定',
    pledge_title: '質押、賺取、以及更多驚喜',
    pledge_use: '可用',
    stock: '股份比例',
    pledge_total: '質押總計',
    profit: '利潤',
    extract_profit: '提取利潤',
    buy_text_1: '購買完成後是否直接質押遊戲？',
    buy_text_2: '目前無法購買，於UTC+ 0重置',
    buy_success: '購買成功',

    // 我的NFT
    myNFT_text_1: '您是否確認質押？質押NFT將進入遊戲並可能為您帶來收益',
    myNFT_text_2: '您是否確認退出質押？退出質押後您的收益可能將會停止',
    myNFT_search: 'NFT搜尋',
    myNFT_choose: '選擇NFT',
    myNFT_all: '全部NFT',
    director_badge: '世界董事徽章',
    enter_pledge: '進入質押',
    quit_pledge: '退出質押',
    sell: '賣出',

    // 我的推薦+紅利
    my_recommend: '我的推薦',
    share_name: '分享',
    my_bonus: '我的紅利',
    bonus_claimed: '已領紅利',
    my_pet_bonus: '我的眾籌紅利',
    pet_bonus_claimed: '已領眾籌紅利',
    my_paco_bonus: '我的NFT紅利',
    paco_bonus_claimed: '已領NFT紅利',
    bonus_unaccalimed: '未領紅利',
    withdrawal: '提款',
    bid_temple: '聖殿眾籌紅利',
    bid_CryptoPaco: 'CryptoPacoNFT紅利',
    person_num: '人數',
    current_bonus: '本期紅利',
    total_bonus: '累計紅利',
    first: '第一代',
    second: '第二代',
    third: '第三代',
    fourth: '第四代',
    fifth: '第五代',
    sixth: '第六代',
    seventh: '第七代',
    eighth: '第八代',
    ninth: '第九代',
    tenth: '第十代',

    gen1: '第一代',
    gen2: '第二代',
    gen3: '第三代',
    gen4: '第四代',
    gen5: '第五代',
    gen6: '第六代',
    gen7: '第七代',
    gen8: '第八代',
    gen9: '第九代',
    gen10: '第十代',
    copy_title: '我的邀請連結',
    total: '總計',
    arena_bonus: '競技場紅利',
    world_bonus: '世界董事紅利',


    // 眾籌遊戲
    bid_title_1: '第',
    bid_title_2: '期',
    bid_title_3: '眾籌',
    bid_title_4: '輪次',
    bided: '已投入',
    period: '期數',
    bid_amount: '總眾籌金額',
    own: '當前擁有',
    total: "總計",
    initial_period: '初始期數',
    initial_principal: '當期投入本金',
    current_amount: '當前金額',
    expected_profit: '預計利潤',
    withdraw: '出金',
    withdrawable: '可出金',
    apply_withdraw: '申請出金',
    waiting: '等待中',
    bid_user_num: '用戶數',
    bid_trade_num: '交易數',
    operate: '操作',
    progress_percent: "完成進度",
    progress_done: "已完成",
    progress_undone: "未完成",
    counting: "計算中",


    // 提交
    bid_modal_1_text_1: '您是否確認參加眾籌',
    bid_modal_1_text_2: '參與眾籌往後每期將自動複投',
    bid_modal_2_text: '眾籌成功',
    bid_modal_3_text: '參與眾籌最低金額為',
    bid_modal_4_text_1: '您已超出眾籌參與金額，請按',
    bid_modal_4_text_2: '獲得CryptoPacoNFT',
    bid_modal_4_text_3: '並質押或於下期可再次參與眾籌',
    bid_modal_5_text_1: '本期眾籌已滿額，下期請盡早搶購',
    // 出金
    bid_modal_6_text_1: '您可選擇出金方式',
    bid_modal_6_text_2: '出金將收取3%智能合約調用費',
    full_staked: '100%出金',
    obliged_staked: '強制出金',
    full_staked_text: '您需要等待3期眾籌成功後出金',
    obliged_staked_text_1: '強制出金三期退回70%眾籌金額，',
    obliged_staked_text_2: '您可出金的金額為',
    withdrawTitle: "眾籌失敗，您可以透過下方領取本金及利息",
    withdrawTitle2: "*新的一輪眾籌遊戲即將展開，第一期從金額10,000 USDT開始遊戲，敬請搶購",
    withdraw70: "期眾籌失敗，你能收回金額",
    withdraw100: "投入後當期眾籌失敗，將100%收回金額",

    upgrade: "達到升級條件後再次進行眾籌遊戲即可升級角色"

  },
  claimDialog: {
    claim1: "提取成功",
    claim2: "共提取",
    confirm: "確認",
  }
}