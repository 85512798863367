

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import ComingSoon from '../../assets/images/ComingSoon.png'

import NavGame from "../../compontents/NavGame.js"


const LeaderBoard = () => {
    const { t, i18n } = useTranslation();


    useEffect(() => {



    }, []);


    return <div id="app">


        <section className="game-box comingSoon">
        <div className="container">
            <NavGame />
        </div>

            <div className="game-content ">
                <h2><img src={ComingSoon} alt="" /></h2>
            </div>
        </section>



    </div>
}

export default LeaderBoard