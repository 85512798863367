

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    useNavigate,
    Switch,
    Route,
    Link,
    useSearchParams
} from "react-router-dom";

import NavGame from "../../compontents/NavGame.js"
import InviteBox from "../../compontents/InviteBox.js"


import Role from "../../compontents/Role.js"


import payImg from '../../assets/images/pay.png'
import eggImg from '../../assets/images/egg1.png'

import sprite1 from '../../assets/images/sprite1.png'
import sprite2 from '../../assets/images/sprite2.png'

import warrior2 from '../../assets/images/warrior2.png'
import warrior3 from '../../assets/images/warrior3.png'


import demon1 from '../../assets/images/demon1.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3"
import axios from 'axios'

import { unit, needChainId, usdtAddress, heroAddress, petAddress } from "../../global/constants"

import { useSelector, useDispatch } from 'react-redux';


const Game = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const walletAddress = useSelector(state => state.walletAddress);

    const [buyType, setBuyType] = useState(0)

    const isCardAnim = useRef(false)

    const loading = useSelector(state => state.loading);
    const petAbi = require("../../assets/contract/pet.json");
    const heroAbi = require("../../assets/contract/hero.json");
    const usdtAbi = require("../../assets/contract/usdt.json");


    const [cryptoPacoPrice, setCryptoPacoPrice] = useState("350")

    const [inviteCode, setInviteCode] = useState("")



    var web3 = new Web3(Web3.givenProvider);

    var USDTContract = new web3.eth.Contract(
        usdtAbi,
        usdtAddress
    );

    var HeroContract = new web3.eth.Contract(
        heroAbi,
        heroAddress
    );

    var PetContract = new web3.eth.Contract(
        petAbi,
        petAddress
    );

    const [closeTime, setCloseTime] = useState((1656504000) - Math.floor(Date.now() / 1000))

    const buyStage = 1




    let btn = document.querySelector('.game-btn');
    let menu = document.querySelector('.game-nav');
    let miltmodal = document.querySelector('.milt-check-modal');
    let miltmask = document.querySelector('.milt-check-modal .mask');
    let unmiltmodal = document.querySelector('.unmiltmodal');
    let unmiltmask = document.querySelector('.unmiltmodal .mask');
    let pay_content = document.querySelector('.pay-content');
    //btn.onClick =function(){
    //menu.classList.toggle('active');
    //}

    function checkmodal() {
        miltmodal.classList.toggle('check');
    }
    //miltmask.onClick =function(){
    //miltmodal.classList.toggle('check');
    //}
    function close_milt_modal() {
        miltmodal.classList.toggle('check');
    }

    function unmilt() {
        unmiltmodal.classList.add('check');
        if (unmiltmodal.classList.contains("check")) {
            miltmodal.classList.toggle('check');

        };
    }
    function closemodal() {
        unmiltmodal.classList.toggle('check');
    }
    // unmiltmask.onClick =function(){
    //     unmiltmodal.classList.toggle('check');
    // }
    function pay() {
        pay_content.classList.toggle('check');
        miltmodal.classList.toggle('check');
    }

    async function pasteInviteCode() {

        let address = await navigator.clipboard.readText();
        if (Web3.utils.isAddress(address)) {
            setInviteCode(address)
        }
        else {
            toast.error(t('message.needInputAddress'))
        }

    }



    function mintAction(cardAnim, type) {
        if (loading == true) {
            return
        }
        if (cardAnim == true) {
            isCardAnim.current = true
        }
        else {
            isCardAnim.current = false
        }
        console.log(isCardAnim.current)
        //miltmodal.classList.toggle('check');

        if (type == 0) {
            petMintAction()
        }
        else if (type == 1) {
            heroMintAction(1)
        }
        else if (type == 2) {
            heroMintAction(3)
        }


    }

    async function getCryptoPacoInfo() {
        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        try {
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: true }
            });

            //let heroTotalSupply = await HeroContract.methods.totalSupply().call()

            let checkStatus = await HeroContract.methods.checkBuyStatus().call()

            if (checkStatus == 1) {
                setCryptoPacoPrice("350")
            }
            else {
                setCryptoPacoPrice("350")
            }


        } catch (error) {
            console.log(error);

        } finally {
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: false }
            });
        }

    }


    async function petMintAction() {
        console.log('pet')
        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }
            
            
            let totalBuy = await PetContract.methods.totalBuy(data[0]).call()
            console.log(totalBuy)
            if (totalBuy == 1){
                toast.error(t('toast.mintedPet'));
                return
            }
            

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });

                let mintToken = ""
                let result = await PetContract.methods.mint((inviteCode != "" ? inviteCode : "0x0000000000000000000000000000000000000000")).send({
                    from: data[0],
                    // value: web3.utils.toWei(
                    //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                    //   "wei"
                    // ),
                    gasPrice: await web3.eth.getGasPrice()

                })
                console.log(result)
                mintToken = result.events.Mint.returnValues.tokenId

                let tempArray = []
                if (isCardAnim.current == true) {
                    let temp = {
                        img: await getMetadata(await PetContract.methods.tokenURI(mintToken).call()),
                        //img: await getMetadata("api.cryptopaco.io/pet/", mintToken),
                        token_id: mintToken,
                        token_address: petAddress
                    }

                    tempArray.push(temp)


                    localStorage.setItem('mintCard', JSON.stringify({ assets: tempArray }))

                    navigate('/animation')
                }
                return



            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.buy cancel"));
                }
                else {
                    toast.error(t("toast.buy error"));
                }
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function heroMintAction(buyCount) {

        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });

                console.log("price")
                let getPrice = await HeroContract.methods.currentPrice().call()
                let totalPrice = (web3.utils.fromWei(getPrice, unit) * buyCount)

                let balanceOfWei = parseFloat(web3.utils.fromWei(await USDTContract.methods.balanceOf(data[0]).call(), unit))
                console.log((balanceOfWei))
                console.log(totalPrice)
                if ((balanceOfWei) >= (totalPrice)) {
                    let allowanceWei = parseFloat(web3.utils.fromWei(await USDTContract.methods.allowance(data[0], heroAddress).call(), unit))
                    //console.log(allowanceWei)
                    // console.log(totalPrice)
                    console.log(web3.utils.toWei(String(totalPrice), unit))
                    // console.log(allowanceWei - totalPrice )
                    if (allowanceWei < totalPrice) {

                        await USDTContract.methods.approve(heroAddress, web3.utils.toWei(String(10000000), unit)).send({ from: data[0], gasPrice: await web3.eth.getGasPrice() })
                    }

                }
                else {
                    return toast.error(t("toast.Not enough USDT"));
                }

                let checkStatus = await HeroContract.methods.checkBuyStatus().call()

                console.log(checkStatus)
                let mintResult = []
                let mintToken = ""
                if (checkStatus == 1) {
                    console.log(inviteCode != "" ? inviteCode : "0x0000000000000000000000000000000000000000")

                    let result = await HeroContract.methods.mint(buyCount, (inviteCode != "" ? inviteCode : "0x0000000000000000000000000000000000000000")).send({
                        from: data[0],
                        // value: web3.utils.toWei(
                        //   String((buyCount) * price),
                        //   "wei"
                        // ),
                        gasPrice: await web3.eth.getGasPrice()
                    })
                    console.log(result)
                    mintToken = result.events.WhitelistMint.returnValues.tokenId

                    if (buyCount == 1) {
                        mintResult = [mintToken]
                    }
                    else {
                        mintResult = [mintToken, String(parseInt(mintToken) + 1), String(parseInt(mintToken) + 2)]
                    }

                    //console.log(mintResult)
                    //console.log(mintResult.events.WhitelistMint.returnValues.tokenId)

                }
                else if (checkStatus == 2) {


                    let result = await HeroContract.methods.mint(buyCount, (inviteCode != "" ? inviteCode : "0x0000000000000000000000000000000000000000")).send({
                        from: data[0],
                        // value: web3.utils.toWei(
                        //   String((buyCount) * price),
                        //   "wei"
                        // ),
                        gasPrice: await web3.eth.getGasPrice()
                    })
                    mintToken = result.events.PublicMint.returnValues.tokenId

                    if (buyCount == 1) {
                        mintResult = [mintToken]
                    }
                    else {
                        mintResult = [mintToken, String(parseInt(mintToken) + 1), String(parseInt(mintToken) + 2)]
                    }

                }
                else {
                    toast.error(t("toast.buy error"));
                }

                console.log(mintResult)


                if (isCardAnim.current == true) {
                    let tempArray = []
                    for (let i = 0; i < mintResult.length; i++) {
                        let temp = {
                            img: await getMetadata(await HeroContract.methods.tokenURI(mintResult[i]).call()),
                            //img: await getMetadata("api.cryptopaco.io/hero/", mintResult[i]),
                            token_id: mintResult[i],
                            token_address: heroAddress
                        }

                        tempArray.push(temp)
                    }


                    if (buyCount == 1) {
                        localStorage.setItem('mintCard', JSON.stringify({ assets: tempArray }))
                    }
                    else {
                        localStorage.setItem('mintCard', JSON.stringify({ assets: tempArray }))
                    }
                    navigate('/animation')
                }

                return



            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.buy cancel"));
                }
                else {
                    toast.error(t("toast.buy error"));
                }
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function getMetadata(path) {

        const api_url = "" + path 
        console.log(api_url)

        let response = await axios.get(api_url)

        return response.data.image

    }

    function copySystemId() {
        var str = document.getElementById('systemid');
        window.getSelection().selectAllChildren(str);
        str.select();
        str.setSelectionRange(0, str.value.length);
        document.execCommand("Copy")
        window.getSelection().removeAllRanges()
    }

    useEffect(() => {
        btn = document.querySelector('.game-btn');
        menu = document.querySelector('.game-nav');
        miltmodal = document.querySelector('.milt-check-modal');
        miltmask = document.querySelector('.milt-check-modal .mask');
        unmiltmodal = document.querySelector('.unmiltmodal');
        unmiltmask = document.querySelector('.unmiltmodal .mask');
        pay_content = document.querySelector('.pay-content');


        let token = searchParams.get('q')


        if (token && web3.utils.isAddress(token)) {
            localStorage.setItem("inviteCode", token)
            setInviteCode(token)
        }
        else if (localStorage.getItem("inviteCode")) {
            setInviteCode(localStorage.getItem("inviteCode"))
        }

    }, []);

    useEffect(() => {

        if (walletAddress) {
            getCryptoPacoInfo()
        }

    }, [walletAddress]);


    return <div id="app">

        <div className="milt-check-modal modal-box">
            <div className="mask"></div>
            <div className="inner">
                <div className="closemodal" onClick={() => close_milt_modal()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.getNFT_modal_1")}</p>
                    <div className="btn-box">
                        <a onClick={() => mintAction(true)} className="btn yellow-btn">{t("message.yes")}</a>
                        <a onClick={() => mintAction(false)} className="btn black-btn">{t("message.no")}</a>
                    </div>
                </div>

            </div>
        </div>
        <div className="unmiltmodal modal-box">
            <div className="mask"></div>
            <div className="inner">
                <div className="closemodal" onClick={() => closemodal()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.getNFT_modal_2")}</p>
                    <div className="btn-box">
                        <a href="/game.php" className="btn yellow-btn">{t("message.understand")}</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="pay-content"><a href="/animation.php"><img src={payImg} alt="" /></a></div>
        {/* <?php include("./layouts/nav.html"); ?> */}
        <section className="game-box">
            <div className="container">
                {/* <?php include("./layouts/nav_game.html"); ?> */}
                <NavGame></NavGame>
            </div>
            <div className="game-content">
                <div className="container">

                    <div className="game-content-head">
                        <Role></Role>
                        <h2 className="title">{t("message.getNFT_title")}</h2>
                    </div>


                    <div className="check-milt">
                        <div className="milt-pet item-milt">
                            <div className="img">
                                <span><img src={eggImg} alt="" /></span>
                            </div>
                            <div className="text">
                                <h3 className="title title-flex">{t("message.getNFT_MountNFT")}
                                <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/bian-wan-bian-zuan-sheng-dian-qi-chong-nft/tui-jian-ji-hua" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/play-2-earn-cryptopaconft/pacomount-nft"} target="_block" className="link">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C" />
                                    </svg>
                                </a>
                            </h3>
                                <div className="btn-box">
                                    <button type="button" className="btn yellow-btn" onClick={() => (setBuyType(0), mintAction(true, 0))}>{t("message.free_milt")}</button>
                                </div>
                            </div>
                        </div>

                        <div className="milt-character item-milt">
                            <div className="img">
                                <span><img src={sprite1} alt="" /></span>
                                <span><img src={warrior2} alt="" /></span>
                                <span><img src={demon1} alt="" /></span>
                                <span><img src={sprite2} alt="" /></span>
                                <span><img src={warrior3} alt="" /></span>
                            </div>
                            <div className="text">
                                <h3 className="title title-flex">CryptoPaco NFT
                                <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/bian-wan-bian-zuan-sheng-dian-qi-chong-nft/tui-jian-ji-hua" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/play-2-earn-cryptopaconft/cryptopaco-nft"} target="_block" className="link">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C" />
                                    </svg>
                                </a>
                            </h3>
                                <p>{t("message.NFT_price")}<span>{cryptoPacoPrice}</span> USDT/{t("message.NFT_unit")}</p>
                                <div className="btn-box">
                                    <button type="button" className="btn yellow-btn" onClick={() => (setBuyType(1), mintAction(true, 1))}>{t("message.milt")}</button>
                                    <button type="button" className="btn yellow-btn" onClick={() => (setBuyType(2), mintAction(true, 2))}>{t("message.miltx3")}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div className="copy-code">
                    <input type="text" className="form-control" value={inviteCode} id="copy-code" />
                    <button type="submit" onClick={()=>pasteInviteCode()} className="btn yellow-btn">{ t("message.paste") }</button>
                </div>     */}

                    <InviteBox />

                </div>
            </div>
        </section>

    </div>
}

export default Game