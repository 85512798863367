

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import Timer from '../../compontents/Timer'


import Web3 from "web3"

import { calMul, calDiv, needChainId, fundingAddress, separatorNumber, pacoStakingAddress, petStakingAddress, heroStakingAddress, calAdd } from "../../global/constants"


import AD from '../../assets/images/AD.png'
import AD2 from '../../assets/images/AD2.png'
import AD3 from '../../assets/images/AD3.png'

import sprite1 from '../../assets/images/sprite1.png'
import sprite2 from '../../assets/images/sprite2.png'
import sprite3 from '../../assets/images/sprite3.png'
import sprite4 from '../../assets/images/sprite4.png'
import sprite5 from '../../assets/images/sprite5.png'

import warrior1 from '../../assets/images/warrior1.png'
import warrior2 from '../../assets/images/warrior2.png'
import warrior3 from '../../assets/images/warrior3.png'
import warrior4 from '../../assets/images/warrior4.png'
import warrior5 from '../../assets/images/warrior5.png'

import demon1 from '../../assets/images/demon1.png'
import demon2 from '../../assets/images/demon2.png'
import demon3 from '../../assets/images/demon3.png'
import demon4 from '../../assets/images/demon4.png'
import demon5 from '../../assets/images/demon5.png'

import pet1 from '../../assets/images/pet1.jpg'
import pet2 from '../../assets/images/pet2.jpg'
import pet3 from '../../assets/images/pet3.jpg'

import feature1 from '../../assets/images/feature1.jpg'
import feature2 from '../../assets/images/feature2.jpg'
import feature3 from '../../assets/images/feature3.jpg'
import feature4 from '../../assets/images/feature4.jpg'
import feature5 from '../../assets/images/feature5.jpg'
import feature6 from '../../assets/images/feature6.jpg'

import logo1 from '../../assets/images/logo1.png'
import logo2 from '../../assets/images/logo2.png'
import logo3 from '../../assets/images/logo3.png'
import logo4 from '../../assets/images/logo4.png'
import logo5 from '../../assets/images/logo5.png'
import logo6 from '../../assets/images/logo6.png'
import { useSelector } from "react-redux";



const Home = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()

    const walletAddress = useSelector(state => state.walletAddress);

    const petStakingAbi = require("../../assets/contract/petStaking.json");
    const heroStakingAbi = require("../../assets/contract/heroStaking.json");

    const [showAd1, setShowAd1] = useState(false)
    const [showAd2, setShowAd2] = useState(false)
    const [showAd3, setShowAd3] = useState(false)

    var homeHash1 = 0
    var homeHash2 = 0
    var homeHash3 = 0
    var homeHash4 = 0
    var homeHash5 = 0

    const fundingAbi = require("../../assets/contract/funding.json");

    var web3 = new Web3(Web3.givenProvider);

    let binancemainnet = "https://bsc-dataseed.binance.org/";
    if (needChainId == 56) {
        web3.setProvider(binancemainnet);
    }


    var FundingContract = new web3.eth.Contract(
        fundingAbi,
        fundingAddress
    );
    var PetStakingContract = new web3.eth.Contract(
        petStakingAbi,
        petStakingAddress
    );
    var HeroStakingContract = new web3.eth.Contract(
        heroStakingAbi,
        heroStakingAddress
    );

    const [fundingCurrentStatus, setFundingCurrentStatus] = useState(null)

    const isFirst = useRef(false)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)


    const [timeLeft, setTimeLeft] = useState(999999999);
    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);



    async function getFundingInfo() {


        try {

            let currentStatus = await FundingContract.methods.currentStatus().call()
            let tempStartTime = Number(await FundingContract.methods.start().call())
            let tempEndTime = Number(await FundingContract.methods.end().call())

            if (isFirst.current == false) {
                setStartTime(tempStartTime)
                setEndTime(tempEndTime)
                isFirst.current = true
            }
            setFundingCurrentStatus(currentStatus)

        } catch (error) {
            console.log(error);
            //} finally {
        }

    }




    function handleScroll() {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        //let scrollTop =
        //  document.documentElement.scrollTop || document.body.scrollTop;

        //let windowHeight =
        //document.documentElement.clientHeight || document.body.clientHeight;

        //let scrollHeight =
        //  document.documentElement.scrollHeight || document.body.scrollHeight;

        //console.log(homeHash1.getBoundingClientRect().top)
        //console.log(homeHash2.getBoundingClientRect().top)
        //console.log(homeHash3.getBoundingClientRect().top)
        //console.log(homeHash4.getBoundingClientRect().top)
        //console.log(homeHash5.getBoundingClientRect().top)
        // console.log("---------------------------")

        //console.log(window.innerHeight)
        if (homeHash5.getBoundingClientRect().top <= 500) {
            setCurrentTag("#home-team")
        }
        else if (homeHash4.getBoundingClientRect().top <= 150) {
            setCurrentTag("#home-map")
        }
        else if (homeHash3.getBoundingClientRect().top <= 150) {
            setCurrentTag("#home-features")
        }
        else if (homeHash2.getBoundingClientRect().top <= 150) {
            setCurrentTag("#home-characters")
        }
        else if (homeHash1.getBoundingClientRect().top <= 150) {
            setCurrentTag("#home-story")
        }

    }

    const [currentTag, setCurrentTag] = useState("#home-story")

    async function checkStakedNFT() {

        let stakedPet = await PetStakingContract.methods.getPacoMountStaked(walletAddress).call()
        console.log(stakedPet)
        let stakedHero = await HeroStakingContract.methods.getCryptoPacoStaked(walletAddress).call()
        console.log(stakedHero)
        if ((stakedPet.length + stakedHero.length) == 0) {
            setShowAd1(true)
        }

    }


    useEffect(() => {
        if (walletAddress !== undefined) {
            getFundingInfo()
            checkStakedNFT()
        }

    }, [walletAddress]);

    useEffect(() => {
        console.log('useEffect')
        homeHash1 = document.querySelector('#home-story')
        homeHash2 = document.querySelector('#home-characters')
        homeHash3 = document.querySelector('#home-features')
        homeHash4 = document.querySelector('#home-map')
        homeHash5 = document.querySelector('#home-team')

        window.addEventListener("scroll", handleScroll);

        return () => {
            // 在組件被解除掛載時，移除事件監聽器
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);


    return <div id="app">
        <div className="hidden ">{timeLeft}</div>
        <div className="AD">
            {
                showAd1 ?
                    <div className="item AD-1">
                        <div className="mask"></div>
                        <div className="inner">
                            <div className="closeAD" onClick={() => setShowAd1(false)}>
                                <svg width="40" height="40" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                                </svg>
                            </div>
                            <div className="popContent border-solid border-[#FECE3E] border ">
                                <div className=" bg-black  p-8 pt-12">
                                    <p className="text-xl text-[#FECE3E] font-bold">{t("message.showAd1Title")}</p>

                                    <div className="grid grid-cols-2 gap-5 pt-6">
                                        <Link to="/game" className="btn black-btn">{t("message.showAd1Option1")}</Link>
                                        <Link to="/mynft" className="btn yellow-btn">{t("message.showAd1Option2")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                showAd2 ?
                    <div className="item AD-2">
                        <div className="mask"></div>
                        <div className="inner">
                            <div className="closeAD" onClick={() => setShowAd2(false)}>
                                <svg width="40" height="40" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                                </svg>
                            </div>
                            <div className="popContent">
                                <a href="###"><img src={AD2} alt="" /></a>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                showAd3 ?
                    <div className="item AD-3">
                        <div className="mask"></div>
                        <div className="inner">
                            <div className="closeAD" onClick={() => setShowAd3(false)}>
                                <svg width="40" height="40" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                                </svg>
                            </div>
                            <div className="popContent">
                                <Link to="/buy"><img src={AD3} alt="" /></Link>
                            </div>
                        </div>


                    </div> : ""
            }
        </div>



        <div className="scroll_btn">
            <ul>

                <li className={currentTag == "#home-story" || currentTag == "" ? "active" : ""}><a href="#home-story" onClick={() => setCurrentTag('#home-story')}>{t("message.caption")}</a></li>
                <li className={currentTag == "#home-characters" ? "active" : ""}><a href="#home-characters" onClick={() => setCurrentTag('#home-characters')}>{t("message.characters")}</a></li>
                <li className={currentTag == "#home-features" ? "active" : ""}><a href="#home-features" onClick={() => setCurrentTag('#home-features')}>{t("message.features")}</a></li>
                <li className={currentTag == "#home-map" ? "active" : ""}><a href="#home-map" onClick={() => setCurrentTag('#home-map')}>{t("message.map")}</a></li>
                <li className={currentTag == "#home-team" ? "active" : ""}><a href="#home-team" onClick={() => setCurrentTag('#home-team')}>{t("message.team")}</a></li>
            </ul>
        </div>
        <div className="content_block">
            <div className="banner-box">
                <div className="img"></div>
                {
                    startTime > 0 && endTime > 0 ?
                        startTime - Math.floor(Date.now() / 1000) > 0 ?
                            <Timer titleType={'1'} closeTime={(startTime)}></Timer>
                            :
                            <Timer titleType={'2'} closeTime={(fundingCurrentStatus != null && (fundingCurrentStatus[1] || fundingCurrentStatus[2])) ? 0 : (endTime)}></Timer>
                        : ""

                }
                <div className="banner-txt">
                    <h2 className="title">{t("message.banner_caption_1")}</h2>
                    <p><span>{t("message.banner_caption_2")}</span>
                        <span>{t("message.banner_caption_3")}</span>
                    </p>
                    <div className="button">
                        <Link to="/game" className="btn black-btn">{t("message.miltNFT")}</Link>
                        <Link to="/buy" className="btn black-btn">{t("message.buy$PACO")}</Link>
                        <Link to="/bid" className="btn yellow-btn">{t("message.start_game")}</Link>
                    </div>
                </div>

            </div>
        </div>


        <section className="home-story" id="home-story">
            <div className="container">
                <h2 className="title title-box">

                    {t("message.caption")}
                </h2>
                <div className="txt">
                    <div className="txt-border">
                        <p>{t("message.caption_text_1")}</p>
                        <p>{t("message.caption_text_2")}</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="home-characters" id="home-characters">
            <div className="container">
                <h2 className="title title-box">

                    {t("message.characters")}
                </h2>
                <div className="characters-items">
                    <ul>
                        <li>
                            <div className="img">
                                <span><img src={sprite1} alt="" /></span>
                                <span><img src={sprite2} alt="" /></span>
                                <span><img src={sprite3} alt="" /></span>
                                <span><img src={sprite4} alt="" /></span>
                                <span><img src={sprite5} alt="" /></span>
                            </div>
                            <h3 className="title">{t("message.characters_name_1")}</h3>

                        </li>
                        <li>
                            <div className="img">
                                <span><img src={warrior1} alt="" /></span>
                                <span><img src={warrior2} alt="" /></span>
                                <span><img src={warrior3} alt="" /></span>
                                <span><img src={warrior4} alt="" /></span>
                                <span><img src={warrior5} alt="" /></span>
                            </div>
                            <h3 className="title">{t("message.characters_name_2")}</h3>

                        </li>
                        <li>
                            <div className="img" >
                                <span><img src={demon1} alt="" /></span>
                                <span><img src={demon2} alt="" /></span>
                                <span><img src={demon3} alt="" /></span>
                                <span><img src={demon4} alt="" /></span>
                                <span><img src={demon5} alt="" /></span>
                            </div>
                            <h3 className="title">{t("message.characters_name_3")}</h3>

                        </li>
                        <li>
                            <div className="img"><img src={pet1} alt="" /></div>
                            <h3 className="title">{t("message.mounts_name_1")}</h3>
                        </li>
                        <li>
                            <div className="img"><img src={pet2} alt="" /></div>
                            <h3 className="title">{t("message.mounts_name_2")}</h3>
                        </li>
                        <li>
                            <div className="img"><img src={pet3} alt="" /></div>
                            <h3 className="title">{t("message.mounts_name_3")}</h3>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="home-features" id="home-features">
            <div className="container">
                <h2 className="title title-box">
                    {/* <span>FEATURES</span> */}
                    {t("message.features")}
                </h2>
                <div className="feature-items">
                    <ul>
                        <li>
                            <h3 className="title">{t("message.features_title_1")}</h3>
                            <div className="img"><img src={feature1} alt="" /></div>
                            <div className="txt">
                                <p>{t("message.features_text_1_1")}<br />{t("message.features_text_1_2")}</p>
                            </div>
                        </li>
                        <li>
                            <h3 className="title">{t("message.features_title_2")}</h3>
                            <div className="img"><img src={feature2} alt="" /></div>
                            <div className="txt">
                                <p>{t("message.features_text_2_1")}<br />{t("message.features_text_2_2")}</p>
                            </div>
                        </li>
                        <li>
                            <h3 className="title">{t("message.features_title_3")}</h3>
                            <div className="img"><img src={feature3} alt="" /></div>
                            <div className="txt">
                                <p>{t("message.features_text_3_1")}<br />{t("message.features_text_3_2")}</p>
                            </div>

                        </li>
                        <li>
                            <h3 className="title">{t("message.features_title_4")}</h3>
                            <div className="img"><img src={feature4} alt="" /></div>
                            <div className="txt">
                                <p>{t("message.features_text_4_1")}</p>
                            </div>
                        </li>
                        <li>
                            <h3 className="title">{t("message.features_title_5")}</h3>
                            <div className="img"><img src={feature5} alt="" /></div>
                            <div className="txt">
                                <p>{t("message.features_text_5_1")}<br />{t("message.features_text_5_2")}</p>
                            </div>
                        </li>
                        <li>
                            <h3 className="title">{t("message.features_title_6")}</h3>
                            <div className="img"><img src={feature6} alt="" /></div>
                            <div className="txt">
                                <p>{t("message.features_text_6_1")}<br />{t("message.features_text_6_2")}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>


        <section className="home-map" id="home-map">
            <div className="container">
                <h2 className="title title-box">
                    {/* <span>ROUTE MAP</span> */}
                    {t("message.map")}
                </h2>
                <div className="map-items">
                    <ul className="items-box">
                        <li>
                            <div className="border">
                                <em className="line"></em>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h3 className="title">{t("message.map_title_1")}</h3>
                                <div className="txt">
                                    <p>{t("message.map_txt_1_1")}<br />
                                        {t("message.map_txt_1_2")}<br />
                                        {t("message.map_txt_1_3")}<br />
                                        {t("message.map_txt_1_4")}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="border">
                                <em className="line"></em>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h3 className="title">{t("message.map_title_2")}</h3>
                                <div className="txt">
                                    <p>{t("message.map_txt_2_1")}<br />
                                        {t("message.map_txt_2_2")}<br />
                                        {t("message.map_txt_2_3")}<br />
                                        {t("message.map_txt_2_4")}<br />
                                        {t("message.map_txt_2_5")}<br />
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="border">
                                <em className="line"></em>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h3 className="title">{t("message.map_title_3")}</h3>
                                <div className="txt">
                                    <div className="list">
                                        <h4>{t("message.map_title_3_h4")} </h4>
                                        <p>{t("message.map_title_3_p")}</p>
                                        <div>
                                            <p>◎ {t("message.map_txt_3_1")}</p>
                                            <p>◎ {t("message.map_txt_3_2")}</p>
                                            <p>◎ {t("message.map_txt_3_3")}</p>
                                            <p>◎ {t("message.map_txt_3_4")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="border">
                                <em className="line"></em>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h3 className="title">{t("message.map_title_4")}</h3>
                                <div className="txt">
                                    <p>{t("message.map_txt_3_1")}<br />
                                        {t("message.map_txt_4_2")}<br />
                                        {t("message.map_txt_4_3")}<br />
                                        {t("message.map_txt_4_4")}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="border">
                                <em className="line"></em>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <h3 className="title">{t("message.map_title_5")}</h3>
                                <div className="txt">
                                    <p>{t("message.map_txt_5_1")}<br />
                                        {t("message.map_txt_5_2")}
                                    </p>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </section>

        <section className="home-team" id="home-team">
            <h2 className="title title-box team-title">
                {/* <span>Partners</span> */}
                {t("message.team")}
            </h2>
            <div className="container">
                <ul className="partner-items">
                    <li>
                        <div className="img"><img src={logo1} alt="" /></div>
                    </li>
                    <li>
                        <div className="img"><img src={logo2} alt="" /></div>
                    </li>
                    <li>
                        <div className="img"><img src={logo3} alt="" /></div>

                    </li>
                    <li>
                        <div className="img"><img src={logo4} alt="" /></div>
                    </li>
                    <li>
                        <div className="img"><img src={logo5} alt="" /></div>
                    </li>
                    <li>
                        <div className="img"><img src={logo6} alt="" /></div>
                    </li>

                </ul>
            </div>
        </section>

        <div className="community ">
            <ul>
                <li><a href="https://twitter.com/CryptoPaco" target="_block"><img src={require("../../assets/images/twinter.png")} alt="" /></a></li>
                <li><a href="https://discord.gg/cHK3MbXHZT" target="_block"><img src={require("../../assets/images/dscc.png")} alt="" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCWmPDwpyz7n85R-QML4vi3A" target="_block"><img src={require("../../assets/images/youtube.png")} alt="" /></a></li>
                <li><a href="https://www.instagram.com/crypto_paco/" target="_block"><img src={require("../../assets/images/ins.png")} alt="" /></a></li>

            </ul>
        </div>

    </div>



}

export default Home