

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import NavGame from "../../compontents/NavGame.js"

import Role from "../../compontents/Role.js"
import payImg from '../../assets/images/pay.png'

import heroAbi from '../../assets/contract/hero.json'
import fundingAbi from '../../assets/contract/funding.json'
import relationshipAbi from '../../assets/contract/relationship.json'



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3"

import { fixedFloor, calAdd, calSub, calMul, calDiv, unit, fundingAddress, relationshipAddress, needChainId, usdtAddress, pacoAddress, heroAddress, petAddress, pacoIDOAddress, separatorNumber, pacoStakingAddress } from "../../global/constants"

import { useSelector, useDispatch } from 'react-redux';

const Dividend = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const claimDialog = useSelector(state => state.claimDialog);

    const walletAddress = useSelector(state => state.walletAddress);
    const role = useSelector(state => state.role);
    const loading = useSelector(state => state.loading);


    const [showAd1, setShowAd1] = useState(false)
    const [showAd2, setShowAd2] = useState(false)
    const [showAd3, setShowAd3] = useState(false)

    const [myBonus, setMyBonus] = useState(0)
    const [myWithdrawBonusRecord, setMyWithdrawBonusRecord] = useState(0)


    const [myShareClaimed, setMyShareClaimed] = useState(false)
    const [myShares, setMyShares] = useState([])
    const [myTotalShares, setMyTotalShares] = useState(0)
    const [myCheckShare, setMyCheckShare] = useState(0)
    const [claimedMyCheckShare, setClaimedMyCheckShare] = useState(0)

    const [myPacoShares, setMyPacoShares] = useState([])
    const [myPacoTotalShares, setMyPacoTotalShares] = useState(0)
    const [myPacoCheckShare, setMyPacoCheckShare] = useState(0)
    const [claimedMyPacoCheckShare, setClaimedMyPacoCheckShare] = useState(0)

    const [myNFTBonus, setMyNFTBonus] = useState(0)
    const [myNFTWithdrawBonusRecord, setMyNFTWithdrawBonusRecord] = useState(0)

    var web3 = new Web3(Web3.givenProvider);

    var HeroContract = new web3.eth.Contract(
        heroAbi,
        heroAddress
    );

    var FundingContract = new web3.eth.Contract(
        fundingAbi,
        fundingAddress
    );
    var RelationshipContract = new web3.eth.Contract(
        relationshipAbi,
        relationshipAddress
    );


    function fundingGen() {
        if (role == "adventurer") {
            return "1"
        }
        else if (role == 'brave') {
            return "3"
        }
        else if (role == 'envoys') {
            return "3"
        }
        else if (role == 'prophet') {
            return "3"
        }
        else if (role == 'holySpirit') {
            return "3"
        }
        else {
            return "0"
        }

    }

    function roleGen() {
        if (role == 'brave') {
            return "1"
        }
        else if (role == 'envoys') {
            return "3"
        }
        else if (role == 'prophet') {
            return "6"
        }
        else if (role == 'holySpirit') {
            return "10"
        }
        else {
            return "0"
        }

    }



    function copySystemId() {
        var str = document.getElementById('systemid');
        window.getSelection().selectAllChildren(str);
        str.select();
        str.setSelectionRange(0, str.value.length);
        document.execCommand("Copy")
        window.getSelection().removeAllRanges()
        toast.success(t('toast.copySuccess'))
    }


    async function claimBidFunding() {
        if (loading == true) {
            return
        }



        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }


        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });

                let currentStatus = await FundingContract.methods.currentStatus().call()
                if (!currentStatus[1]) {
                    toast.error(t("toast.waitbid"));
                    return
                }
                if (myCheckShare == 0) {
                    toast.error(t("toast.withdraw0USDT"));
                    return
                }


                let allowRefund = await FundingContract.methods.allowRefund().call()
                console.log(allowRefund)
                if (!allowRefund) {
                    toast.error(t("toast.return calculating"));
                    return
                }

                //

                let result = await FundingContract.methods.getShare().send({
                    from: data[0],
                    gasPrice: await web3.eth.getGasPrice()
                    // value: web3.utils.toWei(
                    //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                    //   "wei"
                    // ),
                })

                getBounsInfo()

                toast.success(t("toast.withdraw success"));

                dispatch({
                    type: "UPDATE_CLAIMDIALOG",
                    payload: { claimDialog: { currency: "USDT", value: myCheckShare } }
                });

            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.withdraw cancel"));
                }
                else {
                    toast.error(t("toast.withdraw fail"));
                }
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function claimPacoFunding() {


        if (loading == true) {
            return
        }

        if (myPacoCheckShare == 0) {
            toast.error(t("toast.withdraw0USDT"));
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }


        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });


                let result = await HeroContract.methods.withdrawShare().send({
                    from: data[0],
                    gasPrice: await web3.eth.getGasPrice()
                    // value: web3.utils.toWei(
                    //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                    //   "wei"
                    // ),
                })

                getBounsInfo()
                toast.success(t("toast.withdraw success"));
                dispatch({
                    type: "UPDATE_CLAIMDIALOG",
                    payload: { claimDialog: { currency: "USDT", value: myPacoCheckShare } }
                });

            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.withdraw cancel"));
                }
                else {
                    toast.error(t("toast.withdraw fail"));
                }
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }



    async function getBounsInfo() {

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);

            try {

                let totalShare = 0
                let temp = []

                let pacoTotalShare = 0
                let pacoTemp = []

                let prevTemp

                for (let i = 0; i < 10; i = i + 1) {
                    //let a = web3.utils.fromWei(await RelationshipContract.methods.conuntAncestryN(data[0], i, i + 1).call(), unit)
                    let a
                    if (i == 0) {
                        a = await RelationshipContract.methods.conuntAncestryN(data[0], 0, 0).call()
                        prevTemp = a
                    }
                    else {
                        let t = await RelationshipContract.methods.conuntAncestryN(data[0], 0, i).call()
                        a = calSub(t, prevTemp)
                        prevTemp = t
                    }

                    console.log(i, a, pacoTemp[i - 1])
                    if (i < 3) {
                        temp.push(a)
                        totalShare = calAdd(totalShare, a)
                    }
                    if (i < 10) {
                        pacoTemp.push(a)
                        pacoTotalShare = calAdd(pacoTotalShare, a)

                    }
                }

                console.log('ss')

                setMyShares(temp)
                setMyTotalShares(totalShare)
                if (fundingAddress != "") {
                    setMyShareClaimed(await FundingContract.methods.share(data[0]).call())
                    setMyCheckShare(fixedFloor(web3.utils.fromWei(await FundingContract.methods.checkShare(data[0]).call(), unit), 2))
                    setClaimedMyCheckShare(fixedFloor(web3.utils.fromWei(await FundingContract.methods.shareWithdraw(data[0]).call(), unit), 2))
                }
                setMyPacoShares(pacoTemp)
                setMyPacoTotalShares(pacoTotalShare)
                setMyPacoCheckShare(fixedFloor(web3.utils.fromWei(await HeroContract.methods.share(data[0]).call(), unit), 2))
                setClaimedMyPacoCheckShare(fixedFloor(web3.utils.fromWei(await HeroContract.methods.shareWithdraw(data[0]).call(), unit), 2))

                return

                setMyBonus(web3.utils.fromWei(await HeroContract.methods.funding(data[0]).call(), unit))
                setMyWithdrawBonusRecord(web3.utils.fromWei(await HeroContract.methods.withdrawRecord(data[0]).call(), unit))

                setMyNFTBonus(web3.utils.fromWei(await HeroContract.methods.funding(data[0]).call(), unit))
                setMyNFTWithdrawBonusRecord(web3.utils.fromWei(await HeroContract.methods.withdrawRecord(data[0]).call(), unit))



                setMyBonus(await FundingContract.methods.checkShare(data[0]).call())



            } catch (error) {
                console.log(error);
            } finally {
            }
        });
    }



    useEffect(() => {
        getBounsInfo()


    }, []);


    return <div id="app">

        <div className="pay-content"><img src="./frontEndPackage/images/pay.png" alt="" /></div>
        {/* <?php include("./layouts/nav.html"); ?> */}
        <section className="game-box">
            <div className="container">
                <NavGame></NavGame>
            </div>
            <div className="game-content">
                <div className="container">

                    <div className="game-content-head">
                        <Role></Role>

                        <h2 className="title">{t("message.my_bonus")}</h2>

                    </div>
                    <div className="dividend">
                        <div className="inner">
                            {/* <div className="item">
                            <div className="dividend-content">
                                <label className="name">{ t("message.my_bonus") }：</label>
                                <div className="form_box">
                                    <div  className="input-num" >{myBonus}</div>
                                    <span className="dollor">USDT</span>
                                </div>
                                
                                <button className="btn yellow-btn" type="button"  onClick={()=>claimFunding()}>{ t("message.withdrawal") }</button>
                            </div>
                            <div className="dividend-content">
                                <label className="name">{ t("message.bonus_claimed") }：</label><span className="num">{separatorNumber(myWithdrawBonusRecord)}</span><span className="dollor">USDT</span>
                            </div>
                            
                        </div> */}
                            <div className="item">
                                <div className="dividend-content">

                                    <div className="share">
                                        <label className="name">{t("message.copy_title")}：</label>
                                        <ul>

                                            <p className="share-name">{t("message.share_name")}</p>
                                            <li>
                                                <a target="_blank" href={`http://www.facebook.com/share.php?u=${encodeURIComponent(window.location.href)}`}>
                                                    <svg width="30" height="30" viewBox="0 0 512 512"><path d="M288 192v-38.1c0-17.2 3.8-25.9 30.5-25.9H352V64h-55.9c-68.5 0-91.1 31.4-91.1 85.3V192h-45v64h45v192h83V256h56.4l7.6-64h-64z" fill="currentColor" /></svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href={`http://twitter.com/home/?status=${encodeURIComponent(window.location.href)}`}>
                                                    <svg width="30" height="30" viewBox="0 0 64 64"><path fill="currentColor" d="m60 16l-6 1l4-5l-7 2c-9-10-23 1-19 10C16 24 8 12 8 12s-6 9 4 16l-6-2c0 6 4 10 11 12h-7c4 8 11 8 11 8s-6 5-17 5c33 16 53-14 50-30Z" /></svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://discord.gg/cHK3MbXHZT" target="_blank">
                                                    <svg width="50" height="50" viewBox="0 0 50 50">
                                                        <path fill="currentColor" d="M32.5353 17.5C31.0816 16.8 29.531 16.3 27.8835 16H27.7866C27.5927 16.4 27.3989 16.9 27.2051 17.2C25.4607 16.9 23.7162 16.9 21.9718 17.2C21.778 16.8 21.5842 16.3 21.3903 16C21.3903 16 21.3903 16 21.2934 16C19.6459 16.3 18.0953 16.8 16.6416 17.5C13.6373 22.1 12.862 26.5 13.2497 30.9C13.2497 30.9 13.2497 30.9 13.2497 31C15.1879 32.5 17.1262 33.4 18.9675 34H19.0644C19.549 33.4 19.9367 32.7 20.2274 32V31.9C19.6459 31.7 18.9675 31.4 18.483 31C18.483 31 18.3861 30.9 18.483 30.9C18.5799 30.8 18.6768 30.7 18.8706 30.6H18.9675C22.7471 32.4 26.7205 32.4 30.5001 30.6H30.597C30.6939 30.7 30.7909 30.8 30.9847 30.9V31C30.4032 31.3 29.8217 31.6 29.2403 31.9C29.2403 31.9 29.1433 32 29.2403 32C29.6279 32.7 30.0156 33.3 30.4032 34H30.5001C32.3415 33.4 34.2797 32.5 36.218 31C36.218 31 36.218 31 36.218 30.9C36.4118 25.9 35.1519 21.4 32.5353 17.5ZM20.8089 28.3C19.6459 28.3 18.7737 27.2 18.7737 25.9C18.7737 24.6 19.6459 23.5 20.8089 23.5C21.9718 23.5 22.844 24.6 22.844 25.9C22.844 27.2 21.9718 28.3 20.8089 28.3ZM28.368 28.3C27.2051 28.3 26.3329 27.2 26.3329 25.9C26.3329 24.6 27.2051 23.5 28.368 23.5C29.531 23.5 30.4032 24.6 30.4032 25.9C30.5001 27.2 29.531 28.3 28.368 28.3Z" />
                                                    </svg>
                                                </a>
                                            </li>

                                            <li>
                                                <a href={`https://www.instagram.com/`} target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30">
                                                        <path fill="currentColor" d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                            {/* <li>
                                            <a href="###">
                                                <svg width="28" height="28" viewBox="0 0 28 28">
                                                    <path d="M1.12646 1.12646H12.3907V12.3907H1.12646V1.12646ZM3.37931 3.37931V10.1379H10.1379V3.37931H3.37931Z" fill="currentColor"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63232 5.63208H7.88517V7.88493H5.63232V5.63208Z" fill="currentColor"/>
                                                    <path d="M14.6436 1.12646H25.9078V12.3907H14.6436V1.12646ZM16.8964 3.37931V10.1379H23.6549V3.37931H16.8964Z" fill="currentColor"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1494 5.63208H21.4023V7.88493H19.1494V5.63208Z" fill="currentColor"/>
                                                    <path d="M1.12646 14.6436H12.3907V25.9078H1.12646V14.6436ZM3.37931 16.8964V23.6549H10.1379V16.8964H3.37931Z" fill="currentColor"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63232 19.1492H7.88517V21.402H5.63232V19.1492Z" fill="currentColor"/>
                                                    <path d="M25.9078 21.4021H21.4021V25.9078H14.6436V14.6436H15.77H14.6436V21.4021H16.8964V23.6549H19.1493V16.8964H16.8964V14.6436H15.77H19.1493V16.8964H21.4021V19.1493H23.6549V14.6436H25.9078V21.4021ZM25.9078 23.6549V25.9078H23.6549V23.6549H25.9078Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </li> */}


                                        </ul>

                                    </div>
                                    <div className="copy-code">
                                        <input type="text" className="form-control" readOnly={true} value={walletAddress == null ? t('message.needConnectWallet') : `${window.location.protocol}//${window.location.hostname}/game?q=${walletAddress}`} id="systemid" />
                                        <button type="submit" onClick={() => copySystemId()} className="copy"><svg width="17" height="20" viewBox="0 0 17 20">
                                            <path d="M12.5263 0H1.78947C0.805263 0 0 0.818182 0 1.81818V14.5455H1.78947V1.81818H12.5263V0ZM15.2105 3.63636H5.36842C4.38421 3.63636 3.57895 4.45455 3.57895 5.45455V18.1818C3.57895 19.1818 4.38421 20 5.36842 20H15.2105C16.1947 20 17 19.1818 17 18.1818V5.45455C17 4.45455 16.1947 3.63636 15.2105 3.63636ZM15.2105 18.1818H5.36842V5.45455H15.2105V18.1818Z" fill="#E1BD7C" />
                                        </svg></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="dividend-content">
                        <div className="item">
                            <h3 className="title title-flex">{t("message.bid_temple")}
                                <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/bian-wan-bian-zuan-sheng-dian-qi-chong-nft/tui-jian-ji-hua" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/play-2-earn-cryptopaconft/crowdfunding-referral-program"} target="_block" className="link">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C" />
                                    </svg>
                                </a>
                            </h3>
                            <div className="inner">
                                <table className="rtable rtable-flip">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t("message.person_num")}</th>
                                            {/* <th>{ t("message.current_bonus") }</th> */}
                                            {/* <th>{t("message.total_bonus")}</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            myShares.map((i, index) => <tr key={index}>
                                                <td>{t(`message.gen${index + 1}`)}</td>
                                                <td>{separatorNumber(i)}</td>
                                            </tr>
                                            )
                                        }
                                        {/* <tr>
                                            <td>{t("message.first")}</td>
                                            <td>{myShares}</td>

                                        </tr>
                                        <tr>
                                            <td>{t("message.second")}</td>
                                            <td>100,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.third")}</td>
                                            <td>101,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.fourth")}</td>
                                            <td>101,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.fifth")}</td>
                                            <td>101,000</td>


                                        </tr> */}
                                        <tr>
                                            <td>{t("message.total")}</td>
                                            <td>{separatorNumber(myTotalShares)}</td>


                                        </tr>


                                    </tbody>
                                </table>

                                <div className=" relative z-10 dividend pb-2">
                                    <div className="item ">
                                        {
                                            fundingGen() != "0" ?
                                                <div className="dividend-content mb-1">
                                                    <p className="text-[#E1BD7C]">【{t('message.role')}：{t(`message.${role}`)} [{t('message.dividendCue4')} {t(`message.gen${fundingGen()}`)} {t('message.dividendCue3')}］】</p>
                                                </div>
                                                :
                                                <div className="dividend-content mb-1">
                                                    <p className="text-[#E1BD7C]">【{t('message.role')}：{t(`message.${role}`)} [{t('message.dividendCue1')}］】</p>
                                                </div>
                                        }
                                        <div className="dividend-content">
                                            <label className="name text-[#FECE3F]">{t("message.my_pet_bonus")}：</label>
                                            <div className="form_box">
                                                <div className="input-num " >{myShareClaimed ? 0 : separatorNumber(myCheckShare)}</div>
                                                <span className="dollor">USDT</span>
                                            </div>

                                            <button className={`btn yellow-btn ${myShareClaimed ? 'grayscale' : ''}`} disabled={myShareClaimed} type="button" onClick={() => claimBidFunding()}>{t("message.withdrawal")}</button>
                                        </div>
                                        <div className="dividend-content">
                                            <label className="name text-[#FECE3F]">{t("message.pet_bonus_claimed")}：</label><span className="num">{separatorNumber(claimedMyCheckShare)}</span><span className="dollor">USDT</span>
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                        {/* <div className="item">
                        <div className="inner">
                        <table className="rtable rtable-flip">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{ t("message.bonus_unaccalimed") }</th>
                                        <th>{ t("message.total_bonus") }</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{ t("message.arena_bonus") }</td>
                                        <td>1,000</td>
                                        <td>1,000</td>
                                        
                                    </tr>
                                    <tr>
                                        <td> <div className="title-flex !justify-end">{ t("message.world_bonus") } 
                                            <a href={i18n.language=='zh1'?"https://cryptopaco.gitbook.io/crypto-paco-cn/bian-wan-bian-zuan-paco-nft/shen-zu-nft-shi-jie-dong-shi":"https://cryptopaco.gitbook.io/crypto-paco-en-1/bian-wan-bian-zuan-paco-nft/divine-nation-nft-world-director-member"} target="_block" className="link">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C"/>
                                                </svg>
                                            </a>
                                            </div>
                                        </td>
                                        <td>1,000</td>
                                        <td>4,500</td>
                                       
                                    </tr>
                                  
                                
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                        <div className="item">
                            <h3 className="title title-flex">{t("message.bid_CryptoPaco")}
                                <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/bian-wan-bian-zuan-paco-nft/tui-jian-ji-hua" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/play-2-earn-cryptopaconft/cryptopaco-nft/referral-program"} target="_block" className="link">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C" />
                                    </svg>
                                </a>
                            </h3>
                            <div className="inner">
                                <table className="rtable rtable-flip">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t("message.person_num")}</th>
                                            {/* <th>{ t("message.current_bonus") }</th> */}
                                            {/* <th>{t("message.total_bonus")}</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            myPacoShares.map((i, index) => <tr key={index}>
                                                <td>{t(`message.gen${index + 1}`)}</td>
                                                <td>{separatorNumber(i)}</td>
                                            </tr>
                                            )
                                        }
                                        <tr>
                                            <td>{t("message.total")}</td>
                                            <td>{separatorNumber(myPacoTotalShares)}</td>

                                        </tr>
                                        {/* <tr>
                                            <td>{t("message.first")}</td>
                                            <td>105,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.second")}</td>
                                            <td>100,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.third")}</td>
                                            <td>101,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.fourth")}</td>
                                            <td>101,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.fifth")}</td>
                                            <td>101,000</td>


                                        </tr>

                                        <tr>
                                            <td>{t("message.sixth")}</td>
                                            <td>101,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.seventh")}</td>
                                            <td>101,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.eighth")}</td>
                                            <td>101,000</td>


                                        </tr>
                                        <tr>
                                            <td>{t("message.ninth")}</td>
                                            <td>101,000</td>

                                        </tr>
                                        <tr>
                                            <td>{t("message.tenth")}</td>
                                            <td>101,000</td>


                                        </tr>

                                        <tr>
                                            <td>{t("message.total")}</td>
                                            <td>102,000</td>


                                        </tr> */}


                                    </tbody>
                                </table>


                                <div className=" relative z-10 dividend pb-2">

                                    <div className="item ">
                                        {
                                            roleGen() != "0" ?
                                                <div className="dividend-content mb-1">
                                                    <p className="text-[#E1BD7C]">【{t('message.role')}：{t(`message.${role}`)} [{t('message.dividendCue2')}{t(`message.gen${roleGen()}`)}{t('message.dividendCue3')}］】</p>
                                                </div>
                                                :
                                                <div className="dividend-content mb-1">
                                                    <p className="text-[#E1BD7C]">【{t('message.role')}：{t(`message.${role}`)} [{t('message.dividendCue1')}］】</p>
                                                </div>
                                        }

                                        <div className="dividend-content">

                                            <label className="name text-[#FECE3F]">{t("message.my_paco_bonus")}：</label>
                                            <div className="form_box">
                                                <div className="input-num " >{separatorNumber(myPacoCheckShare)}</div>
                                                <span className="dollor">USDT</span>

                                            </div>

                                            <button className="btn yellow-btn" type="button" onClick={() => claimPacoFunding()}>{t("message.withdrawal")}</button>
                                        </div>
                                        <div className="dividend-content">
                                            <label className="name text-[#FECE3F]">{t("message.paco_bonus_claimed")}：</label><span className="num">{separatorNumber(claimedMyPacoCheckShare)}</span><span className="dollor">USDT</span>
                                        </div>

                                    </div>
                                </div>

                            </div>




                        </div>
                        <div className="item"></div>

                    </div>





                </div>
            </div>
        </section>

    </div>
}

export default Dividend