
const initState = {
    menuItemData: [
        "1",
        "2",
    ],
    walletAddress:undefined,
    role:"norole",
    loading:false,
    claimDialog:null
  };

const itemReducer = (state = initState, action) => {
    switch (action.type) {
      case 'UPDATE_LOADING': {
        return { ...state,loading: action.payload.loading };
      }
      case 'UPDATE_WALLET': {
        return { ...state, walletAddress: action.payload.walletAddress };
      }
      case 'UPDATE_ROLE': {
        return { ...state, role: action.payload.role };
      }
      case 'UPDATE_CLAIMDIALOG': {
        return { ...state, claimDialog: action.payload.claimDialog };
      }
    
      default:
        return state;
    }
};


export {itemReducer};