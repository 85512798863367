export const en = {
  toast: {
    "mintedPet": "A MountNFT already has been minted, limited to one",
    'switch bsc mainnet': "Please use BSC Binance Smart Chain",
    'stake success': "Staking successful",
    'stake error': "Staking failed",
    'stake cancel': "Staking canceled",
    'unstake success': "Unstake success",
    'unstake error': "Unstaking error",
    'unstake cancel': "Unstaking canceled",
    'buy error': "Purchase failed",
    'buy error1': "Purchase failed",
    'buy success': "Purchase successful",
    "buy cancel": "Purchase canceled",
    'connect wallet': "Please connect your wallet first",
    'Not enough token': "You don't have enough tokens",
    'Not enough PACO': "You don't have enough PACO",
    'Not enough USDT': "You don't have enough USDT",
    'return calculating': "Crowdfunding failed, it can take up to 30 minutes to calculate the final amount, please wait.",
    'notAllowClaimCenter': "You can unstake after the crowdfunding fails",
    "chooseNFT": "Please choose an NFT that has not been staked",
    "chooseStakedNFT": "Please choose a staked NFT",
    "withdraw0PACO": "No PACO that can be withdrawn",
    "withdraw0USDT": "No USDT that can be withdrawn",
    "withdraw success": "Withdrawal successful",
    "withdraw fail": "Withdrawal failed",
    "withdraw cancel": "Withdrawal canceled",
    "in bid Time": "Crowdfunding is in progress, the crowdfunding has not failed yet.",
    "bid submit success": "Funds submitted successfully",
    "bid submit fail": "Submitting funds has failed",
    "bid submit cancel": "Submitting funds has been canceled",
    "bid rule1": "A minimum of 100 USDT is required to participate in the crowdfunding game",
    "bid rule2": "To participate in the crowdfunding game, the last two digits have to be zero",

    "waitbid": "Open after the crowdfunding ends",

    "noNetwork": "The network is abnormal, please refresh the website and try again",

    "copySuccess":"Copy Success"
  },
  message: {
    importToken:"Import",
    showAd1Title: "Please mint an NFT and stake it to play the game",
    showAd1Option1: "Go to Mint",
    showAd1Option2: "Go to Stake",
    $PACO: "Buy $PACO",
    loading: 'Loading',
    needInputAddress: "Please paste the correct address.",
    needConnectWallet: "Please connect the wallet.",
    needChain: "Please switch Binance Smart Chain.",
    timer_day: "D",
    timer_hour: "H",
    timer_minute: "M",
    timer_second: "S",
    partClaim: "Mandatory Claim",
    allClaim: "100% Claim",
    claimPeriod: "Claim Period",
    claimValue: "Claim amount",

    clickOpen: "Click",
    picked: "Selected",
    nftunit: "",

    dividendCue1: "Currently failed to get the bonus",
    dividendCue2: "Currently only available",
    dividendCue3: "bonus",
    dividendCue4: "Currently available",

    // 歷史眾籌
    bidhistory_title: 'Claim Investment',
    claimed: "Withdrawn",

    // 主選單
    home: 'Home',
    game: 'Games',
    market: 'Market',
    crypto: 'White Paper',
    recommend: 'Recommend',
    buy$PACO: 'Buy $PACO',
    miltNFT: 'Mint NFT',
    linkWallet: 'Connect',

    // 時間
    timeline_caption_1: ' Time left ',
    timeline_caption_2: 'End time countdown',
    timeline_caption_3: 'The game has started counting down',
    timeline_caption_4: 'Crowdfunding end remaining time',
    timeline_day: 'Day',
    timeline_hour: 'Hour',
    timeline_minute: 'Minute',
    timeline_second: 'Second',
    start: 'Start',
    end: 'End',
    reciprocal: 'Countdown',
    finish: 'Finish',

    //共用
    yes: 'Yes',
    no: 'No',
    confirm: 'Confirm',
    cancel: 'Cancel',
    understand: 'Understand',
    submit: 'Submit',

    // 首頁選單
    caption: 'Description',
    characters: 'Calling Roles',
    features: 'Features',
    map: 'Roadmap',
    team: 'Partners and Investors',

    // 首頁banner
    banner_caption_1: 'Welcome to CryptoPaco',
    banner_caption_2: 'The world\'s first perpetual crypto loop game',
    banner_caption_3: 'Realize real earnings while playing',
    start_game: 'Start game',

    // 首頁說明
    caption_text_1: 'Crypto Paco is an NFT artifact created on the BNB chain that allows collectors to summon races. When you purchase a CryptoPaco NFT, you are not just buying a piece of art, rather you are making Paco your religion. You have the chance to participate in the sanctuary crowdfunding, raffles, national conferences and different alliances. As a result, you have the opportunity to enjoy maximum profits with CryptoPaco NFT, and even have the opportunity to participate in the World Hall Congress to enjoy the highest honor.',
    caption_text_2: 'As the number of CryptoPaco NFT holders increases, so will the price, and smart contracts will be written with an escalation mechanism so that early holders can enjoy fruitful results. CryptoPaco is written on a revolutionary algorithm that brings high rewards to those who are willing to give their stake.',

    // 召喚角色
    characters_name_1: 'Fairy (Elf Race)',
    characters_name_2: 'Paladin (Human Race)',
    characters_name_3: 'Devil (Demon Race)',

    mounts_name_1: 'Star Mount',
    mounts_name_2: 'Earth Mount',
    mounts_name_3: 'Sky Mount',

    // 特點
    features_title_1: 'Sanctuary',
    features_text_1_1: 'Each crowdfunding period is limited to a certain amount, once this amount has been reached the crowdfunding period is successful and you will get a 10%-15% crowdfunding bonus.',
    features_text_1_2: '',

    features_title_2: 'Mint NFT',
    features_text_2_1: '10,000 free MountNFTs (10% crowdfunding bonus) are provided, limited to one per player. You can also use USDT to mint your own CryptoPacoNFT (15% crowdfunding bonus).',
    features_text_2_2: '',

    features_title_3: 'Global',
    features_text_3_1: 'Stake PACO-Coins to become a shareholder of CryptoPaco.',
    features_text_3_2: 'Obtain profits of CryptoPaco depending on the number of tokens you have staked.',

    features_title_4: 'Alliance',
    features_text_4_1: 'Be a messenger of one of the three major races and create your own alliance or join one of others.',

    features_title_5: 'Social',
    features_text_5_1: 'You can play together with players from all around the world and earn high rewards together while playing.',
    features_text_5_2: '',

    features_title_6: 'Arena',
    features_text_6_1: 'You can participate in activities such as Zeus Fragments, Fountain of Wisdom,',
    features_text_6_2: 'Dark God\'s Counterattack, Creation Alliance Battle and other activities with a total bonus of more than 1.5 billion US dollars.',

    // 路線圖
    map_title_1: 'The First Stage',
    map_txt_1_1: 'ICO launch',
    map_txt_1_2: 'Test Website Online',
    map_txt_1_3: 'Start Social Media',
    map_txt_1_4: 'Paco Mounts NFT Whitelist Deployment',

    map_title_2: 'Second Stage ',
    map_txt_2_1: 'Smart contract deployment final test',
    map_txt_2_2: 'The official website is online',
    map_txt_2_3: 'Paco Mounts NFT Open Minting',
    map_txt_2_4: 'Temple Construction Crowdfunding Launched',
    map_txt_2_5: 'Enable token share function',

    map_title_3: 'The Third Stage',
    map_title_3_h4: 'CryptoPaco NFT Whitelist Deployment ',
    map_title_3_p: 'CryptoPaco NFT Open Minting',
    map_txt_3_1: 'Fountain of Wisdom',
    map_txt_3_2: 'Fragment of Zeus',
    map_txt_3_3: 'The Dark God\'s Counterattack',
    map_txt_3_4: 'Genesis Alliance Battle',

    map_title_4: 'Fourth Stage',
    map_txt_4_1: 'NFT Market',
    map_txt_4_2: 'DeFi Financial Department',
    map_txt_4_3: 'Open the World Palace Meeting',
    map_txt_4_4: 'Exclusive APP launch plan',

    map_title_5: 'Fifth Stage',
    map_txt_5_1: 'Blockchain land model',
    map_txt_5_2: 'NFT virtual reality integration platform',

    //遊戲選單
    getNFT: 'Get NFT',
    myNFT: 'My NFT',
    bid: 'Crowdfunding',
    arena: 'Arena',
    leaderboard: 'Leaderboards',
    dividend: 'Bonuses',
    role: 'title',
    adventurer: "Adventurer",
    brave: 'Army',
    envoys: 'Envoys',
    prophet: 'Prophet',
    holySpirit: 'Holy Spirit',
    norole: 'N/A',
    bidhistory: "Claim",

    // 獲得NFT
    getNFT_title: 'Get your NFT instantly',
    getNFT_MountNFT: ' MountNFT ',
    free_milt: 'Free Mint',
    NFT_price: 'Current Price ',
    NFT_unit: ' 1',
    milt: 'Mint',
    miltx3: 'Mint x 3',
    copy: 'Copy the invitation',
    paste: 'Paste the invitation',
    getNFT_modal_1: 'Stake directly after minting?',
    getNFT_modal_2: 'The minting is currently full and resets at UTC+0',

    // 動畫
    getNFT_text_1: 'Congratulations on getting this NFT',
    getNFT_text_2: 'Go to',
    getNFT_text_3: 'Stake now?',
    getNFT_text_4: 'Do you want to stake the NFT directly?',

    // $PACO
    buy_title: 'Pre-sale',
    overage: 'Balance',
    max: 'Balance',
    buy: 'Purchase',
    pledge: ' Staking ',
    onpledge: 'Staked',
    notpledge: 'Unstaked',
    pledge_remove: ' Unstake ',
    remove: 'Unlock',
    fixed: 'Lock',
    pledge_title: 'Stake, earn, and more',
    pledge_use: 'Available',
    stock: 'Total Staked',
    pledge_total: 'Total staked',
    profit: 'profit',
    extract_profit: 'Withdraw profits',
    buy_text_1: 'Stake directly after the purchase is completed?',
    buy_text_2: ' Currently not available for purchase, resets at UTC+0 ',
    buy_success: 'The purchase was successful',

    // 我的NFT
    myNFT_text_1: 'Do you confirm the staking? Staking NFTs can bring you earnings in the game',
    myNFT_text_2: 'Do you confirm the unstaking? Your earnings may cease after unstaking',
    myNFT_search: 'NFT search',
    myNFT_choose: 'Select the NFT',
    myNFT_all: 'All NFTs',
    director_badge: 'World Director Badge',
    enter_pledge: 'Stake',
    quit_pledge: 'Unstake',
    sell: 'Sell',

    // 我的推薦+紅利
    my_recommend: 'My Recommendation',
    share_name: 'Share',
    my_bonus: 'My Bonus',
    bonus_claimed: ' Received Bonus ',
    my_pet_bonus: 'My Crowdfunding Bonus',
    pet_bonus_claimed: 'Received Crowdfunding Bonus',
    my_paco_bonus: 'My NFT Bonus',
    paco_bonus_claimed: 'Received NFT Bonus',
    bonus_unaccalimed: ' Unclaimed Bonus ',
    withdrawal: 'Claim',
    bid_temple: 'Temple Crowdfunding Dividends',
    bid_CryptoPaco: 'CryptoPacoNFT Dividends',
    person_num: 'Number',
    current_bonus: 'Currently',
    total_bonus: 'Total',
    first: '1.Gen',
    second: '2.Gen',
    third: '3.Gen',
    fourth: '4.Gen',
    fifth: '5.Gen',
    sixth: '6.Gen',
    seventh: '7.Gen',
    eighth: '8.Gen',
    ninth: '9.Gen',
    tenth: '10.Gen',

    gen1: '1.Gen',
    gen2: '2.Gen',
    gen3: '3.Gen',
    gen4: '4.Gen',
    gen5: '5.Gen',
    gen6: '6.Gen',
    gen7: '7.Gen',
    gen8: '8.Gen',
    gen9: '9.Gen',
    gen10: '10.Gen',
    copy_title: 'My Invitation Link',
    total: 'Total',
    arena_bonus: 'Arena Bonuses',
    world_bonus: 'World Directors Dividend',

    // 眾籌遊戲
    bid_title_1: 'Phase',
    bid_title_2: '',
    bid_title_3: 'Target',
    bid_title_4: 'Round',
    period: 'Period',
    bid_amount: 'Total Crowdfunding Amount',
    own: 'Currently owned',
    total: "Total",
    initial_period: 'Initial Period',
    initial_principal: 'Current Principal',
    current_amount: 'Current amount',
    expected_profit: 'Estimated profit',
    withdraw: 'Withdrawals',
    withdrawable: 'Withdrawals are available',
    apply_withdraw: 'Apply for a withdrawal',
    waiting: 'waiting',
    bid_user_num: 'Number of users',
    bid_trade_num: 'Number of transactions',
    operate: 'Operate',
    progress_percent: "Progress",
    progress_done: "Done",
    progress_undone: "Undone",
    counting: "Calculating",

    // 提交
    bid_modal_1_text_1: 'Do you confirm the participation in the crowdfunding',
    bid_modal_1_text_2: 'Your funds will be automatically reinvested, with each successful crowdfunding period',
    bid_modal_2_text: 'Crowdfunding successful',
    bid_modal_3_text: 'The minimum amount to participate in the crowdfunding is:',
    bid_modal_4_text_1: 'Crowdfunding amount exceeded, please click',
    bid_modal_4_text_2: 'Get CryptoPacoNFT',
    bid_modal_4_text_3: 'and participate in the next crowdfunding period',
    bid_modal_5_text_1: 'This crowdfunding period is completed, please join as soon as possible in the next period',
    // 出金
    bid_modal_6_text_1: 'You can choose the withdrawal method',
    bid_modal_6_text_2: 'Withdrawals will be subject to a 3% smart contract call fee',
    full_staked: '100% withdrawal',
    obliged_staked: 'Forced withdrawals',
    full_staked_text: ' You need to wait for the 3rd successsful crowdfunding period before withdrawing money ',
    obliged_staked_text_1: 'Compulsory withdrawal of the third phase to return 70% of the crowdfunding amount,',
    obliged_staked_text_2: 'The amount you can withdraw is',
    withdrawTitle: "Crowdfunding failed, you can collect your principal and earnings below",
    withdrawTitle2: "*A new crowdfunding round is about to begin, the first round will start with a crowdfunding target of 10,000 USDT",
    withdraw70: "Crowdfunding failed, you can retrieve the following amount",
    withdraw100: "If you invested in the current crowdfunding period and it fails, you can recover 100% of the invested amount",

    upgrade: "If the upgrading conditions are met, you can upgrade your role after crowdfunding again."

  },
  claimDialog: {
    claim1: "Claim Success",
    claim2: "Total",
    confirm: "Confirm",
  }
}

