import { menuData, brandLink } from "../global/constants"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";








const Header = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const location = useLocation()


    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }

    let btn = document.querySelector('.game-btn');
    let menu = document.querySelector('.game-nav');
    let pay_content = document.querySelector('.pay-content');
    function pathListActive() {
        menu.classList.toggle('active');
    }

    function currentPathName() {
        if (location.pathname == '/game') {
            return "getNFT"
        }
        else if (location.pathname == '/mynft') {
            return "myNFT"
        }
        else if (location.pathname == '/bid') {
            return "bid"
        }
        else if (location.pathname == '/arena') {
            return "arena"
        }
        else if (location.pathname == '/leaderboard') {
            return "leaderboard"
        }
        else if (location.pathname == '/dividend') {
            return "dividend"
        }
        else if (location.pathname == '/buy') {
            return "$PACO"
        }
        else {
            return ""
        }

    }




    useEffect(() => {
        btn = document.querySelector('.game-btn');
        menu = document.querySelector('.game-nav');
        pay_content = document.querySelector('.pay-content');

    }, [])

    return <div className="bg-black"><div className="game-btn" onClick={() => pathListActive()}>{t(`message.${currentPathName()}`)} <svg width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4Z" /></svg></div>
        <div className="game-nav">
            <ul className="nav">
                <li className={location.pathname == '/game' ? "item active" : "item"}><Link to="/game">{t("message.getNFT")}</Link></li>
                <li className={location.pathname == '/mynft' ? "item active" : "item"}><Link to="/mynft">{t("message.myNFT")}</Link></li>
                <li className={location.pathname == '/bid' ? "item active" : "item"}><Link to="/bid">{t("message.bid")}</Link></li>
                <li className={location.pathname == '/arena' ? "item active" : "item"}><Link to="/arena">{t("message.arena")}</Link></li>
                <li className={location.pathname == '/leaderboard' ? "item active" : "item"}><Link to="/leaderboard">{t("message.leaderboard")}</Link></li>
                <li className={location.pathname == '/dividend' ? "item active" : "item"}><Link to="/dividend">{t("message.dividend")}</Link></li>
                <li className={location.pathname == '/buy' ? "item active" : "item"}><Link to="/buy">{t("message.$PACO")}</Link></li>
            </ul>

        </div>
    </div>


}
export default Header