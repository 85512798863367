

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Timer from "../../compontents/Timer"


import NavGame from "../../compontents/NavGame.js"
import Role from "../../compontents/Role.js"


import pacoImg from '../../assets/images/paco.png'
import tokenImg from '../../assets/images/token.png'

import usdtImg from '../../assets/images/usdt.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3"

import fundingAbi from "../../assets/contract/funding.json";

import { calAdd, history, fixedFloor, calMul, calDiv, needChainId, shareAddress, fundingAddress, usdtAddress, pacoAddress, heroAddress, petAddress, pacoIDOAddress, separatorNumber, pacoStakingAddress } from "../../global/constants"

import { useSelector, useDispatch } from 'react-redux';

const Buy = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [buyType, setBuyType] = useState(0)

    const walletAddress = useSelector(state => state.walletAddress);
    const loading = useSelector(state => state.loading);
    const shareAbi = require("../../assets/contract/share.json");
    const petAbi = require("../../assets/contract/pet.json");
    const heroAbi = require("../../assets/contract/hero.json");
    const usdtAbi = require("../../assets/contract/usdt.json");
    const pacoAbi = require("../../assets/contract/paco.json");
    const pacoIDOAbi = require("../../assets/contract/ido.json");
    const pacoStakingAbi = require("../../assets/contract/pacoStaking.json");



    var web3 = new Web3(Web3.givenProvider);


    var PacoContract = new web3.eth.Contract(
        pacoAbi,
        pacoAddress
    );

    var PacoStakingContract = new web3.eth.Contract(
        pacoStakingAbi,
        pacoStakingAddress
    );

    var FundingContract = new web3.eth.Contract(
        fundingAbi,
        fundingAddress
    );

    var ShareContract = new web3.eth.Contract(
        shareAbi,
        shareAddress
    );



    const unit = 'ether'

    const [historyList, setHistoryList] = useState(history)




    const [pacoExchangeValue, setPacoExchangeValue] = useState(0)

    const [stakAblePaco, setStakAblePaco] = useState(0)
    const [stakingPaco, setStakingPaco] = useState(0)

    const [totalStakingPaco, setTotalStakingPaco] = useState(0)

    const [inputStakPaco, setInputStakPaco] = useState(0)
    const [inputUnlockStakPaco, setInputUnlockStakPaco] = useState(0)

    const [claimBounsValue, setClaimBounsValue] = useState(0)
    const [claimedBounsValue, setClaimedBounsValue] = useState(0)
    const [claimedBouns, setClaimedBouns] = useState(false)

    const [ownPacoPercent, setOwnPacoPercent] = useState(0)


    const [refund70, setRefund70] = useState(0)
    const [refund100, setRefund100] = useState(0)
    const [claimed70, setClaimed70] = useState(false)
    const [claimed100, setClaimed100] = useState(false)



    const [fundingCurrentStatus, setFundingCurrentStatus] = useState(null)

    const isFirst = useRef(false)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)


    const [timeLeft, setTimeLeft] = useState(999999999);
    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);



    let btn = document.querySelector('.game-btn');
    let menu = document.querySelector('.game-nav');
    let add_token = document.querySelector('.add-token');
    let buymodal = document.querySelector('.milt-check-modal');
    let unbuymodal = document.querySelector('.unmiltmodal');
    let pay_modal = document.querySelector('.pay-modal');
    let max_btn = document.querySelector('.maxbtn');
    //btn.onClick =function(){
    //   menu.classList.toggle('active');
    //}


    function unbuycheck() {
        unbuymodal.classList.toggle('check');
        if (unbuymodal.classList.contains("check")) {
            buymodal.classList.toggle('check');
        };
    }

    async function getWalletInfo(selRound) {

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);

            try {

                console.log(history)
                let ShareContract = new web3.eth.Contract(
                    shareAbi,
                    history[selRound].shareAddress
                );
                let FundingContract = new web3.eth.Contract(
                    fundingAbi,
                    history[selRound].fundingAddress
                );



                //紅利
                let tempclaimX = await ShareContract.methods.claimX().call()
                let tempClaimBounsValue = web3.utils.fromWei(await ShareContract.methods.checkBouns(data[0], selRound).call(), unit)
                //let tempClaimedBounsValue = web3.utils.fromWei(await ShareContract.methods.claimer(data[0]).call(), unit)
                //let tempClaimedBouns = await ShareContract.methods.claimed(data[0]).call()



                let currentStatus = await FundingContract.methods.currentStatus().call()
                let getPoolAmount = await FundingContract.methods.getPoolAmount(currentStatus[3], data[0]).call()

                let checkProfit = await FundingContract.methods.checkProfit(data[0]).call()

                let tempClaimed70 = await FundingContract.methods.profitRefund(data[0]).call()
                let tempRefund70 = calAdd((web3.utils.fromWei(checkProfit[0], unit)), (web3.utils.fromWei(checkProfit[1], unit)))




                let tempClaimed100 = await FundingContract.methods.currentRefund(data[0]).call()
                let tempRefund100 = await FundingContract.methods.checkCurrentRefund(data[0]).call()


                const temp = historyList.map(item => {

                    if (item.round === selRound) {
                        item.claimX = tempclaimX
                        item.claimBounsValue = tempClaimBounsValue
                        //item.claimedBounsValue = tempClaimedBounsValue
                        //item.claimedBouns = tempClaimedBouns

                        item.fundingCurrentStatus = currentStatus
                        item.claimed70 = tempClaimed70
                        item.refund70 = tempRefund70
                        item.claimed100 = tempClaimed100
                        item.refund100 = tempRefund100
                    }
                    return item;
                });

                setHistoryList(temp);



                //setClaimBounsValue(web3.utils.fromWei(await ShareContract.methods.checkBouns(data[0],Number(tempclaimX) - 1).call(), unit))
                //setClaimedBounsValue(web3.utils.fromWei(await ShareContract.methods.claimer(data[0]).call(), unit))
                // console.log(checkRefundProfit)
                // let tempRefund70 = calAdd(calAdd(Number(web3.utils.fromWei(checkRefundProfit[0], unit)), Number(web3.utils.fromWei(checkRefundProfit[1], unit))), Number(web3.utils.fromWei(checkRefundProfit[2], unit)))
                // setRefund70(tempRefund70)

                // let claimed = await ShareContract.methods.claimed(data[0]).call()
                // if (claimed) {
                //     setClaimedBouns(true)
                // }


            } catch (error) {
                console.log(error);
            } finally {
            }
        });
    }


    async function claimBouns(selRound) {

        if (loading == true) {
            return
        }
        if (historyList[selRound].claimBounsValue == 0) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });
                let FundingContract = new web3.eth.Contract(
                    fundingAbi,
                    history[selRound].fundingAddress
                );

                let ShareContract = new web3.eth.Contract(
                    shareAbi,
                    history[selRound].shareAddress
                );

                // let allowClaim = await ShareContract.methods.allowClaim().call()
                // if (!allowClaim) {
                //     toast.error(t('toast.return calculating'));
                //     return
                // }

                console.log(
                    await ShareContract.methods.claim(data[0], selRound).send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                    })
                );


                getWalletInfo(selRound)
                return toast.success(t("toast.withdraw success"));


            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.withdraw cancel"));
                }
                else {
                    toast.error(t("toast.withdraw error"));
                }
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function claimFunding70(selRound) {

        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });
                let FundingContract = new web3.eth.Contract(
                    fundingAbi,
                    history[selRound].fundingAddress
                );

                let allowRefund = await FundingContract.methods.allowRefund().call()
                if (!allowRefund) {
                    toast.error(t("toast.return calculating"));
                    return
                }


                console.log(
                    await FundingContract.methods.refundProfit().send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                        // value: web3.utils.toWei(
                        //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                        //   "wei"
                        // ),
                    })
                );

                getWalletInfo(selRound)
                return
                //return toast.success(t("toast.buy success"));
            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.withdraw cancel"));
                }
                else {
                    toast.error(t('toast.withdraw fail'));
                }
                //bid()
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function claimFunding100(selRound) {

        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });
                let allowRefund = await FundingContract.methods.allowRefund().call()
                if (!allowRefund) {
                    toast.error(t("toast.return calculating"));
                    return
                }


                console.log(
                    await FundingContract.methods.refundCurrent().send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                        // value: web3.utils.toWei(
                        //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                        //   "wei"
                        // ),
                    })
                );
                //getFundingInfo()
                //getOwnUSDT()
                //bid_check()
                getWalletInfo(selRound)
                return
                //return toast.success(t("toast.buy success"));
            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.withdraw cancel"));
                }
                else {
                    toast.error(t('toast.withdraw fail'));
                }
                //bid()
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }







    useEffect(() => {

        btn = document.querySelector('.game-btn');
        menu = document.querySelector('.game-nav');
        add_token = document.querySelector('.add-token');
        buymodal = document.querySelector('.milt-check-modal');
        unbuymodal = document.querySelector('.unmiltmodal');
        pay_modal = document.querySelector('.pay-modal');
        max_btn = document.querySelector('.maxbtn');


        historyList.forEach((i) => console.log(getWalletInfo(i.round)));



    }, [walletAddress]);


    return <div id="app">
        <div className="hidden">{timeLeft}</div>


        <div className="unmiltmodal modal-box">
            <div className="mask" onClick={() => unbuycheck()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => unbuycheck()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.buy_text_2")}</p>
                    <div className="btn-box">
                        <a href="/buy.php" className="btn yellow-btn">{t("message.understand")}</a>
                    </div>
                </div>
            </div>
        </div>

        {/* <?php include("./layouts/nav.html"); ?> */}
        <section className="game-box">

            <div className="game-content">
                <div className="container">
                    <div className="game-content-head ">
                        <h2 className="title">{t('message.bidhistory_title')}</h2>
                    </div>
                    <div className="game-content-body">

                        <>


                            {historyList.map((i, index) =>
                                <div className="border mb-4" style={{ "borderWidth": "0px" }}>
                                    <div className="stake">
                                        <div className="stake-head justify-center">

                                            <h4 className="text-2xl text-main">{t('message.bid_title_1')} {i.round + 1} {t('message.bid_title_4')}</h4>


                                        </div>
                                        <div className="stake-body">

                                            <div className="interest">
                                                <div className="name mb-4">
                                                    <p>PACO {t("message.profit")}</p><div className="num">{i.claimBounsValue ? separatorNumber(fixedFloor(i.claimBounsValue, 5)) : 0} USDT </div>
                                                </div>

                                                <div className="btn-box">
                                                    <button className={`btn yellow-btn !w-auto min-w-[130px] mr-2 ${i.claimBounsValue ? " grayscale " : " "}`} disabled={i.claimBounsValue} type="button" onClick={() => claimBouns(i.round)}>{t("message.extract_profit")}</button>

                                                    <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/dai-bi-jing-ji/paco-dai-bi" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/paco-coin/token-information"} target="_block">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C"></path>
                                                        </svg>
                                                    </a>
                                                </div>

                                            </div>


                                            <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-4 lg:gap-10">
                                                <div className="bid-items">
                                                    <div className="inner relative z-10 dividend pt-6 pb-12">
                                                        <div className="item ">
                                                            <div className="dividend-content ">


                                                                <label className="name mb-2 text-[#FECE3F]">{t(`message.withdraw70`)}：<br /></label><br />

                                                                <div className="form_box">
                                                                    <div className="input-num " >{i.fundingCurrentStatus != null && i.fundingCurrentStatus[1] == true ? separatorNumber(parseInt(i.refund70)) : t("message.counting")}</div>

                                                                    <span className="dollor">USDT</span>
                                                                </div>


                                                                <button className={`btn yellow-btn !w-auto ${i.claimed70 ? " grayscale !cursor-default" : ""}`} type="button" disabled={i.claimed70} onClick={() => claimFunding70(i.round)}>{i.claimed70 ? t('message.claimed') : t("message.withdrawal")}</button>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bid-items">
                                                    <div className="inner relative z-10 dividend pt-6 pb-12">
                                                        <div className="item ">
                                                            <div className="dividend-content ">


                                                                <label className="name mb-2 text-[#FECE3F]">{t(`message.withdraw100`)}：<br /></label><br />

                                                                <div className="form_box">
                                                                    <div className="input-num " >{i.fundingCurrentStatus != null && i.fundingCurrentStatus[1] == true ? separatorNumber(parseInt(i.refund100)) : t("message.counting")}</div>
                                                                    <span className="dollor">USDT</span>
                                                                </div>

                                                                <button className={`btn yellow-btn !w-auto ${i.claimed100 ? " grayscale !cursor-default" : " "}`} type="button" disabled={i.claimed100} onClick={() => claimFunding100(i.round)}>{i.claimed100 ? t('message.claimed') : t("message.withdrawal")}</button>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </>

                    </div>







                </div>

            </div>
        </section>



    </div>
}

export default Buy