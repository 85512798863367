

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Timer from "../../compontents/Timer"

import { addPacoToken, addUSDTToken } from '../../global/constants'

import NavGame from "../../compontents/NavGame.js"
import Role from "../../compontents/Role.js"


import pacoImg from '../../assets/images/paco.png'
import tokenImg from '../../assets/images/token.png'

import usdtImg from '../../assets/images/usdt.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3"

import fundingAbi from "../../assets/contract/funding.json";

import { fixedFloor, calMul, calDiv, needChainId, shareAddress, fundingAddress, usdtAddress, pacoAddress, heroAddress, petAddress, pacoIDOAddress, separatorNumber, pacoStakingAddress } from "../../global/constants"

import { useSelector, useDispatch } from 'react-redux';

const Buy = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [buyType, setBuyType] = useState(0)

    const walletAddress = useSelector(state => state.walletAddress);
    const loading = useSelector(state => state.loading);
    const shareAbi = require("../../assets/contract/share.json");
    const petAbi = require("../../assets/contract/pet.json");
    const heroAbi = require("../../assets/contract/hero.json");
    const usdtAbi = require("../../assets/contract/usdt.json");
    const pacoAbi = require("../../assets/contract/paco.json");
    const pacoIDOAbi = require("../../assets/contract/ido.json");
    const pacoStakingAbi = require("../../assets/contract/pacoStaking.json");



    var web3 = new Web3(Web3.givenProvider);

    var PacoIDOContract = new web3.eth.Contract(
        pacoIDOAbi,
        pacoIDOAddress
    );

    var USDTContract = new web3.eth.Contract(
        usdtAbi,
        usdtAddress
    );

    var PacoContract = new web3.eth.Contract(
        pacoAbi,
        pacoAddress
    );

    var HeroContract = new web3.eth.Contract(
        heroAbi,
        heroAddress
    );

    var PetContract = new web3.eth.Contract(
        petAbi,
        petAddress
    );

    var PacoStakingContract = new web3.eth.Contract(
        pacoStakingAbi,
        pacoStakingAddress
    );

    var FundingContract = new web3.eth.Contract(
        fundingAbi,
        fundingAddress
    );

    var ShareContract = new web3.eth.Contract(
        shareAbi,
        shareAddress
    );

    const [IDOValue1, setIDOValue1] = useState(0.0)
    const [IDOValue2, setIDOValue2] = useState(0.0)

    const [closeTime, setCloseTime] = useState((1656504000) - Math.floor(Date.now() / 1000))

    const buyStage = 1

    const [balanceOfUSDT, setBalanceOfUSDT] = useState(0)

    const unit = 'ether'

    const handleChangeIDOValue1 = event => {
        if (event.target.value.length > 10) {
            return
        }
        setIDOValue1(event.target.value);
        setIDOValue2(calDiv(event.target.value, pacoExchangeValue));

    };

    const handleChangeIDOValue2 = event => {
        if (event.target.value.length > 10) {
            return
        }
        setIDOValue1(calMul(event.target.value, pacoExchangeValue));
        setIDOValue2(event.target.value);
    };


    const [pacoExchangeValue, setPacoExchangeValue] = useState(0)

    const [stakAblePaco, setStakAblePaco] = useState(0)
    const [stakingPaco, setStakingPaco] = useState(0)

    const [totalStakingPaco, setTotalStakingPaco] = useState(0)

    const [inputStakPaco, setInputStakPaco] = useState(0)
    const [inputUnlockStakPaco, setInputUnlockStakPaco] = useState(0)

    const [claimBounsValue, setClaimBounsValue] = useState(0)
    const [claimedBounsValue, setClaimedBounsValue] = useState(0)
    const [claimedBouns, setClaimedBouns] = useState(false)

    const [ownPacoPercent, setOwnPacoPercent] = useState(0)


    const [claimX, setClaimX] = useState(0)



    const [fundingCurrentStatus, setFundingCurrentStatus] = useState(null)
    const fundingCurrentStatusRef = useRef(null)

    const isFirst = useRef(false)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)


    const [timeLeft, setTimeLeft] = useState(999999999);
    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);



    let btn = document.querySelector('.game-btn');
    let menu = document.querySelector('.game-nav');
    let add_token = document.querySelector('.add-token');
    let buymodal = document.querySelector('.milt-check-modal');
    let unbuymodal = document.querySelector('.unmiltmodal');
    let pay_modal = document.querySelector('.pay-modal');
    let max_btn = document.querySelector('.maxbtn');
    //btn.onClick =function(){
    //   menu.classList.toggle('active');
    //}
    function addtoken() {
        add_token.classList.toggle('check');
    }
    function buycheck() {
        buymodal.classList.toggle('check');
    }

    function change() {

    }

    function unbuycheck() {
        unbuymodal.classList.toggle('check');
        if (unbuymodal.classList.contains("check")) {
            buymodal.classList.toggle('check');
        };
    }
    function pay(needStake) {
        //pay_modal.classList.toggle('check');
        console.log(IDOValue2)
        buyToken(IDOValue2, needStake)
        //if (pay_modal.classList.contains("check")) {
        buymodal.classList.toggle('check');
        //};

    }

    async function getWalletInfo() {

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);

            try {

                // if (fundingAddress !== "") {
                //     let currentStatus = await FundingContract.methods.currentStatus().call()
                //     let tempStartTime = Number(await FundingContract.methods.start().call())
                //     let tempEndTime = Number(await FundingContract.methods.end().call())

                //     if (isFirst.current == false) {
                //         setStartTime(tempStartTime)
                //         setEndTime(tempEndTime)
                //         isFirst.current = true
                //     }
                //     setFundingCurrentStatus(currentStatus)
                // }



                let USDT = (web3.utils.fromWei(await USDTContract.methods.balanceOf(data[0]).call(), unit))


                //let exchange = web3.utils.fromWei(await PacoIDOContract.methods.price().call(), unit)

                let exchange = 0.1

                let Paco = Math.floor(web3.utils.fromWei(await PacoContract.methods.balanceOf(data[0]).call(), unit), 2)



                let stakingPaco = Math.floor(web3.utils.fromWei(await PacoStakingContract.methods.staker(data[0]).call(), unit))
                console.log(stakingPaco)
                let totalStakingPaco = Math.floor(web3.utils.fromWei(await PacoStakingContract.methods.totalStaked().call(), unit))
                console.log(totalStakingPaco)
                //let stakingPaco = parseFloat(web3.utils.fromWei(await PacoStakingContract.methods.staker(data[0]).call(), unit))
                let totalPaco = Math.floor(web3.utils.fromWei(await PacoContract.methods.totalSupply().call(), unit))
                console.log(stakingPaco)
                console.log(totalStakingPaco)




                //setOwnPacoPercent((web3.utils.fromWei(await PacoStakingContract.methods.shares(data[0]).call(), unit)))
                setOwnPacoPercent(fixedFloor(calMul(calDiv(stakingPaco, totalPaco), 1), 2))
                //setOwnPacoPercent((calMul(calDiv(stakingPaco, totalPaco), 100)).toFixed(2))
                //console.log(web3.utils.fromWei(await PacoStakingContract.methods.shares(data[0]).call(), unit))
                //setOwnPacoPercent(await PacoStakingContract.methods.shares(data[0]).call())
                setTotalStakingPaco(totalStakingPaco)


                setStakingPaco(stakingPaco)


                setStakAblePaco(Paco)
                setBalanceOfUSDT(USDT)

                setPacoExchangeValue(exchange)

                //     getBouns

                let tempclaimX = await ShareContract.methods.claimX().call()
                console.log(tempclaimX)
                setClaimX(tempclaimX)
                setClaimBounsValue(web3.utils.fromWei(await ShareContract.methods.checkBouns(data[0], Number(tempclaimX) - 1).call(), unit))


                //setClaimedBounsValue(web3.utils.fromWei(await ShareContract.methods.claimer(data[0]).call(), unit))
                // console.log(checkRefundProfit)
                // let tempRefund70 = calAdd(calAdd(Number(web3.utils.fromWei(checkRefundProfit[0], unit)), Number(web3.utils.fromWei(checkRefundProfit[1], unit))), Number(web3.utils.fromWei(checkRefundProfit[2], unit)))
                // setRefund70(tempRefund70)

                // let claimed = await ShareContract.methods.claimed(data[0]).call()
                // if (claimed) {
                //     setClaimedBouns(true)
                // }


            } catch (error) {
                console.log(error);
            } finally {
            }
        });
    }


    async function claimBouns() {


        if (loading == true) {
            return
        }
        if (claimBounsValue == 0) {
            toast.error(t("toast.withdraw0USDT"));
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });

                // let allowClaim = await ShareContract.methods.allowClaim().call()
                // if (!allowClaim) {
                //     toast.error(t('toast.return calculating'));
                //     return
                // }

                console.log(
                    await ShareContract.methods.claim(data[0], Number(claimX) - 1).send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                    })
                );


                getWalletInfo()
                toast.success(t("toast.withdraw success"));

                dispatch({
                    type: "UPDATE_CLAIMDIALOG",
                    payload: { claimDialog: { currency: "USDT", value: claimBounsValue } }
                });


            } catch (error) {
                console.log(error);
                toast.error(t("toast.withdraw error"));
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function stakToken(buyCount) {

        //buyCount = buyCount / pacoExchangeValue
        console.log(buyCount)
        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });
                let totalPrice = buyCount
                let balanceOfWei = parseFloat(web3.utils.fromWei(await PacoContract.methods.balanceOf(data[0]).call(), unit))


                console.log(balanceOfWei)
                console.log(totalPrice)
                if (balanceOfWei >= totalPrice) {
                    let allowanceWei = parseFloat(web3.utils.fromWei(await PacoContract.methods.allowance(data[0], pacoStakingAddress).call(), unit))
                    console.log(allowanceWei)
                    // console.log(allowanceWei - totalPrice )
                    if (allowanceWei < totalPrice) {

                        await PacoContract.methods.approve(pacoStakingAddress, web3.utils.toWei(String(500000), unit)).send({ from: data[0], gasPrice: await web3.eth.getGasPrice() })
                    }

                }
                else {
                    return toast.error(t("toast.Not enough PACO"));
                }
                console.log(
                    await PacoStakingContract.methods.stak(data[0], web3.utils.toWei(String(buyCount), unit)).send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                    })
                );
                setInputStakPaco("")

                getWalletInfo()

                return toast.success(t("toast.stake success"));


            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.stake cancel"));
                }
                else {
                    toast.error(t("toast.stake error"));
                }


            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function unStakToken(buyCount) {
        //buyCount = buyCount / pacoExchangeValue
        console.log(buyCount)
        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });

                if (stakingPaco < inputUnlockStakPaco) {
                    return toast.error(t("toast.Not enough PACO"));
                }
                console.log(
                    await PacoStakingContract.methods.claim(data[0], web3.utils.toWei(String(buyCount), unit)).send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                    })
                );

                setInputUnlockStakPaco("")

                getWalletInfo()
                return toast.success(t("toast.unstake success"));


            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.unstake cancel"));
                }
                else {
                    toast.error(t("toast.unstake error"));
                }
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function buyToken(buyCount, needStake) {

        //buyCount = buyCount / pacoExchangeValue
        console.log(buyCount)
        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });
                //let getPrice = await PacoIDOContract.methods.price().call()
                let getPrice = pacoExchangeValue
                //let totalPrice = (web3.utils.fromWei(getPrice, unit)*buyCount)

                let totalPrice = IDOValue1


                //console.log(web3.utils.toWei(String(totalPrice),unit))
                //console.log(totalPrice)
                let balanceOfWei = parseFloat(web3.utils.fromWei(await USDTContract.methods.balanceOf(data[0]).call(), unit))

                console.log(getPrice)
                console.log(balanceOfWei)
                console.log(totalPrice)
                if (balanceOfWei >= totalPrice) {
                    let allowanceWei = parseFloat(web3.utils.fromWei(await USDTContract.methods.allowance(data[0], pacoIDOAddress).call(), unit))
                    console.log(allowanceWei)

                    // console.log(allowanceWei - totalPrice )
                    if (allowanceWei < totalPrice) {

                        await USDTContract.methods.approve(pacoIDOAddress, web3.utils.toWei(String(IDOValue2), unit)).send({ from: data[0], gasPrice: await web3.eth.getGasPrice() })
                    }

                }
                else {
                    return toast.error(t("toast.Not enough USDT"));
                }

                console.log(
                    await PacoIDOContract.methods.buy(web3.utils.toWei(String(buyCount), unit)).send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                        // value: web3.utils.toWei(
                        //   String((buyCount) * price),
                        //   "wei"
                        // ),
                    })
                );


                let temp = IDOValue2
                setIDOValue1("")
                setIDOValue2("")

                getWalletInfo()
                toast.success(t("toast.buy success"));

                if (needStake) {
                    await stakToken(temp)
                }


            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.buy cancel"));
                }
                else {
                    toast.error(t("toast.buy error"));
                }
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }



    useEffect(() => {

        if (walletAddress !== undefined) {
            getWalletInfo()
        }

    }, [walletAddress]);

    useEffect(() => {

        btn = document.querySelector('.game-btn');
        menu = document.querySelector('.game-nav');
        add_token = document.querySelector('.add-token');
        buymodal = document.querySelector('.milt-check-modal');
        unbuymodal = document.querySelector('.unmiltmodal');
        pay_modal = document.querySelector('.pay-modal');
        max_btn = document.querySelector('.maxbtn');

    }, []);


    return <div id="app">
        <div className="hidden">{timeLeft}</div>

        <div className="milt-check-modal modal-box">
            <div className="mask" onClick={() => buycheck()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => buycheck()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.buy_text_1")}</p>
                    <div className="btn-box">
                        <a onClick={() => pay(true)} className="btn yellow-btn">{t("message.yes")}</a>
                        <a onClick={() => pay(false)} className="btn black-btn">{t("message.no")}</a>
                    </div>
                </div>

            </div>
        </div>
        <div className="unmiltmodal modal-box">
            <div className="mask" onClick={() => unbuycheck()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => unbuycheck()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.buy_text_2")}</p>
                    <div className="btn-box">
                        <a href="/buy.php" className="btn yellow-btn">{t("message.understand")}</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="pay-modal modal-box">
            <div className="mask" onClick={() => pay()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => pay()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.buy_success")}</p>
                    <div className="btn-box">
                        <a href="/buy.php" className="btn yellow-btn">{t("message.confirm")}</a>
                    </div>
                </div>
            </div>
        </div>

        {/* <?php include("./layouts/nav.html"); ?> */}
        <section className="game-box">
            <div className="container">
                <NavGame></NavGame>
                {/* <?php include("./layouts/nav_game.html"); ?> */}
            </div>
            <div className="game-content">
                <div className="container">
                    <div className="game-content-head">
                        <Role></Role>
                        <h2 className="title">{t("message.buy_title")}</h2>
                        {/* {
                            startTime > 0 && endTime > 0 && false ?
                                startTime - Math.floor(Date.now() / 1000) > 0 ?
                                    <Timer titleType={'1'} closeTime={(startTime)}></Timer>
                                    :
                                    <Timer titleType={'2'} closeTime={(fundingCurrentStatus != null && (fundingCurrentStatus[1] || fundingCurrentStatus[2])) ? 0 : (endTime)}></Timer>
                                : ""

                        } */}
                    </div>
                    <div className="game-content-body">
                        <div className="border" style={{ "borderWidth": "0px" }}>
                            <div className="swap">
                                <div className="title">USDT/PACO <span className='num'>{(pacoExchangeValue)}</span></div>
                                <div className="itembox">
                                    <div className="item" id="swapA">
                                        <div className="head">
                                            <div className="name"><i><img src={usdtImg} alt="" /></i>USDT
                                                <div className=" cursor-pointer flex items-center gap-2 " onClick={() => addUSDTToken()}>
                                                    <svg viewBox="0 0 40 40" width="30px" color="text" xmlns="http://www.w3.org/2000/svg" className="sc-8a800401-0 hROqIC">
                                                        <path
                                                            d="M36.0112 3.33337L22.1207 13.6277L24.7012 7.56091L36.0112 3.33337Z"
                                                            fill="#E17726"></path>
                                                        <path
                                                            d="M4.00261 3.33337L17.7558 13.7238L15.2989 7.56091L4.00261 3.33337Z"
                                                            fill="#E27625"></path>
                                                        <path
                                                            d="M31.0149 27.2023L27.3227 32.8573L35.2287 35.0397L37.4797 27.3258L31.0149 27.2023Z"
                                                            fill="#E27625"></path>
                                                        <path
                                                            d="M2.53386 27.3258L4.77116 35.0397L12.6772 32.8573L8.9987 27.2023L2.53386 27.3258Z"
                                                            fill="#E27625"></path>
                                                        <path
                                                            d="M12.2518 17.6496L10.0419 20.9712L17.8793 21.3281L17.6048 12.8867L12.2518 17.6496Z"
                                                            fill="#E27625"></path>
                                                        <path
                                                            d="M27.762 17.6494L22.3129 12.7905L22.1207 21.3279L29.9581 20.9711L27.762 17.6494Z"
                                                            fill="#E27625"></path>
                                                        <path
                                                            d="M12.6772 32.8574L17.3989 30.5652L13.336 27.3809L12.6772 32.8574Z"
                                                            fill="#E27625"></path>
                                                        <path
                                                            d="M22.6009 30.5652L27.3226 32.8574L26.6637 27.3809L22.6009 30.5652Z"
                                                            fill="#E27625"></path>
                                                        <path
                                                            d="M27.3226 32.8575L22.6009 30.5653L22.9715 33.6399L22.9303 34.9301L27.3226 32.8575Z"
                                                            fill="#D5BFB2"></path>
                                                        <path
                                                            d="M12.6772 32.8575L17.0694 34.9301L17.042 33.6399L17.3989 30.5653L12.6772 32.8575Z"
                                                            fill="#D5BFB2"></path>
                                                        <path
                                                            d="M17.1518 25.3495L13.2262 24.1965L15.9988 22.92L17.1518 25.3495Z"
                                                            fill="#233447"></path>
                                                        <path d="M22.848 25.3495L24.001 22.92L26.801 24.1965L22.848 25.3495Z"
                                                            fill="#233447"></path>
                                                        <path
                                                            d="M12.6773 32.8573L13.3635 27.2023L8.99876 27.3258L12.6773 32.8573Z"
                                                            fill="#CC6228"></path>
                                                        <path
                                                            d="M26.6364 27.2023L27.3227 32.8573L31.0149 27.3258L26.6364 27.2023Z"
                                                            fill="#CC6228"></path>
                                                        <path
                                                            d="M29.9581 20.9709L22.1207 21.3278L22.8482 25.3495L24.0011 22.92L26.8012 24.1965L29.9581 20.9709Z"
                                                            fill="#CC6228"></path>
                                                        <path
                                                            d="M13.2263 24.1965L15.9989 22.92L17.1519 25.3495L17.8793 21.3278L10.0419 20.9709L13.2263 24.1965Z"
                                                            fill="#CC6228"></path>
                                                        <path
                                                            d="M10.0419 20.9709L13.3361 27.3809L13.2263 24.1965L10.0419 20.9709Z"
                                                            fill="#E27525"></path>
                                                        <path
                                                            d="M26.8011 24.1965L26.6638 27.3809L29.958 20.9709L26.8011 24.1965Z"
                                                            fill="#E27525"></path>
                                                        <path
                                                            d="M17.8793 21.3278L17.1519 25.3494L18.0715 30.0985L18.2637 23.8396L17.8793 21.3278Z"
                                                            fill="#E27525"></path>
                                                        <path
                                                            d="M22.1205 21.3278L21.7499 23.8258L21.9283 30.0985L22.848 25.3494L22.1205 21.3278Z"
                                                            fill="#E27525"></path>
                                                        <path
                                                            d="M22.848 25.3496L21.9284 30.0987L22.601 30.5654L26.6638 27.381L26.8011 24.1967L22.848 25.3496Z"
                                                            fill="#F5841F"></path>
                                                        <path
                                                            d="M13.2262 24.1967L13.336 27.381L17.3989 30.5654L18.0714 30.0987L17.1518 25.3496L13.2262 24.1967Z"
                                                            fill="#F5841F"></path>
                                                        <path
                                                            d="M22.9303 34.93L22.9715 33.6398L22.6284 33.3378H17.3714L17.042 33.6398L17.0694 34.93L12.6772 32.8574L14.2145 34.1202L17.3302 36.2751H22.6696L25.7853 34.1202L27.3226 32.8574L22.9303 34.93Z"
                                                            fill="#C0AC9D"></path>
                                                        <path
                                                            d="M22.601 30.5653L21.9284 30.0986H18.0715L17.3989 30.5653L17.0421 33.6399L17.3715 33.3379H22.6285L22.9716 33.6399L22.601 30.5653Z"
                                                            fill="#161616"></path>
                                                        <path
                                                            d="M36.5875 14.3003L37.7542 8.61779L36.011 3.33337L22.6009 13.2846L27.7618 17.6493L35.0365 19.7768L36.6424 17.8964L35.9424 17.3886L37.0679 16.3728L36.2169 15.7003L37.3287 14.863L36.5875 14.3003Z"
                                                            fill="#763E1A"></path>
                                                        <path
                                                            d="M2.24573 8.61779L3.42615 14.3003L2.67123 14.863L3.78302 15.7003L2.93202 16.3728L4.05753 17.3886L3.35752 17.8964L4.96343 19.7768L12.2518 17.6493L17.399 13.2846L4.00263 3.33337L2.24573 8.61779Z"
                                                            fill="#763E1A"></path>
                                                        <path
                                                            d="M35.0365 19.777L27.7619 17.6495L29.958 20.9712L26.6638 27.3811L31.0149 27.3262H37.4797L35.0365 19.777Z"
                                                            fill="#F5841F"></path>
                                                        <path
                                                            d="M12.2517 17.6495L4.96332 19.777L2.53386 27.3262H8.99869L13.336 27.3811L10.0419 20.9712L12.2517 17.6495Z"
                                                            fill="#F5841F"></path>
                                                        <path
                                                            d="M22.1205 21.3276L22.6009 13.2843L24.701 7.56067H15.2988L17.3988 13.2843L17.8792 21.3276L18.0577 23.8531L18.0714 30.0984H21.9283L21.9421 23.8531L22.1205 21.3276Z"
                                                            fill="#F5841F"></path>
                                                    </svg>
                                                    <p className=" text-main border-[1px] text-sm  px-3 py-1 border-solid border-main rounded-full">{t('message.importToken')}</p>
                                                </div>
                                            </div>
                                            <div className="sale">{t("message.overage")}：<span>{separatorNumber(fixedFloor(balanceOfUSDT, 5))}</span></div>
                                        </div>
                                        <div className="input-control"><input type="number" onChange={handleChangeIDOValue1} value={IDOValue1} /><button className="maxbtn" onClick={() => [setIDOValue1(balanceOfUSDT), setIDOValue2(calDiv(balanceOfUSDT, pacoExchangeValue))]}>{t("message.max")}</button></div>


                                    </div>
                                    <a onClick={() => change()} className="change pointer-events-none">
                                        <svg width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7.15 21.1q-.375-.375-.375-.888q0-.512.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375q.512 0 .887.375l8.4 8.425q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325L8.9 21.125q-.35.35-.862.35q-.513 0-.888-.375Z" /></svg>
                                        {/* <svg width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 10H3l4-4m0 8h14l-4 4" /></svg> */}
                                    </a>
                                    <div className="item" id="swapB">
                                        <div className="head">
                                            <div className="name"><i><img src={pacoImg} alt="" /></i>PACO
                                                <div  className=" cursor-pointer flex items-center gap-2 " onClick={() => addPacoToken(walletAddress)}>
                                                    <svg viewBox="0 0 40 40" width="30px" color="text" xmlns="http://www.w3.org/2000/svg" className="sc-8a800401-0 hROqIC">
                                                        <path d="M36.0112 3.33337L22.1207 13.6277L24.7012 7.56091L36.0112 3.33337Z" fill="#E17726"></path>
                                                        <path d="M4.00261 3.33337L17.7558 13.7238L15.2989 7.56091L4.00261 3.33337Z" fill="#E27625"></path>
                                                        <path d="M31.0149 27.2023L27.3227 32.8573L35.2287 35.0397L37.4797 27.3258L31.0149 27.2023Z" fill="#E27625"></path>
                                                        <path d="M2.53386 27.3258L4.77116 35.0397L12.6772 32.8573L8.9987 27.2023L2.53386 27.3258Z" fill="#E27625"></path>
                                                        <path d="M12.2518 17.6496L10.0419 20.9712L17.8793 21.3281L17.6048 12.8867L12.2518 17.6496Z" fill="#E27625"></path>
                                                        <path d="M27.762 17.6494L22.3129 12.7905L22.1207 21.3279L29.9581 20.9711L27.762 17.6494Z" fill="#E27625"></path>
                                                        <path d="M12.6772 32.8574L17.3989 30.5652L13.336 27.3809L12.6772 32.8574Z" fill="#E27625"></path>
                                                        <path d="M22.6009 30.5652L27.3226 32.8574L26.6637 27.3809L22.6009 30.5652Z" fill="#E27625"></path>
                                                        <path d="M27.3226 32.8575L22.6009 30.5653L22.9715 33.6399L22.9303 34.9301L27.3226 32.8575Z" fill="#D5BFB2"></path>
                                                        <path d="M12.6772 32.8575L17.0694 34.9301L17.042 33.6399L17.3989 30.5653L12.6772 32.8575Z" fill="#D5BFB2"></path>
                                                        <path d="M17.1518 25.3495L13.2262 24.1965L15.9988 22.92L17.1518 25.3495Z" fill="#233447"></path>
                                                        <path d="M22.848 25.3495L24.001 22.92L26.801 24.1965L22.848 25.3495Z" fill="#233447"></path>
                                                        <path d="M12.6773 32.8573L13.3635 27.2023L8.99876 27.3258L12.6773 32.8573Z" fill="#CC6228"></path>
                                                        <path d="M26.6364 27.2023L27.3227 32.8573L31.0149 27.3258L26.6364 27.2023Z" fill="#CC6228"></path>
                                                        <path d="M29.9581 20.9709L22.1207 21.3278L22.8482 25.3495L24.0011 22.92L26.8012 24.1965L29.9581 20.9709Z" fill="#CC6228"></path>
                                                        <path d="M13.2263 24.1965L15.9989 22.92L17.1519 25.3495L17.8793 21.3278L10.0419 20.9709L13.2263 24.1965Z" fill="#CC6228"></path>
                                                        <path d="M10.0419 20.9709L13.3361 27.3809L13.2263 24.1965L10.0419 20.9709Z" fill="#E27525"></path>
                                                        <path d="M26.8011 24.1965L26.6638 27.3809L29.958 20.9709L26.8011 24.1965Z" fill="#E27525"></path>
                                                        <path d="M17.8793 21.3278L17.1519 25.3494L18.0715 30.0985L18.2637 23.8396L17.8793 21.3278Z" fill="#E27525"></path>
                                                        <path d="M22.1205 21.3278L21.7499 23.8258L21.9283 30.0985L22.848 25.3494L22.1205 21.3278Z" fill="#E27525"></path>
                                                        <path d="M22.848 25.3496L21.9284 30.0987L22.601 30.5654L26.6638 27.381L26.8011 24.1967L22.848 25.3496Z" fill="#F5841F"></path>
                                                        <path d="M13.2262 24.1967L13.336 27.381L17.3989 30.5654L18.0714 30.0987L17.1518 25.3496L13.2262 24.1967Z" fill="#F5841F"></path>
                                                        <path d="M22.9303 34.93L22.9715 33.6398L22.6284 33.3378H17.3714L17.042 33.6398L17.0694 34.93L12.6772 32.8574L14.2145 34.1202L17.3302 36.2751H22.6696L25.7853 34.1202L27.3226 32.8574L22.9303 34.93Z"
                                                            fill="#C0AC9D"></path>
                                                        <path d="M22.601 30.5653L21.9284 30.0986H18.0715L17.3989 30.5653L17.0421 33.6399L17.3715 33.3379H22.6285L22.9716 33.6399L22.601 30.5653Z"
                                                            fill="#161616"></path>
                                                        <path d="M36.5875 14.3003L37.7542 8.61779L36.011 3.33337L22.6009 13.2846L27.7618 17.6493L35.0365 19.7768L36.6424 17.8964L35.9424 17.3886L37.0679 16.3728L36.2169 15.7003L37.3287 14.863L36.5875 14.3003Z"
                                                            fill="#763E1A"></path>
                                                        <path d="M2.24573 8.61779L3.42615 14.3003L2.67123 14.863L3.78302 15.7003L2.93202 16.3728L4.05753 17.3886L3.35752 17.8964L4.96343 19.7768L12.2518 17.6493L17.399 13.2846L4.00263 3.33337L2.24573 8.61779Z"
                                                            fill="#763E1A"></path>
                                                        <path d="M35.0365 19.777L27.7619 17.6495L29.958 20.9712L26.6638 27.3811L31.0149 27.3262H37.4797L35.0365 19.777Z"
                                                            fill="#F5841F"></path>
                                                        <path d="M12.2517 17.6495L4.96332 19.777L2.53386 27.3262H8.99869L13.336 27.3811L10.0419 20.9712L12.2517 17.6495Z"
                                                            fill="#F5841F"></path>
                                                        <path d="M22.1205 21.3276L22.6009 13.2843L24.701 7.56067H15.2988L17.3988 13.2843L17.8792 21.3276L18.0577 23.8531L18.0714 30.0984H21.9283L21.9421 23.8531L22.1205 21.3276Z"
                                                            fill="#F5841F"></path>
                                                    </svg>
                                                    <p className=" text-main border-[1px] text-sm  px-3 py-1 border-solid border-main rounded-full">{t('message.importToken')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-control"><input type="number" onChange={handleChangeIDOValue2} value={IDOValue2} /></div>
                                    </div>
                                    <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/paco-coin/initial-coin-offering" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/paco-coin/initial-coin-offering"} target="_block" className="link">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C" />
                                        </svg>
                                    </a>
                                </div>


                                <div className="btn-box">
                                    <a onClick={() => buycheck()} className="btn yellow-btn buy">{t("message.buy")}</a>
                                </div>

                            </div>

                        </div>
                        <>
                            <div className="text">
                                <i><img src={pacoImg} alt="" /></i><h2 className="title">{t("message.pledge")} PACO <span>{t("message.pledge_title")} </span></h2>
                            </div>

                            <div className="border" style={{ "borderWidth": "0px" }}>
                                <div className="stake">
                                    <div className="stake-head ">
                                        <div className="name">
                                            <h4 className="subtitle">PACO {t("message.pledge_use")} <span className="num">{separatorNumber(stakAblePaco)} </span></h4>
                                        </div>
                                        <div className="name"><h4 className="subtitle">PACO {t("message.onpledge")}<span className="num">{separatorNumber(stakingPaco)}</span></h4></div>
                                        {/* <div className="name truncate"><h4 className="subtitle">{t("message.stock")}<span className="num">{stakingPaco > 0 && ownPacoPercent == 0 ? `<1`:fixedFloor(calMul(ownPacoPercent, 100), 5)}%</span></h4></div> */}
                                        {/* <div className="name"><h4 className="subtitle">{t("message.pledge_total")}<span className="num">{separatorNumber(totalStakingPaco)} PACO</span></h4></div> */}
                                    </div>
                                    <div className="stake-body">
                                        <div className="stake-items">
                                            <ul>
                                                <li className="item">
                                                    <div className="name">
                                                        <p>{t("message.pledge")}</p>
                                                        {/* <div className="num">{separatorNumber(stakingPaco)} PACO </div> */}
                                                    </div>
                                                    <div className="content">
                                                        <div className="input-control">
                                                            <input type="number" value={inputStakPaco} onChange={e => setInputStakPaco(parseInt(e.target.value))} /> PACO
                                                        </div>
                                                        <button className="maxbtn" onClick={() => setInputStakPaco(stakAblePaco)}>{t("message.max")}</button>
                                                        <a onClick={() => stakToken(inputStakPaco)} className="btn yellow-btn lock">{t("message.fixed")}</a>
                                                    </div>
                                                </li>
                                                <li className="item">
                                                    <div className="name">
                                                        <p>{t("message.pledge_remove")}  <div className="num">{separatorNumber(stakingPaco)} PACO</div></p>
                                                    </div>
                                                    <div className="content">
                                                        <div className="input-control">
                                                            <input type="number" value={inputUnlockStakPaco} onChange={e => setInputUnlockStakPaco(parseInt(e.target.value))} /> PACO
                                                        </div>
                                                        <button className="maxbtn" onClick={() => setInputUnlockStakPaco(stakingPaco)}>{t("message.max")}</button>
                                                        <a onClick={() => unStakToken(inputUnlockStakPaco)} className="btn green-btn remove">{t("message.remove")}</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="interest">
                                            <div className="name mb-4">
                                                <p>PACO {t("message.profit")}</p><div className="num ">{separatorNumber(fixedFloor(claimBounsValue, 2))} USDT </div>
                                            </div>
                                            <div className="hidden name  mb-4">
                                                <p className="!w-full">已提取 PACO {t("message.profit")}</p><div className="num">{separatorNumber(fixedFloor(claimedBounsValue, 2))} USDT </div>
                                            </div>
                                            <div className="btn-box">
                                                <button className={`btn yellow-btn !w-auto min-w-[130px] mr-2 ${claimBounsValue == 0 ? " grayscale " : " "}`} disabled={claimBounsValue == 0} type="button" onClick={() => claimBouns()}>{t("message.extract_profit")}</button>

                                                <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/dai-bi-jing-ji/paco-dai-bi" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/paco-coin/token-information"} target="_block">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C"></path>
                                                    </svg>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    </div>







                </div>

            </div>
        </section>



    </div>
}

export default Buy