import { menuData, brandLink } from "../global/constants"
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";



import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";




const InviteBox = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation()

  const walletAddress = useSelector(state => state.walletAddress);



  const [closeTime, setCloseTime] = useState(0)

  function copySystemId() {
    var str = document.getElementById('systemid');
    window.getSelection().selectAllChildren(str);
    str.select();
    str.setSelectionRange(0, str.value.length);
    document.execCommand("Copy")
    window.getSelection().removeAllRanges()
    toast.success(t('toast.copySuccess'))
}

  useEffect(() => {

    //asd()
  }, [])

  return <div className="copy-code">
    <input type="text" className="form-control" readOnly={true} value={walletAddress == null ? t('message.needConnectWallet') : `${window.location.protocol}//${window.location.hostname}/game?q=${walletAddress}`} id="systemid" />
    <button type="submit" onClick={() => copySystemId()} className="btn yellow-btn">{t("message.copy")}</button>
  </div>

}
export default InviteBox