

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


///
import ReactECharts from 'echarts-for-react';

import payImg from "../../assets/images/pay.png"

import usdtImg from "../../assets/images/usdt.png"
import angleImg from "../../assets/images/angle.svg"
import errorImg from "../../assets/images/error.svg"
import timerImg from "../../assets/images/timer.svg"

import InviteBox from "../../compontents/InviteBox.js"
import NavGame from "../../compontents/NavGame.js"
import Role from "../../compontents/Role.js"
import Timer from '../../compontents/Timer'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3"

import { calMul, calDiv, needChainId, usdtAddress, pacoAddress, heroAddress, petAddress, petStakingAddress, heroStakingAddress, currentRound, fundingAddress, separatorNumber, pacoStakingAddress, calAdd } from "../../global/constants"

import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment'



const Bid = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const walletAddress = useSelector(state => state.walletAddress);


    const eChartsRef = React.useRef(null);

    const loading = useSelector(state => state.loading);
    const fundingAbi = require("../../assets/contract/funding.json");
    const pacoAbi = require("../../assets/contract/paco.json");
    const petStakingAbi = require("../../assets/contract/petStaking.json");
    const heroStakingAbi = require("../../assets/contract/heroStaking.json");


    let unit = 'ether'

    const usdtAbi = require("../../assets/contract/usdt.json");


    var web3 = new Web3(Web3.givenProvider);


    var FundingContract = new web3.eth.Contract(
        fundingAbi,
        fundingAddress
    );

    var USDTContract = new web3.eth.Contract(
        usdtAbi,
        usdtAddress
    );

    var PacoContract = new web3.eth.Contract(
        pacoAbi,
        pacoAddress
    );

    var PetStakingContract = new web3.eth.Contract(
        petStakingAbi,
        petStakingAddress
    );
    var HeroStakingContract = new web3.eth.Contract(
        heroStakingAbi,
        heroStakingAddress
    );


    const [showAd1, setShowAd1] = useState(false)
    const [showAd2, setShowAd2] = useState(false)
    const [showAd3, setShowAd3] = useState(false)

    const [fundingCurrentStatus, setFundingCurrentStatus] = useState(null)
    const fundingCurrentStatusCurrent = useRef(null)


    const [totalFunding, setTotalFunding] = useState(0)

    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)

    const [inputValue, setInputValue] = useState("")

    const payList = useRef([])

    const [ownUSDT, setOwnUSDT] = useState(0)

    const [refund70, setRefund70] = useState(0)
    const [refund100, setRefund100] = useState(0)

    const [fundingRecord, setFundingRecord] = useState([])
    const [ownTotalFunding, setOwnTotalFunding] = useState(0)
    const [ownTotalGain, setOwnTotalGain] = useState(0)

    const [claimed70, setClaimed70] = useState(false)
    const [claimed100, setClaimed100] = useState(false)

    const isFirst = useRef(false)
    const isFirstFail = useRef(false)
    const isFirstListen = useRef(false)



    const [bidTimer, setBidTimer] = useState(0)

    const [poolList, setPoolList] = useState([])

    const poolListRef = useRef([])


    const timeCount = useRef(0)
    useEffect(() => {

        setTimeout(() => timeCount.current += 1, 1000)
        endTime - Math.floor(Date.now() / 1000) < 0 ? setBidTimer((i) => i) : setBidTimer(endTime - Math.floor(Date.now() / 1000))

        //console.log(endTime - Math.floor(Date.now() / 1000) < 0)
        //console.log(endTime)
        //console.log(Math.floor(Date.now() / 1000))
        //console.log(!isFirstFail.current)

        if (timeCount.current < 3) {
            return
        }

        if (fundingCurrentStatus == null) {
            console.log('omg1', timeCount.current)
            //getFundingInfo()
        }
        else if (!isFirstFail.current && endTime != 0 && endTime - Math.floor(Date.now() / 1000) < 0) {

            console.log('omg2')
            //if (fundingCurrentStatus != null && fundingCurrentStatus[1]) {

            //isFirstFail.current = true
            getFundingInfo()
            //setShowAd1(true)
            //}
        }
    }, [timeCount.current])


    let btn = document.querySelector('.game-btn');
    let menu = document.querySelector('.game-nav');
    let bid_modal = document.querySelector('.bid-modal');
    let bid_check_modal = document.querySelector('.bid-check-modal');
    let amount_modal = document.querySelector('.amount-modal');
    let overrun_modal = document.querySelector('.overrun-modal');
    let full_modal = document.querySelector('.full-modal');
    let staked_modal = document.querySelector('.staked-modal');
    let full_staked_modal = document.querySelector('.full-staked-modal');
    let full_cancel = document.querySelector('.full-staked-modal .cancel'); //100%出金取消
    let full_confirm_btn = document.querySelector('.full-staked-modal .confirm');//100%出金確認
    let obliged_staked_modal = document.querySelector('.obliged-staked-modal');
    let obliged_cancel = document.querySelector('.obliged-staked-modal .cancel'); //強制出金取消
    let obliged_confirm_btn = document.querySelector('.obliged-staked-modal .confirm');//強制出金確認
    let staked_btn = document.querySelector('.staked-btn');
    let pay_content = document.querySelector('.pay-content');
    let withdrawable_modal = document.querySelector('.withdrawable-modal');//可出金
    let withdrawable_confirm_btn = document.querySelector('.withdrawable-modal .confirm');//可出金確認

    //btn.onClick =function(){
    //    menu.classList.toggle('active');
    //}
    // 提交當前重酬金額
    function bid() {
        bid_modal.classList.toggle('check');
    }
    function bid_check() {
        bid_check_modal.classList.toggle('check');
        if (bid_check_modal.classList.contains("check")) {
            bid_modal.classList.toggle('check');
        };
    }
    function amount() {
        amount_modal.classList.toggle('check');
    }
    function overrun() {
        overrun_modal.classList.toggle('check');
    }
    function full() {
        full_modal.classList.toggle('check');
    }
    // 出金
    function staked() {
        staked_modal.classList.toggle('check');
    }

    //100% 出金
    function full_staked() {
        full_staked_modal.classList.toggle('check');
        if (full_staked_modal.classList.contains("check")) {
            staked_modal.classList.remove('check');
        };
        full_cancel.onClick = function () {
            full_staked_modal.classList.remove('check');
            staked_modal.classList.toggle('check');
        };
        full_confirm_btn.onClick = function () {
            full_staked_modal.classList.remove('check');
            staked_btn.innerHTML = "等待中";

            if (staked_btn.innerHTML = "等待中") {
                staked_btn.classList.add('disabled');
            };

        };

    }
    //強制 出金
    function obliged_staked() {
        obliged_staked_modal.classList.toggle('check');
        if (obliged_staked_modal.classList.contains("check")) {
            staked_modal.classList.remove('check');
        };
        obliged_cancel.onClick = function () {
            obliged_staked_modal.classList.remove('check');
            staked_modal.classList.toggle('check');
        };
        obliged_confirm_btn.onClick = function () {
            obliged_staked_modal.classList.remove('check');
            pay_content.classList.toggle('check');
        }

    }
    //可出金
    function withdrawable() {
        withdrawable_modal.classList.toggle('check');
        withdrawable_confirm_btn.onClick = function () {
            withdrawable_modal.classList.remove('check');
            pay_content.classList.toggle('check');
        }
    }





    const optionEmpty = {
        backgroundColor: '#000000',
        legend: {
            top: '0',
            left: 'center',
            color: '#ffffff',
            fontSize: '30',
            fontWeight: 'bold'
        },
        title: {
            text: t('message.progress_percent'),
            left: 'center',
            top: 'center',
            fontSize: '30',

        },

        series: [{
            type: 'pie',
            color: [
                '#FFC943',
                '#989898',
            ],
            radius: ['55%', '70%'],
            label: {
                show: false,
                color: '#ffffff',
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: '21',
                    fontWeight: 'bold'
                }
            },
            data: [
                { value: 0, name: `0%${t('message.progress_done')}` },
                { value: 100, name: `100%${t('message.progress_undone')}` }
            ]
        }]
    }



    const [option, setOption] = useState({
        backgroundColor: '#000000',
        legend: {
            top: '0',
            left: 'center',
            color: '#ffffff',
            fontSize: '30',
            fontWeight: 'bold'
        },
        title: {
            text: t('message.progress_percent'),
            left: 'center',
            top: 'center',
            fontSize: '30',

        },

        series: [{
            type: 'pie',
            color: [
                '#FFC943',
                '#989898',
            ],
            radius: ['55%', '70%'],
            label: {
                show: false,
                color: '#ffffff',
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: '21',
                    fontWeight: 'bold'
                }
            },
            data: [
                { value: 0, name: `0%${t('message.progress_done')}` },
                { value: 100, name: `100%${t('message.progress_undone')}` }
            ]
        }]
    });


    async function inputFunding() {


        if (loading == true) {
            return
        }



        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }



            let isAdmin = await FundingContract.methods.admin(data[0]).call()

            if ((inputValue == null || inputValue < 100) && !isAdmin) {

                toast.error(t("toast.bid rule1"));
                return
            }

            else if ((inputValue == null || inputValue % 100 != 0) && !isAdmin) {

                toast.error(t("toast.bid rule2"));
                return
            }

            //let stakedNFT = await heroStakingAddress.methods.getStacked(fundingAddress, web3.utils.toWei(String(10000000), unit)).send({ from: data[0], })
            // if (!stakedNFT  ) {
            //     toast.error(t("toast.bid rule2"));
            //     return
            // }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });

                let stakedPet = await PetStakingContract.methods.getPacoMountStaked(data[0]).call()
                let stakedHero = await HeroStakingContract.methods.getCryptoPacoStaked(data[0]).call()
                if ((stakedPet.length + stakedHero.length) == 0) {
                    bid()
                    setShowAd2(true)
                    return
                }




                let totalPrice = inputValue
                let balanceOfWei = parseFloat(web3.utils.fromWei(await USDTContract.methods.balanceOf(data[0]).call(), unit))


                console.log(balanceOfWei)
                console.log(totalPrice)
                if (balanceOfWei >= totalPrice) {
                    let allowanceWei = parseFloat(web3.utils.fromWei(await USDTContract.methods.allowance(data[0], fundingAddress).call(), unit))
                    console.log(allowanceWei)
                    // console.log(allowanceWei - totalPrice )
                    if (allowanceWei < totalPrice) {
                        //await USDTContract.methods.approve(fundingAddress, web3.utils.toWei(String(totalPrice), unit)).send({ from: data[0], })
                        await USDTContract.methods.approve(fundingAddress, web3.utils.toWei(String(10000000), unit)).send({ from: data[0], gasPrice: await web3.eth.getGasPrice() })
                    }

                }
                else {
                    return toast.error(t("toast.Not enough USDT"));
                }
                console.log(
                    await FundingContract.methods.addFunding(web3.utils.toWei(String(totalPrice), unit)).send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                        // value: web3.utils.toWei(
                        //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                        //   "wei"
                        // ),
                    })
                );
                setInputValue("")

                getFundingInfo()
                getOwnUSDT()


                bid_check()
                return toast.success(t("toast.bid submit success"));
                //return toast.success(t("toast.buy success"));



            } catch (error) {
                console.log(error);

                if (error.code == 4001) {
                    toast.warning(t("toast.bid submit cancel"));
                }
                else {
                    toast.error(t("toast.bid submit fail"));
                }
                bid()
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });


            }
        });
    }

    async function claimFunding70() {

        if (loading == true) {
            return
        }

        if (fundingCurrentStatus != null && !fundingCurrentStatus[1]) {
            toast.warning(t("toast.in bid Time"));
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        if (refund70 == 0) {
            toast.error(t("toast.withdraw0USDT"));
            return
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });

                let allowRefund = await FundingContract.methods.allowRefund().call()
                if (!allowRefund) {
                    toast.error(t("toast.return calculating"));
                    return
                }


                //let checkRefundProfit = await FundingContract.methods.checkRefundProfit(data[0]).call()
                //console.log(checkRefundProfit)
                //let tempRefund70 = calAdd(calAdd( Number(web3.utils.fromWei(checkRefundProfit[0], unit)) , Number(web3.utils.fromWei(checkRefundProfit[1], unit))) , Number(web3.utils.fromWei(checkRefundProfit[2], unit)))
                //setRefund70(checkRefundProfit)
                //console.log(checkRefundProfit)
                console.log(
                    await FundingContract.methods.getProfit().send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                        // value: web3.utils.toWei(
                        //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                        //   "wei"
                        // ),
                    })
                );
                getFundingInfo()
                getOwnUSDT()
                //bid_check()
                toast.success(t("toast.withdraw success"));

                dispatch({
                    type: "UPDATE_CLAIMDIALOG",
                    payload: { claimDialog: { currency: "USDT", value: refund70 } }
                });

            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.withdraw cancel"));
                }
                else {
                    toast.error(t("toast.withdraw error"));
                }
                //bid()
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }

    async function claimFunding100() {

        if (loading == true) {
            return
        }

        if (fundingCurrentStatus != null && !fundingCurrentStatus[1]) {
            toast.warning(t("toast.in bid Time"));
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        if (refund100 == 0) {
            toast.error(t("toast.withdraw0USDT"));
            return
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                toast.error(t('toast.connect wallet'));
                return;
            }

            try {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: true }
                });
                let allowRefund = await FundingContract.methods.allowRefund().call()
                if (!allowRefund) {
                    toast.error(t("toast.return calculating"));
                    return
                }


                console.log(
                    await FundingContract.methods.getCurrentRefund().send({
                        from: data[0],
                        gasPrice: await web3.eth.getGasPrice()
                        // value: web3.utils.toWei(
                        //   String((this.selCount - 1) * boxConfigInfos.boxPrice),
                        //   "wei"
                        // ),
                    })
                );
                getFundingInfo()
                getOwnUSDT()
                //bid_check()
                toast.success(t("toast.withdraw success"));

                dispatch({
                    type: "UPDATE_CLAIMDIALOG",
                    payload: { claimDialog: { currency: "USDT", value: refund100 } }
                });

            } catch (error) {
                console.log(error);
                if (error.code == 4001) {
                    toast.warning(t("toast.withdraw cancel"));
                }
                else {
                    toast.error(t("toast.withdraw error"));
                }
                //bid()
            } finally {
                dispatch({
                    type: "UPDATE_LOADING",
                    payload: { loading: false }
                });
            }
        });
    }



    async function listenContractEvent() {
        //console.log('check1',fundingCurrentStatus)

        if (fundingCurrentStatus != null) {
            let getPoolRecord = await FundingContract.methods.getPoolRecord(fundingCurrentStatus[3]).call()
            //console.log(getPoolRecord)



            //if (fundingCurrentStatus[4] != 0 && payList.current.length == 0) {
            if (!isFirstListen.current) {
                isFirstListen.current = true
                payList.current = getPoolRecord
                return
            }


            if (payList.current.length < getPoolRecord.length) {
                getFundingInfo()
            }

            console.log(payList.current.length == getPoolRecord.length)
            console.log(payList.current)
            console.log(getPoolRecord)

            for (let i = payList.current.length; i < getPoolRecord.length; i = i + 1) {
                toast.success(`${t('message.bid_title_1')} ${currentRound}-${parseInt(fundingCurrentStatus[3]) + 1} ${t('message.bid_title_2')} ${getPoolRecord[i][0].substring(0, 4)}...${getPoolRecord[i][0].substring(getPoolRecord[i][0].length - 4, getPoolRecord[i][0].length)}${t('message.bided')} ${web3.utils.fromWei(getPoolRecord[i][1], unit)} USDT`)
            }


            payList.current = getPoolRecord

        }



        //var block = web3.eth.getBlock('latest')  27727786
        // let block = await web3.eth.getBlock("latest")
        // //console.log(block)


        // console.log(block.transactions)
        // for (let i = 0; i < ( block.transactions.length == 0 ? block.transactions.length : block.transactions.length - 1); i = i + 1) {
        //     console.log(i)
        //     //console.log(block.transactions[i])
        //     let transaction = await web3.eth.getTransaction(block.transactions[i])
        //     console.log(transaction)
        //     if (transaction.to == fundingAddress) {
        //         alert('Add 500 U')
        //     }
        // }


        // console.log('check2')


        return



        // listener
        FundingContract.events
            .allEvents(
                {
                    filter: {}, // Using an array means OR: e.g. 20 or 23
                    fromBlock: 0,
                },
                function (error, event) {
                    console.log(event);
                }
            )
            .on("AddFunding", function (subscriptionId) {
                console.log(subscriptionId);
            })
            .on("connected", function (subscriptionId) {
                console.log(subscriptionId);
            })
            .on("data", function (event) {
                console.log(event); // same results as the optional callback above
            })
            .on("changed", function (event) {
                console.log(event);
                // remove event from local database
            })
            .on("error", function (error, receipt) {
                // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                console.log(error);
                console.log(receipt);
            });

        // FundingContract.getPastEvents(
        //     "AddFunding",
        //     {
        //         filter: {}, // Using an array means OR: e.g. 20 or 23
        //         fromBlock: 0,
        //         toBlock: "latest",
        //     },
        //     function (error, events) {
        //         console.log(events);
        //         //this.soldNFT = events.length;
        //     }
        // ).then(function (events) {
        //     console.log(events); // same results as the optional callback above
        // });
    }

    function showPhase(selIndex) {


        let temp = [10000, 13000, 16900, 21970, 28561, 37129, 48268, 62748, 81573, 106044,]


        if (selIndex >= 5) {   // 11-20
            temp = temp.concat([137858, 179216, 232980, 302875, 393737, 511858, 665416, 865041, 1124554, 1461920])
        }
        if (selIndex >= 15) {  // 21-30
            temp = temp.concat([1900496, 2470645, 3211838, 4175390, 5428007, 7056410, 9173333, 11925332, 15502932, 20153812])
        }
        if (selIndex >= 25) {  // 31-40
            temp = temp.concat([26199956, 34059943, 44277926, 57561304, 74829695, 97278604, 126462185, 164400841, 213721093, 277837421])
        }
        if (selIndex >= 35) {  // 41-50
            temp = temp.concat([361188648, 469545242, 610408815, 793531459, 1031590897, 1341068167, 1743388617, 2266405202, 2946326763, 3830224792])
        }
        if (selIndex >= 45) {  // 51-60
            temp = temp.concat([4979292229, 6473079898, 8415003868, 10939505028, 14221356537, 18487763498, 24034092548, 31244320312, 40617616406, 52802901328])
        }
        if (selIndex >= 55) {  // 61-70
            temp = temp.concat([68643771727, 89236903245, 116007974219, 150810366485, 196053476430, 254869519359, 331330375167, 430729487718, 559948334033, 727932834244])
        }
        if (selIndex >= 65) {  // 71-80
            temp = temp.concat([946312684517, 1230206489872, 1599268436834, 2079048967884, 2702763658249, 3513592755724, 4567670582442, 5937971757174, 7719363284327, 10035172269625])
        }

        poolListRef.current = temp
        setPoolList(temp)
        console.log(temp)
    }

    useEffect(() => {
        if (fundingCurrentStatusCurrent.current == null) {
            return
        }
        if (parseInt(fundingCurrentStatusCurrent.current[3]) < poolList.length && fundingCurrentStatusCurrent.current != null) {
            scrollPhase(fundingCurrentStatusCurrent.current[3])
        }
    }, [poolList])

    function scrollPhase(selIndex) {
        phaseScroll = document.getElementById('phase-scroll');
        let phaseTr = document.getElementById(`phase-td${Number(selIndex) + 1}`);

        phaseScroll.scrollLeft = phaseTr.offsetLeft
    }




    async function getFundingInfo() {

        if (fundingAddress === "") {
            let currentStatus = [
                false,
                false,
                false,
                "0",
                "0",
                "0"
            ]
            showPhase(0)
            setFundingCurrentStatus(currentStatus)
            return
        }


        web3.eth.getAccounts().then(async (data) => {
            //console.log(data);

            try {



                //let USDT = (web3.utils.fromWei(await USDTContract.methods.balanceOf(data[0]).call(), unit))
                //let exchange = web3.utils.fromWei(await PacoIDOContract.methods.price().call(), unit)
                //let Paco = Math.floor(web3.utils.fromWei(await PacoContract.methods.balanceOf(data[0]).call(), unit),2)

                //let stakingPaco = Math.floor(web3.utils.fromWei(await PacoStakingContract.methods.staker(data[0]).call(), unit))
                //let totalStakingPaco = Math.floor(web3.utils.fromWei(await PacoStakingContract.methods.totalStaked().call(), unit))
                //let stakingPaco = parseFloat(web3.utils.fromWei(await PacoStakingContract.methods.staker(data[0]).call(), unit))

                //console.log(stakingPaco)




                let currentStatus = await FundingContract.methods.currentStatus().call()

                fundingCurrentStatusCurrent.current = currentStatus
                console.log(currentStatus)
                let tempStartTime = Number(await FundingContract.methods.start().call())
                let tempEndTime = Number(await FundingContract.methods.end().call())

                if (currentStatus[1] && !isFirstFail.current) {
                    isFirstFail.current = true
                    setShowAd1(true)
                }

                if (isFirst.current == false) {
                    setStartTime(tempStartTime)
                    setEndTime(tempEndTime)
                    //showPhase(25)
                    showPhase(currentStatus[3])
                    //scrollPhase(currentStatus[3])
                    isFirst.current = true

                }


                //tempStartTime = 1677828621
                //tempEndTime = 1677828681

                console.log(currentStatus)
                console.log(tempStartTime)






                //setBidTimer((tempEndTime - Math.floor(Date.now() / 1000)) > 0 ? (tempEndTime - Math.floor(Date.now() / 1000)) : 0)



                let tempOption = option



                // if (Math.floor(Date.now() / 1000) < tempStartTime) {

                //     tempOption.series[0].data[0].value = 0
                //     tempOption.series[0].data[0].name = `0%已完成`

                //     tempOption.series[0].data[1].value = 100
                //     tempOption.series[0].data[1].name = `100%未完成`

                // }
                // else {

                let done = calMul(calDiv((web3.utils.fromWei(currentStatus[4], unit)), poolListRef.current[parseInt(currentStatus[3])]), 100).toFixed(0)
                let notDone = calMul((1 - calDiv((web3.utils.fromWei(currentStatus[4], unit)), poolListRef.current[parseInt(currentStatus[3])])), 100).toFixed(0)
                if (currentStatus[2]) {
                    done = 100
                    notDone = 0
                }
                else if (done == 100 && (!currentStatus[2])) {
                    done = 99
                    notDone = 1
                }
                tempOption.series[0].data[0].value = done
                tempOption.series[0].data[0].name = `${done}%${t('message.progress_done')}`

                tempOption.series[0].data[1].value = notDone
                tempOption.series[0].data[1].name = `${notDone}%${t('message.progress_undone')}`

                //}


                let getPoolAmount = await FundingContract.methods.getPoolAmount(currentStatus[3], data[0]).call()
                console.log(getPoolAmount)


                let checkProfit = await FundingContract.methods.checkProfit(data[0]).call()
                console.log(checkProfit[0])



                let tempRefund70 = calAdd((web3.utils.fromWei(checkProfit[0], unit)), (web3.utils.fromWei(checkProfit[1], unit)))
                setRefund70(tempRefund70)


                let tempClaimed70 = await FundingContract.methods.profitRefund(data[0]).call()
                if (tempClaimed70) {
                    setClaimed70(true)
                }

                let tempClaimed100 = await FundingContract.methods.currentRefund(data[0]).call()
                if (tempClaimed100) {
                    setClaimed100(true)
                }


                let tempRefund100 = await FundingContract.methods.checkCurrentRefund(data[0]).call()
                setRefund100((web3.utils.fromWei(tempRefund100, unit)))
                //setRefund100((web3.utils.fromWei( getPoolAmount, unit)))



                setOption(tempOption)
                if (eChartsRef && eChartsRef.current) {
                    eChartsRef.current?.getEchartsInstance().setOption(tempOption);
                }


                console.log(0 < poolListRef.current.length && 0 < parseInt(currentStatus[3]))
                //setFundingCurrentStatus(currentStatus)
                let temp = 0
                for (let i = 0; i < poolListRef.current.length && i < parseInt(currentStatus[3]); i = i + 1) {
                    temp = temp + poolListRef.current[i]
                    //console.log(poolListRef.current[i])
                }

                if ((Math.floor(Date.now() / 1000) > tempStartTime)) {
                    temp = temp + Number(web3.utils.fromWei(currentStatus[4], unit))
                }

                setTotalFunding(temp)



                setFundingCurrentStatus(currentStatus)

                let totalPrincipal = 0
                let tempRecord = []
                let tempOwnTotalFunding = 0
                let tempOwnTotalGain = 0




                console.log(parseInt(currentStatus[3]))
                for (let i = 0; i <= parseInt(currentStatus[3]); i = i + 1) {

                    let fundingPrice = await FundingContract.methods.getPoolAmount(String(i), data[0]).call()
                    //let expectProfit = await FundingContract.methods.checkBestProfit(data[0]).call()
                    //expectProfit = calAdd(web3.utils.fromWei(expectProfit[0], unit), web3.utils.fromWei(expectProfit[1], unit))
                    let expectProfit = await FundingContract.methods.checkPhaseProfit(data[0], i).call()
                    console.log(i, expectProfit)

                    expectProfit = web3.utils.fromWei(expectProfit, unit)

                    totalPrincipal = calAdd(totalPrincipal, parseInt(web3.utils.fromWei(fundingPrice, unit)))


                    if (fundingPrice != 0) {
                        tempOwnTotalFunding = calAdd(tempOwnTotalFunding, Number(web3.utils.fromWei(fundingPrice, unit)))
                        tempOwnTotalGain = calAdd(tempOwnTotalGain, Number(expectProfit))
                        tempRecord.push({ period: i + 1, currentPrincipal: web3.utils.fromWei(fundingPrice, unit), totalPrincipal: totalPrincipal, expectProfit: expectProfit })
                    }
                }
                setFundingRecord(tempRecord)



                setOwnTotalFunding(tempOwnTotalFunding)
                setOwnTotalGain(tempOwnTotalGain)








            } catch (error) {
                console.log(error);
                //} finally {
            }
        });
    }

    async function getOwnUSDT() {

        web3.eth.getAccounts().then(async (data) => {
            //console.log(data);

            try {

                //let USDT = (web3.utils.fromWei(await USDTContract.methods.balanceOf(data[0]).call(), unit))
                //let exchange = web3.utils.fromWei(await PacoIDOContract.methods.price().call(), unit)
                //let Paco = Math.floor(web3.utils.fromWei(await PacoContract.methods.balanceOf(data[0]).call(), unit),2)

                //let stakingPaco = Math.floor(web3.utils.fromWei(await PacoStakingContract.methods.staker(data[0]).call(), unit))
                //let totalStakingPaco = Math.floor(web3.utils.fromWei(await PacoStakingContract.methods.totalStaked().call(), unit))
                //let stakingPaco = parseFloat(web3.utils.fromWei(await PacoStakingContract.methods.staker(data[0]).call(), unit))

                //console.log(stakingPaco)


                let tempUSDT = (web3.utils.fromWei(await USDTContract.methods.balanceOf(data[0]).call(), unit))

                setOwnUSDT(tempUSDT)


            } catch (error) {
                console.log(error);
            } finally {
            }
        });
    }
    const [checkBlockTime, setCheckBlockTime] = useState(0)

    useEffect(() => {
        //if (Date.now() > endTime){
        //    getFundingInfo()
        //}

        listenContractEvent()
        setTimeout(() => setCheckBlockTime((i) => i - 1), 1000)
    }, [checkBlockTime])


    let phaseScroll = document.querySelector('#phase-scroll');


    async function checkStakedNFT() {

        let stakedPet = await PetStakingContract.methods.getPacoMountStaked(walletAddress).call()
        console.log(stakedPet)
        let stakedHero = await HeroStakingContract.methods.getCryptoPacoStaked(walletAddress).call()
        console.log(stakedHero)
        if ((stakedPet.length + stakedHero.length) == 0) {
            setShowAd2(true)
        }

    }


    useEffect(() => {
        if (walletAddress !== undefined) {
            getFundingInfo()
            getOwnUSDT()
            checkStakedNFT()
        }

    }, [walletAddress]);

    useEffect(() => {



        btn = document.querySelector('.game-btn');
        menu = document.querySelector('.game-nav');
        bid_modal = document.querySelector('.bid-modal');
        bid_check_modal = document.querySelector('.bid-check-modal');
        amount_modal = document.querySelector('.amount-modal');
        overrun_modal = document.querySelector('.overrun-modal');
        full_modal = document.querySelector('.full-modal');
        staked_modal = document.querySelector('.staked-modal');
        full_staked_modal = document.querySelector('.full-staked-modal');
        full_cancel = document.querySelector('.full-staked-modal .cancel'); //100%出金取消
        full_confirm_btn = document.querySelector('.full-staked-modal .confirm');//100%出金確認
        obliged_staked_modal = document.querySelector('.obliged-staked-modal');
        obliged_cancel = document.querySelector('.obliged-staked-modal .cancel'); //強制出金取消
        obliged_confirm_btn = document.querySelector('.obliged-staked-modal .confirm');//強制出金確認
        staked_btn = document.querySelector('.staked-btn');
        pay_content = document.querySelector('.pay-content');
        withdrawable_modal = document.querySelector('.withdrawable-modal');//可出金
        withdrawable_confirm_btn = document.querySelector('.withdrawable-modal .confirm');//可出金確認





    }, []);


    return <div id="app">

        <div className="AD">
            {
                showAd1 ?
                    <div className="item AD-1 ">
                        <div className="mask"></div>
                        <div className="inner relative bid-items">
                            <div className="inner !bg-transparent">
                                <div className="closeAD " onClick={() => setShowAd1(false)}>
                                    <svg width="40" height="40" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                                    </svg>
                                </div>
                                <div className="popContent max-h-[400px] sm:max-h-[600px] overflow-y-auto py-0 ">
                                    <div className="">
                                        <section className="  px-8 py-14 ">
                                            <div className="game-content">
                                                <div className="container">
                                                    <div>
                                                        <p className="name text-sm sm:text-xl text-[#FECE3F] text-center">{t('message.withdrawTitle')}<br />{t('message.withdrawTitle2')}</p>
                                                    </div>

                                                    <div className=" bg-black grid grid-cols-1 lg:grid-cols-2 w-full gap-0 lg:gap-10 scale-100">
                                                        <div className="bid-items "  >
                                                            <div className="inner  !bg-transparent relative z-10 dividend pt-1 sm:pt-6 pb-2 sm:pb-12">
                                                                <div className="item " style={{ "alignContent": "flex-start", height: "100%" }}>
                                                                    <div className="dividend-content">

                                                                        <label className="name mb-2 text-[#FECE3F]">{(`${t('message.bid_title_1')} ${currentRound}-${fundingCurrentStatus != null ? parseInt(fundingCurrentStatus[3]) + 1 : ""} ${t(`message.withdraw70`)}`)}：<br /><p className="hidden mt-0.5 text-sm text-[#E0BD7C]">{t("（若在投入後當期眾籌失敗，將100%退回）")}</p></label><br />

                                                                        <div className="form_box">
                                                                            <div className="input-num " >{fundingCurrentStatus != null ? fundingCurrentStatus[1] == true ? separatorNumber(parseInt(refund70)) : "" : t('message.counting')}</div>
                                                                            {/* refund70 */}
                                                                            <span className="dollor">USDT</span>
                                                                        </div>


                                                                        <button className={`btn yellow-btn !w-auto ${claimed70 ? " grayscale !cursor-default" : ""}`} type="button" disabled={claimed70} onClick={() => claimFunding70()}>{claimed70 ? t('message.claimed') : t("message.withdrawal")}</button>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bid-items z-[0]">
                                                            <div className="inner !bg-transparent relative z-10 dividend pt-1 sm:pt-6 pb-2 sm:pb-12">
                                                                <div className="item " style={{ "alignContent": "flex-start", height: "100%" }}>
                                                                    <div className="dividend-content ">


                                                                        <label className="name mb-2 text-[#FECE3F]">{t(`message.withdraw100`)}：<br /></label><br />

                                                                        <div className="form_box">
                                                                            <div className="input-num " >{fundingCurrentStatus != null ? fundingCurrentStatus[1] == true ? separatorNumber(parseInt(refund100)) : "" : t('message.counting')}</div>
                                                                            <span className="dollor">USDT</span>
                                                                        </div>

                                                                        <button className={`btn yellow-btn !w-auto ${claimed100 ? " grayscale !cursor-default" : " "}`} type="button" disabled={claimed100} onClick={() => claimFunding100()}>{claimed100 ? t('message.claimed') : t("message.withdrawal")}</button>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> : ""
            }
            {
                showAd2 ?
                    <div className="item AD-1 ">
                        <div className="mask"></div>
                        <div className="inner relative bid-items">
                            <div className="inner !bg-transparent">
                                <div className="closeAD " onClick={() => setShowAd2(false)}>
                                    <svg width="40" height="40" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                                    </svg>
                                </div>
                                <div className="popContent max-h-[400px] sm:max-h-[600px] overflow-y-auto py-0 ">
                                    <div className="">
                                        <section className="  px-8 py-14 ">
                                            <div className="game-content">
                                                <div className="container">



                                                    <div>
                                                        <p className="name text-sm sm:text-xl text-[#FECE3F] text-center">{t("message.showAd1Title")}</p>
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-5 pt-6">
                                                        <Link to="/game" className="btn black-btn">{t("message.showAd1Option1")}</Link>
                                                        <Link to="/mynft" onClick={() => setShowAd2(false)} className="btn yellow-btn">{t("message.showAd1Option2")}</Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> : ""
            }
        </div>


        <div className="bid-modal  modal-box">
            <div className="mask" onClick={() => bid()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => bid()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_1_text_1")}<span className="note">【{t("message.bid_modal_1_text_2")}】</span></p>

                    <div className="btn-box">
                        <a className="btn yellow-btn" onClick={() => inputFunding()}>{t("message.confirm")}</a>
                        <a className="btn black-btn" onClick={() => bid()}>{t("message.cancel")}</a>
                    </div>
                </div>

            </div>
        </div>
        <div className="bid-check-modal  modal-box">
            <div className="mask" onClick={() => bid_check()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => bid_check()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_2_text")}</p>

                    <div className="btn-box">
                        <a className="btn yellow-btn" onClick={() => bid_check()}>{t("message.confirm")}</a>
                    </div>
                </div>

            </div>
        </div>

        <div className="bid_partclaim_modal  modal-box">
            <div className="mask" onClick={() => bid_check()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => bid_check()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_2_text")}</p>

                    <div className="btn-box">
                        <a className="btn yellow-btn" onClick={() => bid_check()}>{t("message.confirm")}</a>
                    </div>
                </div>

            </div>
        </div>

        <div className="bid_allclaim_modal  modal-box">
            <div className="mask" onClick={() => bid_check()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => bid_check()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_2_text")}</p>

                    <div className="btn-box">
                        <a className="btn yellow-btn" onClick={() => bid_check()}>{t("message.confirm")}</a>
                    </div>
                </div>

            </div>
        </div>

        {/* <!-- 提交金額不得少於100USDT --> */}
        <div className="amount-modal  modal-box">
            <div className="mask" onClick={() => amount()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => amount()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_3_text")}100 USDT</p>
                    <div className="btn-box">
                        <a onClick={() => amount()} className="btn yellow-btn">{t("message.confirm")}</a>
                    </div>
                </div>

            </div>
        </div>
        {/* <!-- 僅持有PacoMountsNFT者最多只能1000USDT --> */}
        <div className="overrun-modal  modal-box">
            <div className="mask" onClick={() => overrun()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => overrun()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_4_text_1")}<a href="/game.php">【 {t("message.bid_modal_4_text_2")} 】</a> <br />{t("message.bid_modal_4_text_3")}  </p>
                    <div className="btn-box">
                        <a onClick={() => overrun()} className="btn yellow-btn">{t("message.confirm")}</a>
                    </div>
                </div>

            </div>
        </div>
        {/* <!-- 當期眾籌金額已完成--> */}
        <div className="full-modal  modal-box">
            <div className="mask" onClick={() => full()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => full()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_5_text_1")} </p>
                    <div className="btn-box">
                        <a onClick={() => full()} className="btn yellow-btn">{t("message.confirm")}</a>
                    </div>
                </div>

            </div>
        </div>

        {/* <!--出金 --> */}
        <div className="staked-modal  modal-box">
            <div className="mask" onClick={() => staked()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => staked()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.bid_modal_6_text_1")}<span className="note">【{t("message.bid_modal_6_text_2")}】</span></p>
                    <div className="btn-box">
                        <a onClick={() => full_staked()} className="btn yellow-btn">{t("message.full_staked")}</a>
                        <a onClick={() => obliged_staked()} className="btn yellow-btn">{t("message.obliged_staked")}</a>
                    </div>
                </div>

            </div>
        </div>
        {/* <!--100%出金 --> */}
        <div className="full-staked-modal  modal-box">
            <div className="mask" onClick={() => full_staked()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => full_staked()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.full_staked_text")}</p>
                    <p className="text-sm">{t('message.bid_modal_6_text_2')}</p>
                    <div className="btn-box">
                        <a onClick={() => full_staked()} className="btn yellow-btn confirm">{t("message.confirm")}</a>
                        <a className="btn yellow-btn cancel">{t("message.cancel")}</a>
                    </div>
                </div>

            </div>
        </div>
        {/* <!--強制出金 --> */}
        <div className="obliged-staked-modal  modal-box">
            <div className="mask" onClick={() => obliged_staked()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => obliged_staked()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.obliged_staked_text_1")}<span><i>{t("message.obliged_staked_text_2")}</i> 1,000 USDT</span></p>
                    <p className="text-sm">{t('message.bid_modal_6_text_2')}</p>
                    <div className="btn-box">
                        <a onClick={() => obliged_staked()} className="btn yellow-btn confirm">{t("message.confirm")}</a>
                        <a className="btn yellow-btn cancel">{t("message.cancel")}</a>
                    </div>
                </div>

            </div>
        </div>
        {/* <!-- 可出金--> */}
        <div className="withdrawable-modal  modal-box">
            <div className="mask" onClick={() => withdrawable()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => withdrawable()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.obliged_staked_text_2")}  1,000 USDT<span>【{t("message.bid_modal_6_text_2")}】</span></p>
                    <div className="btn-box">
                        <a className="btn yellow-btn confirm">{t("message.confirm")}</a>
                        <a onClick={() => withdrawable()} className="btn yellow-btn cancel">{t("message.cancel")}</a>
                    </div>
                </div>

            </div>
        </div>

        <div className="pay-content"><img src={payImg} alt="" /></div>


        <section className="game-box">
            <div className="container">
                <NavGame />
            </div>

            <div className="game-content">
                <div className="container">
                    {/* <!-- 說明 --> */}
                    {/* <div style='position:relative; width:100%;margin:auto'>
                    <ul style="background-color:rgb(157 157 157);font-size:15px;padding:5px; position: absolute; left:0; z-index:15;width:48%">
                        <li>1.提交金額不得少於100USDT<a onClick={()=>amount()} style="color:#f00">「顯示」</a></li>
                        <li>2.僅持有PacoMountsNFT者最多只能1000USDT<a onClick={()=>overrun()} style="color:#f00">「顯示」</a></li>
                        <li>3.當期眾籌金額已完成時<a onClick={()=>full()} style="color:#f00">「顯示」</a></li>
                        <li>4.目標10000，已眾籌8000，本次投3000<a onClick={()=>full()} style="color:#f00">「顯示」</a></li>
                        <li>5.葉先生提到：<span style="color:#f00">用戶數、交易數先隱藏</span>，日後會使用到</li>
                    </ul> 
                    <ul style="background-color:rgb(157 157 157);font-size:15px;padding:5px;right:0; position: absolute; z-index:15;width:48%">
                        進度表下方顯示近期的眾籌資訊
                        <li>1.此處為固定顯示6期</li>
                        <li>2.若目前為第1輪第6關，期數將顯示為「1-2, 1-3, 1-4, 1-5, 1-6, 1-7」。</li>
                        <li>3.期數的「-」前表「輪」、「-」後表「關」，如果失敗，將進入下一「輪」，並從第1「關」開始，眾籌金額也從10000元開始重新計算</li>
                        <li>4.<img src="./frontEndPackage/images/timer.svg" alt="">沙漏表示進行中，<img src="./frontEndPackage/images/angle.svg" alt="">眾籌成功<img src="./frontEndPackage/images/error.svg" alt="">眾籌失敗</li>
                        
                    </ul>
                </div> */}


                    <div className="game-content-head">
                        <Role></Role>


                        <h2 className="title title-flex ">{t("message.features_title_1")} <a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/bian-wan-bian-zuan-sheng-dian-qi-chong-nft/sheng-dian-zhong-chou-wan-fa" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/play-2-earn-cryptopaconft/gate-of-sacredness-crowdfunding"} target="_block"><svg width="20" height="20" viewBox="0 0 20 20" fill="#2CBAA9" ><path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#E1BD7C"></path></svg></a></h2>

                    </div>
                    <div className="bid-head">
                        <h3 className="title">{t("message.bid_title_1")} <span>{currentRound}-{fundingCurrentStatus != null ? parseInt(fundingCurrentStatus[3]) + 1 : ""}</span> {t("message.bid_title_2")}</h3>
                        <div className="inner">

                            <div className="text-box">

                                <div className="bid-text">
                                    <div className="text">
                                        <div className="item"><p className="name">{t("message.start")}：</p><p className="txt">{startTime != 0 ? moment(parseInt(startTime) * 1000).format("YYYY-M-D HH:mm:ss") : "0000-0-0 00:00:00"}</p></div>
                                        <div className="item"><p className="name">{t("message.end")}：</p><p className="txt">{endTime != 0 ? moment(parseInt(endTime) * 1000).format("YYYY-M-D HH:mm:ss") : "0000-0-0 00:00:00"}</p></div>
                                        <div className="item"><p className="name">{t("message.reciprocal")}：</p><p className="txt">{bidTimer == 0 || (fundingCurrentStatus != null && (fundingCurrentStatus[1] || fundingCurrentStatus[2])) || (Math.floor(Date.now() / 1000) < startTime) ? "00:00:00:00" : `${Math.floor(bidTimer / 86400) < 10 ? `0${Math.floor(bidTimer / 86400)}` : Math.floor(bidTimer / 86400)}:${Math.floor(bidTimer % 86400 / 3600) < 10 ? `0${Math.floor(bidTimer % 86400 / 3600)}` : Math.floor(bidTimer % 86400 / 3600)}:${Math.floor(bidTimer % 3600 / 60) < 10 ? `0${Math.floor(bidTimer % 3600 / 60)}` : Math.floor(bidTimer % 3600 / 60)}:${bidTimer % 60 < 10 ? `0${bidTimer % 60}` : bidTimer % 60}`}</p></div>
                                        {/* <div className="item"><p className="name">{t("message.bid_user_num")}：</p><p className="txt">{fundingCurrentStatus != null ? fundingCurrentStatus[5] : ""}</p></div>
                                        <div className="item"><p className="name">{t("message.bid_trade_num")}：</p><p className="txt">{fundingCurrentStatus != null ? fundingCurrentStatus[6] : ""}</p></div> */}
                                        <div className="item"><p className="name">{t("message.finish")}：</p><p className="txt"><span>{separatorNumber(fundingCurrentStatus != null ? (Math.floor(Date.now() / 1000) < startTime) ? 0 : parseInt((web3.utils.fromWei(fundingCurrentStatus[4], unit))) : "")}</span> / <span>{separatorNumber(fundingCurrentStatus != null ? poolListRef.current[parseInt(fundingCurrentStatus[3])] : "")}</span></p></div>
                                        <div className="input_dollar">
                                            <p className="name"><i><img src={usdtImg} alt="" /></i>USDT </p>
                                            <div className="input">
                                                <input type="number" className="input-num" value={inputValue} onChange={(e) => setInputValue(parseInt(e.target.value))} />
                                                <p className='note'>{t("message.own")} <span>{separatorNumber(parseInt(ownUSDT))}</span> USDT</p>
                                            </div>
                                            <button className="btn yellow-btn" onClick={() => bid()}>{t("message.submit")}</button>
                                        </div>

                                    </div>
                                    {/* <!-- 圓餅圖 --> */}
                                    <div className="pie_Chart_box" >

                                        <div className="pie_Chart" id="container">
                                            {

                                                Math.floor(Date.now() / 1000) < startTime ?
                                                    <ReactECharts option={optionEmpty} theme={'dark'} />
                                                    : <ReactECharts option={option} ref={eChartsRef} theme={'dark'} />
                                            }


                                        </div>
                                        <table className="table bid-table" >
                                            <thead>
                                                <tr>
                                                    <th>{t("message.period")}</th>
                                                    <th>{t("message.bid_title_3")}</th>
                                                </tr>
                                            </thead>
                                            <tbody id="phase-scroll">
                                                {
                                                    poolList.map((i, index) =>
                                                        <tr key={i}>
                                                            <td id={`phase-td${index + 1}`}><p className='num'>{currentRound}-{index + 1}</p></td>
                                                            <td>
                                                                <p className='price'>{separatorNumber(i)}</p>
                                                                {
                                                                    (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == index || (fundingCurrentStatus != null && fundingCurrentStatus[3] > index) ?
                                                                        <img className=" mx-auto w-[22px] h-[22px]" src={angleImg} alt="" />
                                                                        : (fundingCurrentStatus != null && fundingCurrentStatus[1]) && fundingCurrentStatus[3] == index ?
                                                                            <img className=" mx-auto w-[22px] h-[22px]" src={errorImg} alt="" /> :
                                                                            (fundingCurrentStatus != null && !fundingCurrentStatus[1]) && fundingCurrentStatus[3] == index ?
                                                                                <img className=" mx-auto w-[22px] h-[22px]" src={timerImg} alt="" /> : ""
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {/* <tr>
                                                    <td><p className='num'>1-1</p></td>
                                                    <td>
                                                        <p className='price'>10,000</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 0 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 0) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p className='num'>1-2</p></td>
                                                    <td>
                                                        <p className='price'>13,000</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 1 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 1) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p className='num'>1-3</p></td>
                                                    <td>
                                                        <p className='price'>16,900</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 2 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 2) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p className='num'>1-4</p></td>
                                                    <td>
                                                        <p className='price'>21,970</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 3 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 3) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p className='num'>1-5</p></td>
                                                    <td>
                                                        <p className='price'>28,561</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 4 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 4) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p className='num'>1-6</p></td>
                                                    <td>
                                                        <p className='price'>37,129</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 5 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 5) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p className='num'>1-7</p></td>
                                                    <td><p className='price'>48,268</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 6 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 6) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p className='num'>1-8</p></td>
                                                    <td><p className='price'>62,749</p>
                                                        {
                                                            (fundingCurrentStatus != null && fundingCurrentStatus[2]) && fundingCurrentStatus[3] == 7 || (fundingCurrentStatus != null && fundingCurrentStatus[3] > 7) ?
                                                                <img className=" mx-auto" src={angleImg} alt="" />
                                                                : ""
                                                        }
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        <div className="total">
                                            <div className="name">{t("message.bid_amount")}</div>
                                            <div className="price">$ {separatorNumber(parseInt(totalFunding))}</div>
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>
                        {
                            startTime > 0 && endTime > 0 ?
                                startTime - Math.floor(Date.now() / 1000) > 0 ?
                                    <Timer titleType={'1'} closeTime={(startTime)}></Timer>
                                    :
                                    <Timer titleType={'2'} closeTime={(fundingCurrentStatus != null && (fundingCurrentStatus[1] || fundingCurrentStatus[2])) ? 0 : (endTime)}></Timer>
                                : ""

                        }

                        <div className="px-4">
                            <InviteBox />
                        </div>


                    </div>
                    <div className="h-2">

                    </div>
                    {/* <div className="grid grid-cols-2 w-full gap-4 lg:gap-10">
                        <div className={`w-full bid-head ${fundingCurrentStatus != null && fundingCurrentStatus[3] % 2 == 0 ? "" : "brightness-[0.25]"}`}>
                            <div className="inner">

                                <div className="text-box text-center text-yellow-300 ">
                                    <p className="text-lg  mb-5">單數期累計金額</p>
                                    <p className="text-2xl font-bold">0</p>
                                </div>
                            </div>
                        </div>

                        <div className={`w-full bid-head ${fundingCurrentStatus != null && fundingCurrentStatus[3] % 2 == 1 ? "" : "brightness-[0.25]"}`}>
                            <div className="inner">

                                <div className="text-box text-center text-yellow-300 ">
                                    <p className="text-lg  mb-5">雙數期累計金額</p>
                                    <p className="text-2xl font-bold">0</p>
                                </div>
                            </div>
                        </div>

                    </div> */}
                    {/* <div className="bid-head text-white ">
                        <div className="inner">
                            <div className="text-box">
                                <div className="text-main mx-auto lg:w-[800px] text-center mb-8">
                                    <div className="text-2xl font-bold ">出金方式</div>


                                </div>

                                <div className="flex flex-col lg:flex-row gap-4 justify-center">
                                    <button className="w-full lg:w-40 btn yellow-btn" onClick={() => obliged_staked()}>{t("message.partClaim")}</button>
                                    <button className="w-full lg:w-40 btn yellow-btn" onClick={() => full_staked()}>{t("message.allClaim")}</button>

                                </div>
                            </div>

                        </div>
                    </div> */}

                    <div className="bid-items">
                        <div className="inner">
                            <table className=" bid-items-table">
                                <thead>
                                    <tr>
                                        <th className="!w-1/3">{t("message.initial_period")}</th>
                                        <th className="!w-1/3">{t("message.initial_principal")} (USDT)</th>
                                        {/* <th>累計投入本金</th> */}
                                        <th className="!w-1/3">{t("message.expected_profit")} (USDT)</th>
                                        {/* <th>{t("message.current_amount")}</th> */}
                                        {/* <th>{t("message.expected_profit")}</th> */}
                                        {/* <th>{t("message.operate")}</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fundingRecord.map(i => <tr key={i.period}>
                                            <td className="!w-1/3" >{currentRound}-{i.period}</td>
                                            <td className="!w-1/3">{separatorNumber(parseInt(i.currentPrincipal))}</td>
                                            {/* <td>{separatorNumber(i.totalPrincipal)}</td> */}
                                            <td className="!w-1/3">{separatorNumber(parseInt(i.expectProfit))}</td>
                                            {/* <td >1,000</td> */}
                                            {/* <td >100</td> */}
                                            {/* <td><button className="maxbtn staked-btn" onClick={() => staked()}>{t('message.withdrawable')}</button></td> */}
                                        </tr>)
                                    }


                                </tbody>
                            </table>

                            <div className="w-full  py-4 z-10  relative text-[#E1BD7C]">
                                <div className="grid grid-cols-3 w-full  text-left">
                                    <div className="px-2 lg:px-8">{t('message.total')}</div>
                                    <div className="px-2 lg:px-8"> <span className="font-bold">{separatorNumber(parseInt(ownTotalFunding))}</span></div>
                                    {/* 累計投入本金 累計預期獲利 */}
                                    <div className="px-2 lg:px-8"> <span className="font-bold">{separatorNumber(parseInt(ownTotalGain))}</span></div>


                                </div>
                            </div>
                        </div>

                    </div>

                    {
                        fundingCurrentStatus != null && fundingCurrentStatus[1] ?
                            <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-4 lg:gap-10">
                                <div className="bid-items">
                                    <div className="inner relative z-10 dividend pt-6 pb-12">
                                        <div className="item ">
                                            <div className="dividend-content ">


                                                <label className="name mb-2 text-[#FECE3F]">{`${t("message.bid_title_1")} ${currentRound}-${fundingCurrentStatus != null ? parseInt(fundingCurrentStatus[3]) + 1 : ""} ${t('message.withdraw70')}`}：<br /></label><br />

                                                <div className="form_box">
                                                    <div className="input-num " >{fundingCurrentStatus != null ? fundingCurrentStatus[1] == true ? separatorNumber(parseInt(refund70)) : "" : t('message.counting')}</div>
                                                    {/* refund70 */}
                                                    <span className="dollor">USDT</span>
                                                </div>


                                                <button className={`btn yellow-btn !w-auto ${claimed70 ? " grayscale !cursor-default" : ""}`} type="button" disabled={claimed70} onClick={() => claimFunding70()}>{claimed70 ? t('message.claimed') : t("message.withdrawal")}</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="bid-items">
                                    <div className="inner relative z-10 dividend pt-6 pb-12">
                                        <div className="item ">
                                            <div className="dividend-content ">


                                                <label className="name mb-2 text-[#FECE3F]">{t(`message.withdraw100`)}：<br />
                                                </label><br />

                                                <div className="form_box">
                                                    <div className="input-num " >{fundingCurrentStatus != null ? fundingCurrentStatus[1] == true ? separatorNumber(parseInt(refund100)) : "" : t('message.counting')}</div>
                                                    <span className="dollor">USDT</span>
                                                </div>

                                                <button className={`btn yellow-btn !w-auto ${claimed100 ? " grayscale !cursor-default" : " "}`} type="button" disabled={claimed100} onClick={() => claimFunding100()}>{claimed100 ? t('message.claimed') : t("message.withdrawal")}</button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            : ""
                    }

                    {/* <div className="bid-items">
                        <table className="inner bid-items-table">
                            <thead>
                                <tr>
                                    <th>{t("message.claimPeriod")}</th>
                                    <th>{t("message.claimValue")}</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td  >1-1</td>
                                    <td >1,000</td>
                                    <td ></td>

                                </tr>

                            </tbody>
                        </table>

                    </div> */}





                </div>
            </div>

        </section>


    </div>
}

export default Bid