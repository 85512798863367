// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOsuRaRoDINZYJCsHHET4miMU3uLfgBKI",
  authDomain: "paco-pro-472ec.firebaseapp.com",
  projectId: "paco-pro-472ec",
  storageBucket: "paco-pro-472ec.appspot.com",
  messagingSenderId: "788608080057",
  appId: "1:788608080057:web:40d1507efca5941e856368",
  measurementId: "G-7TDDJG6ERG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);