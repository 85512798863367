
export const menuData = [

]


export const currentRound = 1

export const needChainId = 56   // main
//export const needChainId = 97  // test

export const unit = 'ether'


export const history = [

    //{
    //  round:0,
    //  cryptoPacoAddress : "",
    //  pacoAddress : "",
    //  usdtAddress : "",
    //  fundingAddress : "",
    //  idoAddress : "",
    //  nftCenterAddress : "",
    //  pacoCenterAddress : "",
    //  pacoMountAddress : "",
    //  relationshipAddress : "",
    //  shareAddress : "",

    //},

]





let cryptoPaco = "0x14f489e5463c8e31052472406B1a4e459AB7762d"
let paco = "0x548b7E521a45Dbf505D2f16F47335D5f6aB13A18"
let usdt = "0x55d398326f99059fF775485246999027B3197955"
let funding = "0x3a1C71DE26a90Ed754475cF308c4B8E9a940A6f8"
let ido = "0xA3e70Fe60C12c5F4731080c063f8483e51428c34"
let nftCenter = "0xa1286bbf4B134F6BAdEB8127F1ae459A115FB9A3"
let pacoCenter = "0xC8F86c3fB5997E6F82560d5Aed45F840294c5289"
let pacoMount = "0x4798881DcfF3770672Ff180D540511D3C85eFf9F"
let relationship = "0x0aaEAE1f5fC1b9275Cf3F95B5451E55544d57d5c"
let share = "0xB5A527591Ee98f1fA8b9A9506c679641621eBde9"


export const usdtAddress = usdt
export const pacoAddress = paco

export const petAddress = pacoMount
export const heroAddress = cryptoPaco

export const petStakingAddress = nftCenter
export const heroStakingAddress = nftCenter

export const pacoStakingAddress = pacoCenter

export const pacoIDOAddress = ido

export const fundingAddress = funding


export const relationshipAddress = relationship
export const shareAddress = share


export async function addPacoToken(walletAddress) {
    const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
                address: pacoAddress, // The address that the token is at.
                symbol: "PACO", // A ticker symbol or shorthand, up to 5 chars.
                decimals: 18, // The number of decimals in the token
                image: "https://cryptopaco.s3.ap-southeast-1.amazonaws.com/token_logo.png", // A string url of the token logo
            },
        },
    });
    console.log(wasAdded)
    if (wasAdded) {
        localStorage.setItem("pacoToken", walletAddress)
    }
}

export async function addUSDTToken() {
    const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
                address: usdtAddress, // The address that the token is at.
                symbol: "USDT", // A ticker symbol or shorthand, up to 5 chars.
                decimals: 18, // The number of decimals in the token
                //image: "https://cryptopaco.s3.ap-southeast-1.amazonaws.com/token_logo.png", // A string url of the token logo
            },
        },
    });
    console.log(wasAdded)

}


export function separatorNumber(numb) {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
}

export function fixedFloor(numb, digit) {
    var a = 1
    for (let i = 0; i < digit; i = i + 1) {
        a *= 10
    }
    return calDiv(Math.floor(calMul(numb, a)), a)
}

export function calAdd(arg1, arg2) {
    let r1, r2, m
    try {
        r1 = arg1.toString().split('.')[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split('.')[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
}

export function calSub(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

export function calMul(arg1, arg2) {
    let m = 0
    let s1 = arg1.toString()
    let s2 = arg2.toString()
    try {
        m += s1.split('.')[1] ? s1.split('.')[1].length : ''
    } catch (e) { }
    try {
        m += s2.split('.')[1] ? s2.split('.')[1].length : ''
    } catch (e) { }
    return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
}

export function calDiv(arg1, arg2) {
    let t1 = 0
    let t2 = 0
    let r1
    let r2
    try {
        t1 = arg1.toString().split('.')[1].length
    } catch (e) { }
    try {
        t2 = arg2.toString().split('.')[1].length
    } catch (e) { }
    r1 = Number(arg1.toString().replace('.', ''))
    r2 = Number(arg2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
}
