import { menuData, brandLink, needChainId } from "../global/constants"
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useNavigate,
    useLocation
} from "react-router-dom";

import pacoImg from '../assets/images/paco.png'
import Wallet from '../compontents/Wallet'
import ClaimDialog from '../compontents/ClaimDialog'
import { addPacoToken } from '../global/constants'
import { useSelector, useDispatch } from 'react-redux';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { local } from "web3modal";

import Web3 from "web3"

const Header = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()

    const isFirst = useRef(true)

    const walletAddress = useSelector(state => state.walletAddress);
    var web3 = new Web3(Web3.givenProvider);

    const changeLanguage = (lang) => {
        console.log(i18n.language)
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        window.location.reload()
        // if (i18n.language == 'zh') {
        //     i18n.changeLanguage('en');
        // }
        // else {
        //     i18n.changeLanguage('zh');
        // }

    };

    function scrollToTop() {
        window.scrollTo(0, 0);
    }


    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }
    function isGamePath(path) {
        if (path == '/game') {
            return true
        }
        else if (path == '/mynft') {
            return true
        }
        else if (path == '/bid') {
            return true
        }
        else if (path == '/arena') {
            return true
        }
        else if (path == '/leaderboard') {
            return true
        }
        else if (path == '/dividend') {
            return false
        }
        else if (path == '/buy') {
            return true
        }
        else {
            return false
        }

    }


    async function check() {

        if (window && typeof window.ethereum !== 'undefined' && window.ethereum.on) {

            // const address = await web3.eth.getAccounts();
            const chainId = await web3.eth.getChainId();

            //const asd = await web3.eth.get
            //console.log(asd)
            if (walletAddress == null) {
                toast.warning(t('message.needConnectWallet'))
                navigate('/')
            }
            else if (chainId != needChainId) {
                toast.error(t('message.needChain'))

                if (needChainId == 97) {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [{
                            chainId: web3.utils.toHex(97),
                            rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
                            //rpcUrls: ["https://data-seed-prebsc-1-s2.binance.org:8545/"],
                            chainName: "Smart Chain - Testnet",
                            nativeCurrency: {
                                name: "BNB",
                                symbol: "BNB",
                                decimals: 18
                            },
                            blockExplorerUrls: ["https://testnet.bscscan.com"]
                        }]
                    });

                }
                else if (needChainId == 56) {
                    //window.ethereum.request({
                    //    method: 'wallet_switchEthereumChain',
                    //    params: [{ chainId: web3.utils.toHex(needChainId) }]
                    //});
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [{
                            chainId: web3.utils.toHex(56),
                            chainName: "BNB Smart Chain",
                            nativeCurrency: {
                                name: "Binance Chain Native Token",
                                symbol: "BNB",
                                decimals: 18
                            },
                            rpcUrls: [
                                "https://bsc-dataseed1.binance.org",
                                "https://bsc-dataseed2.binance.org",
                                "https://bsc-dataseed3.binance.org",
                                "https://bsc-dataseed4.binance.org",
                                "https://bsc-dataseed1.defibit.io",
                                "https://bsc-dataseed2.defibit.io",
                                "https://bsc-dataseed3.defibit.io",
                                "https://bsc-dataseed4.defibit.io",
                                "https://bsc-dataseed1.ninicoin.io",
                                "https://bsc-dataseed2.ninicoin.io",
                                "https://bsc-dataseed3.ninicoin.io",
                                "https://bsc-dataseed4.ninicoin.io",
                                "wss://bsc-ws-node.nariox.org"
                            ],
                            blockExplorerUrls: ["https://bscscan.com"]
                        }]
                    });
                }

                navigate('/')
            }

            if (chainId == needChainId && walletAddress != null && isFirst.current) {
                isFirst.current = false
                if (localStorage.getItem("pacoToken") && localStorage.getItem("pacoToken") == walletAddress) {
                    return
                }
                addPacoToken(walletAddress)
            }
        }
        else {
            toast.error(t('message.needConnectWallet'))
            navigate('/')
        }
    }

    function closeMenu() {
        document.getElementById("menu-link").checked = false;
    }




    useEffect(() => {
        console.log(location.pathname)
        console.log(walletAddress)
        //if (!isFirst.current) {
        //    isFirst.current = true
        if (walletAddress !== undefined) {
            check()
        }
        //}
        //asd()
    }, [location.pathname, walletAddress])

    useEffect(() => {


    }, [])


    return <div className="relative">
        <ToastContainer icon={false} position="top-left" toastClassName="!rounded !overflow-hidden" className="!w-[170px] !p-1" bodyClassName={() => "flex items-center"} />

        <nav className="navbar" >
            <div className="navbar-header">
                <div className="logo" ><Link onClick={() => scrollToTop()} to="/"></Link></div>
                <div className="mblang flex justify-center items-center z-10 lang absolute right-[60px] top-[0px] w-[60px] h-[60px]">
                    <input className="menu-lang-cheeckbox" id="menu-lang" type="checkbox" />
                    <a >
                        <label className="menu-lang" htmlFor="menu-lang">
                            <svg width="30" height="30" viewBox="0 0 512 512"><path d="M256 48h-.2H255.5C140.8 48.3 48 141.3 48 256s92.8 207.7 207.5 208h.5c114.9 0 208-93.1 208-208S370.9 48 256 48zm8.3 124.5c22.1-.6 43.5-3.5 64.2-8.5 6.2 24.5 10.1 52.8 10.7 83.8h-74.9v-75.3zm0-16.7V66c22.4 6.2 45.2 36.1 59.6 82-19.2 4.6-39.1 7.2-59.6 7.8zm-16.6-90v90.1c-20.7-.6-40.8-3.3-60.1-8 14.6-46.2 37.5-76.3 60.1-82.1zm0 106.7v75.2h-75.4c.6-31 4.5-59.3 10.7-83.8 20.8 5 42.5 8 64.7 8.6zm-92.2 75.2H64.9c1.8-42.8 17.8-82 43.3-113 18.5 10.2 38.2 18.6 58.8 24.8-6.8 26.5-10.8 56.4-11.5 88.2zm0 16.6c.6 31.7 4.6 61.7 11.4 88.2-20.6 6.3-40.2 14.6-58.8 24.8-25.5-31-41.4-70.2-43.3-113h90.7zm16.8 0h75.4v75.1c-22.2.6-43.9 3.6-64.7 8.7-6.2-24.5-10.1-52.8-10.7-83.8zm75.4 91.8v90.2c-22.6-5.9-45.5-35.9-60.1-82.1 19.3-4.8 39.4-7.5 60.1-8.1zm16.6 89.9v-90c20.5.6 40.4 3.3 59.7 7.9-14.5 46-37.2 75.9-59.7 82.1zm0-106.6v-75.1h74.9c-.6 30.9-4.5 59.2-10.7 83.7-20.7-5-42.1-8-64.2-8.6zm91.6-75.1h91.2c-1.8 42.8-17.8 81.9-43.3 113-18.7-10.3-38.5-18.7-59.3-25 6.8-26.5 10.8-56.3 11.4-88zm0-16.6c-.6-31.7-4.6-61.6-11.3-88.1 20.8-6.3 40.6-14.7 59.2-24.9 25.5 31 41.5 70.2 43.3 113.1h-91.2zm36.5-125.8c-16.6 8.8-34 16.1-52.3 21.6-9.7-31.3-23.4-56.8-39.5-73.6 35.4 8.5 67 26.9 91.8 52zM210.8 70.1c-16.1 16.7-29.7 42.2-39.3 73.3-18.1-5.5-35.4-12.7-51.8-21.5 24.5-25 55.9-43.3 91.1-51.8zM119.6 390c16.4-8.8 33.8-16 51.8-21.5 9.7 31.2 23.3 56.6 39.4 73.4-35.2-8.5-66.6-26.8-91.2-51.9zm181 52.1c16.2-16.8 29.8-42.3 39.6-73.7 18.3 5.5 35.7 12.8 52.3 21.6-24.8 25.2-56.5 43.6-91.9 52.1z"
                                fill="#FFFFFF" />
                            </svg>
                        </label>
                    </a>

                    <ul className="lang-btn ">


                        <li className="p-2 text-center"><a className=" hover:text-white text-white cursor-pointer" onClick={() => changeLanguage('en')}>English</a></li>
                        <li className="p-2 text-center"><a className="hover:text-white text-white cursor-pointer" onClick={() => changeLanguage('cn')}>简中</a></li>
                        <li className="p-2 text-center"><a className="hover:text-white text-white  cursor-pointer" onClick={() => changeLanguage('zh')}>繁中</a></li>
                    </ul>
                </div>
                <input className="menu-icon__cheeckbox" id="menu-link" type="checkbox" />
                <label className="menu-icon " htmlFor="menu-link" >
                    <div>
                        <span></span>
                        <span></span>
                    </div>
                </label>
                <div className="navbar-collapse">
                    <ul className="nav">
                        <li className={location.pathname == '/' ? "active" : ""}><Link onClick={() => closeMenu()} to="/">{t('message.home')}</Link></li>
                        <li className={isGamePath(location.pathname) ? "active" : ""}><Link onClick={() => closeMenu()} to="/bid">{t('message.game')}</Link></li>
                        <li className={location.pathname == '/market' ? "active" : ""}><Link onClick={() => closeMenu()} to="/market">{t('message.market')}</Link></li>
                        <li className={location.pathname == '/gitbook' ? "active" : ""}><a href={i18n.language == 'zh1' ? "https://cryptopaco.gitbook.io/crypto-paco-cn/" : "https://cryptopaco.gitbook.io/crypto-paco-en-1/"} target="_block">{t('message.crypto')}</a></li>
                        <li className={location.pathname == '/dividend' ? "active" : ""}><Link onClick={() => closeMenu()} to="/dividend">{t('message.recommend')}</Link></li>
                        <li className={location.pathname == '/bidhistory' ? "active" : ""}><Link onClick={() => closeMenu()} to="/bidhistory">{t('message.bidhistory')}</Link></li>
                    </ul>
                    <ul className="nav_other">
                        <li><Link onClick={() => closeMenu()} to="/buy" className="black-btn btn">{t('message.buy$PACO')}</Link></li>
                        <li><Link onClick={() => closeMenu()} to="/game" className="black-btn btn">{t('message.miltNFT')}</Link></li>
                        <li className="lang pclang">
                            <input className="menu-lang-cheeckbox" id="menu-lang" type="checkbox" />
                            <a >
                                <label className="menu-lang" htmlFor="menu-lang">
                                    <svg width="30" height="30" viewBox="0 0 512 512"><path d="M256 48h-.2H255.5C140.8 48.3 48 141.3 48 256s92.8 207.7 207.5 208h.5c114.9 0 208-93.1 208-208S370.9 48 256 48zm8.3 124.5c22.1-.6 43.5-3.5 64.2-8.5 6.2 24.5 10.1 52.8 10.7 83.8h-74.9v-75.3zm0-16.7V66c22.4 6.2 45.2 36.1 59.6 82-19.2 4.6-39.1 7.2-59.6 7.8zm-16.6-90v90.1c-20.7-.6-40.8-3.3-60.1-8 14.6-46.2 37.5-76.3 60.1-82.1zm0 106.7v75.2h-75.4c.6-31 4.5-59.3 10.7-83.8 20.8 5 42.5 8 64.7 8.6zm-92.2 75.2H64.9c1.8-42.8 17.8-82 43.3-113 18.5 10.2 38.2 18.6 58.8 24.8-6.8 26.5-10.8 56.4-11.5 88.2zm0 16.6c.6 31.7 4.6 61.7 11.4 88.2-20.6 6.3-40.2 14.6-58.8 24.8-25.5-31-41.4-70.2-43.3-113h90.7zm16.8 0h75.4v75.1c-22.2.6-43.9 3.6-64.7 8.7-6.2-24.5-10.1-52.8-10.7-83.8zm75.4 91.8v90.2c-22.6-5.9-45.5-35.9-60.1-82.1 19.3-4.8 39.4-7.5 60.1-8.1zm16.6 89.9v-90c20.5.6 40.4 3.3 59.7 7.9-14.5 46-37.2 75.9-59.7 82.1zm0-106.6v-75.1h74.9c-.6 30.9-4.5 59.2-10.7 83.7-20.7-5-42.1-8-64.2-8.6zm91.6-75.1h91.2c-1.8 42.8-17.8 81.9-43.3 113-18.7-10.3-38.5-18.7-59.3-25 6.8-26.5 10.8-56.3 11.4-88zm0-16.6c-.6-31.7-4.6-61.6-11.3-88.1 20.8-6.3 40.6-14.7 59.2-24.9 25.5 31 41.5 70.2 43.3 113.1h-91.2zm36.5-125.8c-16.6 8.8-34 16.1-52.3 21.6-9.7-31.3-23.4-56.8-39.5-73.6 35.4 8.5 67 26.9 91.8 52zM210.8 70.1c-16.1 16.7-29.7 42.2-39.3 73.3-18.1-5.5-35.4-12.7-51.8-21.5 24.5-25 55.9-43.3 91.1-51.8zM119.6 390c16.4-8.8 33.8-16 51.8-21.5 9.7 31.2 23.3 56.6 39.4 73.4-35.2-8.5-66.6-26.8-91.2-51.9zm181 52.1c16.2-16.8 29.8-42.3 39.6-73.7 18.3 5.5 35.7 12.8 52.3 21.6-24.8 25.2-56.5 43.6-91.9 52.1z"
                                        fill="currentColor" />
                                    </svg>
                                </label>
                            </a>

                            <ul className="lang-btn">


                                <li><a className=" cursor-pointer" onClick={() => changeLanguage('en')}>English</a></li>
                                <li><a className=" cursor-pointer" onClick={() => changeLanguage('cn')}>简中</a></li>
                                <li><a className=" cursor-pointer" onClick={() => changeLanguage('zh')}>繁中</a></li>
                            </ul>
                        </li>
                        <li className="wallet" onClick={() => closeMenu()} ><input className="menu-wallet-cheeckbox" id="menu-wallet" type="checkbox" />
                            <Wallet ></Wallet>
                        </li>

                    </ul>
                </div>




            </div>

        </nav>



        <ClaimDialog />

        <div className="community hidden">
            <ul>
                <li><a href="https://twitter.com/CryptoPaco" target="_block"><img src={require("../assets/images/twinter.png")} alt="" /></a></li>
                <li><a href="https://discord.gg/cHK3MbXHZT" target="_block"><img src={require("..//assets/images/dscc.png")} alt="" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCWmPDwpyz7n85R-QML4vi3A" target="_block"><img src={require("../assets/images/youtube.png")} alt="" /></a></li>
                <li><a href="https://www.instagram.com/crypto_paco/" target="_block"><img src={require("../assets/images/ins.png")} alt="" /></a></li>

            </ul>
        </div>


    </div>


}
export default Header