

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


import NavGame from "../../compontents/NavGame.js"

import Role from "../../compontents/Role.js"


import swordImg from '../../assets/images/sword.svg'


import demon1 from '../../assets/images/demon1.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3"
import axios from 'axios'

import { useSelector, useDispatch } from 'react-redux';

import { needChainId, usdtAddress, pacoAddress, heroAddress, petAddress, pacoIDOAddress, petStakingAddress, heroStakingAddress } from "../../global/constants"

const Mynft = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const loading = useSelector(state => state.loading);
    const walletAddress = useSelector(state => state.walletAddress);

    const [nftTypeChoose, setNftTypeChoose] = useState(t("message.myNFT_all"))
    const [nftStatusChoose, setNftStatusChoose] = useState(t("message.myNFT_all"))


    const [ownPetList, setOwnPetList] = useState([])

    const petAbi = require("../../assets/contract/pet.json");
    const heroAbi = require("../../assets/contract/hero.json");
    const usdtAbi = require("../../assets/contract/usdt.json");
    const pacoIDOAbi = require("../../assets/contract/ido.json");

    const petStakingAbi = require("../../assets/contract/petStaking.json");
    const heroStakingAbi = require("../../assets/contract/heroStaking.json");



    var web3 = new Web3(Web3.givenProvider);

    var PacoIDOContract = new web3.eth.Contract(
        pacoIDOAbi,
        pacoIDOAddress
    );

    var USDTContract = new web3.eth.Contract(
        usdtAbi,
        usdtAddress
    );

    var HeroContract = new web3.eth.Contract(
        heroAbi,
        heroAddress
    );

    var PetContract = new web3.eth.Contract(
        petAbi,
        petAddress
    );

    var PetStakingContract = new web3.eth.Contract(
        petStakingAbi,
        petStakingAddress
    );
    var HeroStakingContract = new web3.eth.Contract(
        heroStakingAbi,
        heroStakingAddress
    );


    const selectItem = useRef()


    const tempNFTList = useRef([])

    const handleSelNFT = (tokenId, contractId) => {
        const temp = ownPetList.map(item => {

            if (item.token_id === tokenId && item.token_address == contractId) {
                if (item.sel === true) {
                    return { ...item, sel: false };
                }
                else {
                    return { ...item, sel: true };
                }
            }
            return item;
        });

        setOwnPetList(temp);

    };




    const [closeTime, setCloseTime] = useState((1656504000) - Math.floor(Date.now() / 1000))

    const buyStage = 1

    const unit = 'ether'


    //btn.onClick =function(){
    //   menu.classList.toggle('active');
    //}

    function filterNFTTypeChoose(i) {
        if (nftTypeChoose == t("message.myNFT_all")) {
            return true
        }
        else if (nftTypeChoose == t("message.getNFT_MountNFT") && i.token_address == petAddress) {
            return true
        }
        else if (nftTypeChoose == "CryptoPacoNFT" && i.token_address == heroAddress) {
            return true
        }
        else {
            return false
        }

    }

    function filterNFTStatusChoose(i) {

        if (nftStatusChoose == t("message.myNFT_all")) {
            return true
        }
        else if (nftStatusChoose == t("message.onpledge") && i.stakingStatus != "0x0000000000000000000000000000000000000000") {
            return true
        }
        else if (nftStatusChoose == t("message.notpledge") && i.stakingStatus == "0x0000000000000000000000000000000000000000") {
            return true
        }
        else {
            return false
        }
    }


    async function get721Token() {

        try {
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: true }
            });
            tempNFTList.current = []
            //setOwnPetList([])
            await getTokenByContract(petAddress, PetContract, PetStakingContract)
            await getTokenByContract(heroAddress, HeroContract, HeroStakingContract)



            //tempNFTList.current = [...tempNFTList.current ,{token_uri:"api.cryptopaco.io/pet/0"},{token_uri:"api.cryptopaco.io/pet/1"},{token_uri:"api.cryptopaco.io/pet/0"},{token_uri:"api.cryptopaco.io/pet/1"}, ]
            //{token_uri:"api.cryptopaco.io/pet/0"}




            for (let i = 0; i < tempNFTList.current.length; i = i + 1) {

                for (let j = tempNFTList.current.length - 1; j > i; j = j - 1) {
                    //  console.log('i', i, tempNFTList.current[i])
                    //  console.log('j', j, tempNFTList.current[j])
                    if (i != j && tempNFTList.current[i] != undefined && tempNFTList.current[j] != undefined && tempNFTList.current[i].token_uri == tempNFTList.current[j].token_uri) {
                        console.log(tempNFTList.current.splice(j, j))
                        console.log(j)

                    }
                }
            }

            console.log(tempNFTList.current)
            setOwnPetList(tempNFTList.current)


        } catch (error) {
            console.log(error);
            //window.location.reload()
            //toast.error(t("toast.buy error3"));
        } finally {

            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: false }
            });
        }
    }

    async function getTokenByContract(selAddress, selContract, selStakingContract) {
        await getOwnerStakedList(walletAddress, selStakingContract, selContract, selAddress)
        console.log(walletAddress)


        let tempOwnerList = []


        if (selAddress == petAddress) {
            const getPet = await PetStakingContract.methods.getPacoMountOwned(walletAddress).call()
            //const getStakingPet = await PetStakingContract.methods.getPacoMountStaked(walletAddress).call()
            console.log(getPet)
            for (let i = 0; i < getPet.length; i++) {
                if (getPet[i]) {
                    tempOwnerList.push({ token_id: i })
                }
            }
        }

        else if (selAddress == heroAddress) {
            const getHero = await HeroStakingContract.methods.getCryptoPacoOwned(walletAddress).call()
            console.log(getHero)
            for (let i = 0; i < getHero.length; i++) {
                if (getHero[i]) {
                    tempOwnerList.push({ token_id: i })
                }
            }
        }
        // const getStakingHero = await HeroStakingContract.methods.getCryptoPacoStaked(walletAddress).call()

        console.log(tempOwnerList)
        //console.log(getStakingHero)

        let resVariants = await axios.all(
            tempOwnerList.map(async (item) => {
                console.log(item)
                if (selAddress == petAddress) {
                    console.log('ssss')
                    item.token_uri = await PetContract.methods.tokenURI(item.token_id).call()
                }
                else {
                    console.log('sdsd')
                    item.token_uri = await HeroContract.methods.tokenURI(item.token_id).call()
                    console.log(item.token_uri)
                }
                item.token_address = selAddress
                item.image = await getMetadata(item);
                item.stakingStatus = await getStakNFTStatus(item, selStakingContract, selAddress)
                return item
            }
            ))
        console.log(resVariants)

        tempNFTList.current = [...tempNFTList.current, ...resVariants,]


    }


    async function getOwnerStakedList(address, StakingContract, NFTContract, selAddress) {
        let ownPet = null

        if (selAddress == petAddress) {
            ownPet = await StakingContract.methods.getPacoMountStaked(address).call()
        }
        else {
            ownPet = await StakingContract.methods.getCryptoPacoStaked(address).call()
        }
        console.log(ownPet)
        let stakedList = []
        for (let i = 0; i < ownPet.length; i++) {
            let tokenURI = await NFTContract.methods.tokenURI(ownPet[i]).call()
            let item = { token_id: ownPet[i], token_uri: tokenURI, stakingStatus: walletAddress }
            item.image = await getMetadata(item);
            item.token_address = selAddress
            stakedList.push(item)
        }


        console.log(stakedList)
        tempNFTList.current = [...stakedList, ...tempNFTList.current]
        console.log(tempNFTList.current)
        //setOwnPetList(i => [...stakedList, ...i])


        //console.log(ownPetList)
        return ownPet
    }


    async function getOwnerNFTList(address, NFTAddress) {

        let json = {

        }
        const api_url = `https://deep-index.moralis.io/api/v2/${address}/nft?chain=bsc&format=decimal&token_addresses=${NFTAddress}&normalizeMetadata=false`
        console.log(api_url)

        let headers = {
            "Content-Type": "application/json",
            "X-API-Key": "YuEm0G2fq0ugF7QrEpFSl9dafhg2XNqQFalK6NDlwq4xqWWSABED162o2uv44P1N"
        }

        let response = await axios.get(api_url, { headers: headers })


        console.log(response.data.result)

        return response.data.result


    }

    function checkSelNFT() {
        let temp = []
        for (let i = 0; i < ownPetList.length; i = i + 1) {
            if (ownPetList[i].sel) {
                temp.push(ownPetList[i].token_id)
            }
        }
        console.log(temp)
        return temp
    }

    function cleanSelNFT() {
        let temp = []
        for (let i = 0; i < ownPetList.length; i = i + 1) {
            if (ownPetList[i].sel) {
                ownPetList[i].sel = false
            }
            temp.push(ownPetList[i])
        }
        setOwnPetList(temp)
    }

    async function getStakNFTStatus(item, StakingContract, stakingAddress) {

        if (stakingAddress == petAddress) {
            return await StakingContract.methods.pacoMountStaker(item.token_id).call()
        }
        else {
            return await StakingContract.methods.cryptoPacoStaker(item.token_id).call()
        }
    }

    async function stakNFT() {
        let tempSelPetList = []
        let tempSelHeroList = []
        for (let i = 0; i < ownPetList.length; i = i + 1) {
            if (ownPetList[i].sel && ownPetList[i].stakingStatus == "0x0000000000000000000000000000000000000000") {
                //console.log(ownPetList[i].token_address.toUpperCase())
                if (ownPetList[i].token_address.toUpperCase() == petAddress.toUpperCase()) {
                    tempSelPetList.push(ownPetList[i].token_id)  // token_address
                }
                if (ownPetList[i].token_address.toUpperCase() == heroAddress.toUpperCase()) {
                    tempSelHeroList.push(ownPetList[i].token_id)  // token_address
                }

            }
            //console.log(myUnStakingNFT[i].id)
        }

        console.log("asdadsasdasds", ownPetList)
        pledge()
        if (tempSelPetList.length == 0 && tempSelHeroList.length == 0) {
            toast.error(t("toast.chooseNFT"));
            return
        }




        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }


        try {
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: true }
            });
            // console.log(walletAddress, selectItem.current.token_id)
            //console.log(selectItem.current.token_address, heroAddress)
            //console.log(selectItem.current)

            console.log(tempSelPetList)
            console.log(tempSelHeroList)

            if (tempSelPetList.length != 0) {
                let approveNFT = await PetContract.methods.isApprovedForAll(walletAddress, petStakingAddress).call()
                if (approveNFT == false) {
                    await PetContract.methods.setApprovalForAll(petStakingAddress, true).send({
                        from: walletAddress,
                        gasPrice: await web3.eth.getGasPrice()
                    })
                }
                console.log("stakPacoMount")
                await PetStakingContract.methods.stakPacoMount(walletAddress, tempSelPetList).send({
                    from: walletAddress,
                    gasPrice: await web3.eth.getGasPrice()
                })
            }
            if (tempSelHeroList.length != 0) {
                let approveNFT = await HeroContract.methods.isApprovedForAll(walletAddress, heroStakingAddress).call()
                if (approveNFT == false) {
                    await HeroContract.methods.setApprovalForAll(heroStakingAddress, true).send({ from: walletAddress, gasPrice: await web3.eth.getGasPrice() })
                }
                await HeroStakingContract.methods.stakCryptoPaco(walletAddress, tempSelHeroList).send({
                    from: walletAddress,
                    gasPrice: await web3.eth.getGasPrice()
                })
            }


            toast.success(t("toast.stake success"));
            setTimeout(() => {
                window.location.reload()
            }, "2500");

            //await get721Token()


            //return toast.success(t("toast.stake success"));


        } catch (error) {
            console.log(error);
            if (error.code == 4001) {
                toast.warning(t("toast.stake cancel"));
            }
            else {
                toast.error(t("toast.stake error"));
            }

        } finally {
            cleanSelNFT()
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: false }
            });
        }
    }


    async function unStakNFT() {
        let allowClaim = await PetStakingContract.methods.allowClaim().call()
        console.log(allowClaim)
        if (allowClaim == false) {
            cleanSelNFT()
            unpledge()
            return toast.error(t("toast.notAllowClaimCenter"));
        }


        let tempSelPetList = []
        let tempSelHeroList = []
        for (let i = 0; i < ownPetList.length; i = i + 1) {
            if (ownPetList[i].sel && ownPetList[i].stakingStatus != "0x0000000000000000000000000000000000000000") {
                //console.log(ownPetList[i].token_address.toUpperCase())
                if (ownPetList[i].token_address.toUpperCase() == petAddress.toUpperCase()) {
                    tempSelPetList.push(ownPetList[i].token_id)  // token_address
                }
                if (ownPetList[i].token_address.toUpperCase() == heroAddress.toUpperCase()) {
                    tempSelHeroList.push(ownPetList[i].token_id)  // token_address
                }

            }
            //console.log(myUnStakingNFT[i].id)
        }

        console.log("asdadsasdasds", ownPetList)
        unpledge()
        if (tempSelPetList.length == 0 && tempSelHeroList.length == 0) {
            toast.error(t("toast.chooseStakedNFT"));
            return
        }


        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }


        try {
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: true }
            });
            //console.log(selectItem.current.token_address.toUpperCase())
            //console.log(heroAddress.toUpperCase())

            console.log(walletAddress)
            console.log(tempSelPetList)
            console.log(tempSelHeroList)

            if (tempSelPetList.length != 0) {
                await PetStakingContract.methods.claimPacoMount(walletAddress, tempSelPetList).send({
                    from: walletAddress,
                    gasPrice: await web3.eth.getGasPrice()
                })
            }
            if (tempSelHeroList.length != 0) {
                await HeroStakingContract.methods.claimCryptoPaco(walletAddress, tempSelHeroList).send({
                    from: walletAddress,
                    gasPrice: await web3.eth.getGasPrice()
                })
            }



            toast.success(t("toast.unstake success"));
            setTimeout(() => {
                window.location.reload()
            }, "2500");


            //await get721Token()

            //return 


        } catch (error) {
            console.log(error);
            if (error.code == 4001) {
                toast.warning(t("toast.unstake cancel"));
            }
            else {
                toast.error(t("toast.unstake error"));
            }

        } finally {
            cleanSelNFT()
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: false }
            });
        }

    }

    async function getMetadata(item) {
        console.log(item)
        const api_url = "" + item.token_uri
        console.log(api_url)
        let response = await axios.get(api_url)
        return response.data.image
    }

    function chooseNftType(type) {
        cleanSelNFT()
        setNftTypeChoose(type)
        select_1()
    }

    function chooseNftStatus(status) {
        cleanSelNFT()
        setNftStatusChoose(status)
        select_2()
    }


    let btn = document.querySelector('.game-btn');
    let menu = document.querySelector('.game-nav');
    let search_1 = document.querySelector('.select .search-1');
    let search_2 = document.querySelector('.select .search-2');
    let pledge_modal = document.querySelector('.pledge-check-modal');
    let unpledge_modal = document.querySelector('.unpledge-check-modal');
    //btn.onClick =function(){
    //   menu.classList.toggle('active');
    //}
    function select_1() {
        search_1.classList.toggle('check');
    }
    function select_2() {
        search_2.classList.toggle('check');
    }
    function pledge() {
        pledge_modal.classList.toggle('check');
    }
    function unpledge() {
        unpledge_modal.classList.toggle('check');
    }



    async function initAction() {
        //await getWalletAddress()

        get721Token()

    }

    useEffect(() => {

        btn = document.querySelector('.game-btn');
        menu = document.querySelector('.game-nav');
        search_1 = document.querySelector('.select .search-1');
        search_2 = document.querySelector('.select .search-2');
        pledge_modal = document.querySelector('.pledge-check-modal');
        unpledge_modal = document.querySelector('.unpledge-check-modal');

        if (walletAddress != null) {
            initAction()
        }




    }, [walletAddress]);


    return <div id="app">

        <div className="pledge-check-modal  modal-box">
            <div className="mask" onClick={() => pledge()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => pledge()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.myNFT_text_1")}</p>
                    <p className="text-[#E1BD7C]">【{t("message.upgrade")}】</p>
                    <div className="btn-box">
                        <a onClick={() => stakNFT()} className="btn yellow-btn">{t("message.yes")}</a>
                        <a onClick={() => pledge()} className="btn black-btn">{t("message.no")}</a>
                    </div>
                </div>

            </div>
        </div>
        <div className="unpledge-check-modal  modal-box">
            <div className="mask" onClick={() => unpledge()}></div>
            <div className="inner">
                <div className="closemodal" onClick={() => unpledge()}>
                    <svg width="40" height="40" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6Z" />
                    </svg>
                </div>
                <div className="modal-text">
                    <p>{t("message.myNFT_text_2")}</p>
                    <div className="btn-box">
                        <a onClick={() => unStakNFT()} className="btn yellow-btn">{t("message.yes")}</a>
                        <a onClick={() => unpledge()} className="btn black-btn">{t("message.no")}</a>
                    </div>
                </div>

            </div>
        </div>



        <section className="game-box">
            <div className="container">
                <NavGame></NavGame>
            </div>
            <div className="game-content">
                <div className="container">

                    <div className="game-content-head">
                        <Role></Role>
                        <div className="nft-search-box">
                            <p className="text">{t("message.myNFT_search")}</p>
                            <div className="select search-select ">
                                <a onClick={() => select_1()} className="select-1">{nftTypeChoose == "" ? t("message.myNFT_choose") : nftTypeChoose}<svg width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4Z" /></svg></a>
                                <div className="search-items search-1 ">
                                    <ul className="z-10 relative">
                                        <li onClick={() => chooseNftType(t("message.myNFT_all"))}>{t("message.myNFT_all")}</li>
                                        <li onClick={() => chooseNftType(t("message.getNFT_MountNFT"))}>{t("message.getNFT_MountNFT")}</li>
                                        <li onClick={() => chooseNftType("CryptoPacoNFT")}>CryptoPacoNFT</li>
                                        {/* <li onClick={() => chooseNftType(t("message.director_badge"))}>{t("message.director_badge")}</li>
                                        <li onClick={() => chooseNftType(t("message.map_txt_3_2"))}>{t("message.map_txt_3_2")}</li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="select select-pledge">
                                <a onClick={() => select_2()} className="select-2">{nftStatusChoose == "" ? t("message.myNFT_choose") : nftStatusChoose}<svg width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4Z" /></svg></a>
                                <div className="search-items search-2">
                                    <ul className="z-10 relative">
                                        <li onClick={() => chooseNftStatus(t("message.myNFT_all"))}>{t("message.myNFT_all")}</li>
                                        <li onClick={() => chooseNftStatus(t("message.onpledge"))}>{t("message.onpledge")}</li>
                                        <li onClick={() => chooseNftStatus(t("message.notpledge"))}>{t("message.notpledge")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="mt-20 flex justify-end items-center gap-6">
                            <p className="text-main font">{t('message.picked')} {ownPetList.filter(item => item.sel).length} {t('message.nftunit')}</p>

                            <div className="flex gap-3 items-center">

                                {
                                    nftStatusChoose == t('message.notpledge') || nftStatusChoose == t('message.myNFT_all') ?
                                        <button className={`w-full btn yellow-btn min-w-[80px]  ${ownPetList.filter(item => item.sel).length == 0 || ownPetList.filter(item => item.sel && item.stakingStatus != "0x0000000000000000000000000000000000000000").length != 0 ? " grayscale !cursor-default" : ""}`} disabled={ownPetList.filter(item => item.sel).length == 0 || ownPetList.filter(item => item.sel && item.stakingStatus != "0x0000000000000000000000000000000000000000").length != 0} onClick={() => [pledge()]}>{t("message.enter_pledge")}</button>
                                        :
                                        ""}
                                {
                                    nftStatusChoose == t('message.onpledge') || nftStatusChoose == t('message.myNFT_all') ?
                                        <button className={`w-full btn green-btn min-w-[80px] ${ownPetList.filter(item => item.sel).length == 0 || ownPetList.filter(item => item.sel && item.stakingStatus == "0x0000000000000000000000000000000000000000").length != 0 ? " grayscale !cursor-default" : ""}`} type="button" disabled={ownPetList.filter(item => item.sel).length == 0 || ownPetList.filter(item => item.sel && item.stakingStatus == "0x0000000000000000000000000000000000000000").length != 0} onClick={() => [unpledge()]}>{t("message.quit_pledge")}</button>
                                        : ""}
                            </div>
                        </div>

                    </div>



                    <div className="pledge-items">

                        <ul className="!justify-start">
                            {
                                ownPetList.map((i, index) => <li className={`${filterNFTTypeChoose(i) && filterNFTStatusChoose(i) ? "" : "hidden"}`} key={i.token_uri}>


                                    <div>
                                        <div className={`img ${i.sel ? "shadow-[0_0_6px_6px_rgb(255,206,61)]" : ""}  cursor-pointer`} onClick={() => handleSelNFT(i.token_id, i.token_address)}>

                                            <img src={i.image} alt="" />
                                            {
                                                i.stakingStatus == "0x0000000000000000000000000000000000000000" ?
                                                    "" :
                                                    <div className="sword"><img src={swordImg} alt="" /></div>
                                            }


                                        </div>
                                        {/*
                                                    i.stakingStatus == "0x0000000000000000000000000000000000000000" ?
                                                        <a className="btn yellow-btn" onClick={() => [selectItem.current = i, pledge()]}>{t("message.enter_pledge")}</a>
                                                        :
                                                        <a className="btn green-btn" onClick={() => [selectItem.current = i, unpledge(), console.log(i)]}>{t("message.quit_pledge")}</a>
                                                */ }

                                        {
                                            i.stakingStatus == "0x0000000000000000000000000000000000000000" ?
                                                <Link to="/market" className="btn black-btn" >{t("message.sell")}</Link>
                                                :
                                                <a className="btn black-btn disabled" href="###">{t("message.sell")}</a>
                                        }



                                    </div>

                                </li>
                                )}



                        </ul>
                    </div>
                </div>
            </div>

        </section>

    </div>


}

export default Mynft