import { menuData, brandLink } from "../global/constants"
import { useState,useRef, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";








const Timer = (props) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const location = useLocation()

    function titleType(){
      if (props.titleType == "1"){
        return t('message.timeline_caption_1')
      }
      else if (props.titleType == "2"){
        return t('message.timeline_caption_2')
      }
      else if(props.titleType == "3") {
        return t('message.timeline_caption_3')
      }
    }

    const timeCount = useRef(0)
    useEffect(() => {
        setTimeout(()=>timeCount.current += 1, 1000 )
        setCloseTime((props.closeTime - Math.floor(Date.now()/1000) ) > 0 ? (props.closeTime - Math.floor(Date.now()/1000)) : 0 )
    },[timeCount.current])


    const [closeTime,setCloseTime] = useState(0)


    useEffect(() => {
        //setTimeout(()=>closeTime  - 1 < 0 ?setCloseTime((i)=>i):setCloseTime((i)=>i-1), 1000 )
    },[closeTime])

    useEffect(() => {
     
      //setCloseTime((props.closeTime - Math.floor(Date.now()/1000) ) > 0 ? (props.closeTime - Math.floor(Date.now()/1000)) : 0  )
  },[props.closeTime])

    useEffect(() => {
      
        //asd()
    }, [])

    return <div className="time-box">
    <span className="light"></span>
    <div className="activityTime">
        <p>{titleType()}</p>
        <p> <span className="number">{Math.floor(closeTime/86400 ) < 10 ? `0${Math.floor(closeTime/86400 )}`: Math.floor(closeTime/86400 ) }</span>{t('message.timer_day')}<span className="colon">:</span>
            <span className="number">{Math.floor(closeTime%86400/3600 ) < 10 ?`0${Math.floor(closeTime%86400/3600 ) }` :Math.floor(closeTime%86400/3600 ) }</span>{t('message.timer_hour')}<span className="colon">:</span>
            <span className="number">{Math.floor(closeTime%3600/60 )< 10 ?`0${Math.floor(closeTime%3600/60 )}` :Math.floor(closeTime%3600/60 )}</span>{t('message.timer_minute')}<span className="colon">:</span>
            <span className="number">{closeTime%60 < 10 ?`0${closeTime%60}` :closeTime%60}</span>{t('message.timer_second')}
        </p>
    </div>
</div>

}
export default Timer