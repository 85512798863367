export const cn = {
  toast: {
    "mintedPet": "已经铸造过圣殿骑宠，限量一只",
    'switch bsc mainnet': "请使用BSC币安智能链",
    'stake success': "质押成功",
    'stake error': "质押失败",
    'stake cancel': "取消质押",
    'unstake success': "解除质押成功",
    'unstake error': "解除质押失败",
    'unstake cancel': "取消解除质押",
    'buy error': "购买失败",
    'buy error1': "购买失败",
    'buy success': "购买成功",
    'buy cancel': "取消购买",
    'connect wallet': "请先连结钱包",
    'Not enough token': "您没有足够的代币",
    'Not enough PACO': "您没有足够的PACO",
    'Not enough USDT': "您没有足够的USDT",
    'return calculating': "众筹失败，退回的金额需约30分钟计算中，请稍等。",
    'notAllowClaimCenter': "众筹失败后可解除质押",
    "chooseNFT": "请选择尚未质押的NFT",
    "chooseStakedNFT": "请选择已质押的NFT",
    "withdraw0PACO": "没有需要提款的PACO",
    "withdraw0USDT": "没有需要提款的USDT",
    "withdraw success": "提款成功",
    "withdraw fail": "提款失败",
    'withdraw cancel': "取消提款",
    "in bid Time": "众筹正在进行中，尚未众筹失败。",
    "bid submit success": "提交众筹成功",
    "bid submit fail": "提交众筹失败",
    "bid submit cancel": "取消提交众筹",
    "bid rule1": "参加众筹游戏需要最少 100 USDT",
    "bid rule2": "参加众筹游戏需要百位整数的USDT",

    "waitbid": "众筹结束后开放",

    "noNetwork": "网路异常，请刷新网站重试",

    "copySuccess":"复制成功"

  },
  message: {
    importToken:"匯入",
    showAd1Title: "请铸造一只NFT并质押以便开始游戏",
    showAd1Option1: "前往铸造",
    showAd1Option2: "前往质押",
    $PACO: "购买 $PACO",
    loading: '处理中',
    needInputAddress: "请贴上正确的地址",
    needConnectWallet: "请先连结钱包",
    needChain: "请使用BSC币安智能链",
    timer_day: "天",
    timer_hour: "时",
    timer_minute: "分",
    timer_second: "秒",
    partClaim: "强制出金",
    allClaim: "100%出金",
    claimPeriod: "已出金期数",
    claimValue: "出金金额",


    clickOpen: "点击开启",
    picked: "已选择",
    nftunit: "张",

    dividendCue1: "目前未能获得红利",
    dividendCue2: "目前只能获得",
    dividendCue3: "之红利",
    dividendCue4: "目前能获得",

    // 歷史眾籌
    bidhistory_title: '待领金额',
    claimed: "已提款",

    // 主选单
    home: '首页',
    game: '游戏',
    market: '市场',
    crypto: '白皮书',
    recommend: '我的推荐',
    buy$PACO: '购买 $PACO',
    miltNFT: '铸造 NFT',
    linkWallet: '连结钱包',

    // 时间
    timeline_caption_1: '游戏开始剩余时间',
    timeline_caption_2: '结束时间倒数',
    timeline_caption_3: '游戏已开始 倒数',
    timeline_caption_4: '众筹结束剩余时间',
    timeline_day: '天',
    timeline_hour: '时',
    timeline_minute: '分',
    timeline_second: '秒',
    start: '开始',
    end: '结束',
    reciprocal: '倒数',
    finish: '完成',

    //共用
    yes: '是',
    no: '否',
    confirm: '确认',
    cancel: '取消',
    understand: '了解',
    submit: '提交',

    // 首页选单
    caption: '说明',
    characters: '召唤角色',
    features: '特点',
    map: '路线图',
    team: '合作伙伴及投资者',

    // 首页banner
    banner_caption_1: '欢迎来到 CryptoPaco',
    banner_caption_2: '永续循环的游戏',
    banner_caption_3: '实现真正的边玩边赚',
    start_game: '开始游戏',

    // 首页说明
    caption_text_1: '「Crypto Paco是一个创建在Binance链上的NFT艺术品，搜藏家可以进行召唤种族当您购买CryptoPaco NFT时，您不仅仅是购买一件艺术品，而是让Paco成为您的信仰，我们将提供最高60%利润回馈所有推广者，另外增加定期举办圣殿众筹、竞技赛、抽奖、国家会议、联盟，让持有CryptoPaco NFT的您享有最大的利润，甚至有机会参与世界殿堂大会享受至高无上的荣誉。',
    caption_text_2: '随着CryptoPaco NFT持有者的人数增加，创世神也将有规律地增加持有难度，智能合约写入调涨机制，使早期持有者享受丰硕的果实。 」',

    // 召唤角色
    characters_name_1: '精灵军团',
    characters_name_2: '圣骑士军团',
    characters_name_3: '魔族军团',

    mounts_name_1: '星空骑宠',
    mounts_name_2: '大地骑宠',
    mounts_name_3: '蓝天骑宠',

    // 特点
    features_title_1: '圣殿众筹游戏',
    features_text_1_1: '你可以使用USDT游玩圣殿众筹游戏',
    features_text_1_2: '众筹成功将获得10%~15%奖励',

    features_title_2: '铸造 NFT',
    features_text_2_1: 'MountNFT提供10,000张免费铸造',
    features_text_2_2: '可以使用USDT铸造专属于你的CryptoPacoNFT',

    features_title_3: '全球股东',
    features_text_3_1: '质押PACOCoin为CryptoPaco的股东',
    features_text_3_2: '依照持有数量获得项目的利润',

    features_title_4: '联盟',
    features_text_4_1: '只要是三大种族的「使者」都可以创建自己的联盟，也开放所有阶级的三大种族都可参加',

    features_title_5: '社交',
    features_text_5_1: '你可以与全球玩家一起边玩边赚游戏',
    features_text_5_2: '并且可以与全世界玩家合作赢取奖金',

    features_title_6: '竞技场',
    features_text_6_1: '你可以参与宙斯碎片、智慧之泉暗黑之神的逆袭、创世联盟战等活动',
    features_text_6_2: '总奖金超过15亿美元',

    // 路线图
    map_title_1: '第一阶段',
    map_txt_1_1: 'ICO启动',
    map_txt_1_2: '测试网站上线',
    map_txt_1_3: '启动社交媒体',
    map_txt_1_4: 'Paco Mounts NFT白名单部属',

    map_title_2: '第二阶段',
    map_txt_2_1: '智能合约部属最终测试',
    map_txt_2_2: '正式网站上线',
    map_txt_2_3: 'Paco Mounts NFT开放铸造',
    map_txt_2_4: '圣殿建造众筹启动',
    map_txt_2_5: '开启代币股份功能',

    map_title_3: '第三阶段',
    map_title_3_h4: 'CryptoPacoNFT白名单部属 ',
    map_title_3_p: 'CryptoPacoNFT开放铸造',
    map_txt_3_1: '智慧之泉',
    map_txt_3_2: '宙斯碎片',
    map_txt_3_3: '黑暗之神的逆袭',
    map_txt_3_4: '创世联盟战',

    map_title_4: '第四阶段',
    map_txt_4_1: 'NFT市场',
    map_txt_4_2: 'DeFi金融部属',
    map_txt_4_3: '开启世界殿堂会议',
    map_txt_4_4: '专属APP启动计画',

    map_title_5: '第五阶段',
    map_txt_5_1: '区块链土地模式',
    map_txt_5_2: 'NFT虚实整合平台',

    //游戏选单
    getNFT: '获得NFT',
    myNFT: '我的NFT',
    bid: '众筹游戏',
    arena: '竞技场',
    leaderboard: '排行榜',
    dividend: '红利',
    role: '称号',
    adventurer: "勇者",
    brave: '军团',
    envoys: '使者',
    prophet: '先知',
    holySpirit: '圣灵',
    norole: '无',
    bidhistory: "待领金额",

    // 获得NFT
    getNFT_title: '立即获得属于你的NFT',
    getNFT_MountNFT: '圣殿骑宠',
    free_milt: '免费铸造',
    NFT_price: '现在价格',
    NFT_unit: '只',
    milt: '铸造',
    miltx3: '铸造3只',
    copy: '复制邀请',
    paste: '贴上邀请',
    getNFT_modal_1: '铸造完成后是否直接质押游戏？ ',
    getNFT_modal_2: '目前铸造人数已满，于UTC+ 0重置',

    // 动画
    getNFT_text_1: '恭喜您获得NFT',
    getNFT_text_2: '到',
    getNFT_text_3: '进行质押？ ',
    getNFT_text_4: '是否直接质押游戏？ ',

    // $PACO
    buy_title: '预售',
    overage: '余额',
    max: '最大值',
    buy: '购买',
    pledge: '质押',
    onpledge: '已质押',
    notpledge: '未质押',
    pledge_remove: '解除质押',
    remove: '解除',
    fixed: '锁定',
    pledge_title: '质押、赚取、以及更多惊喜',
    pledge_use: '可用',
    stock: '股份比例',
    pledge_total: '质押总计',
    profit: '利润',
    extract_profit: '提取利润',
    buy_text_1: '购买完成后是否直接质押游戏？ ',
    buy_text_2: '目前无法购买，于UTC+ 0重置',
    buy_success: '购买成功',

    // 我的NFT
    myNFT_text_1: '您是否确认质押？质押NFT将进入游戏并可能为您带来收益',
    myNFT_text_2: '您是否确认退出质押？退出质押后您的收益可能将会停止',
    myNFT_search: 'NFT搜寻',
    myNFT_choose: '选择NFT',
    myNFT_all: '全部NFT',
    director_badge: '世界董事徽章',
    enter_pledge: '进入质押',
    quit_pledge: '退出质押',
    sell: '卖出',

    // 我的推荐+红利
    my_recommend: '我的推荐',
    share_name: '分享',
    my_bonus: '我的红利',
    bonus_claimed: '已领红利',
    my_pet_bonus: '我的众筹红利',
    pet_bonus_claimed: '已领众筹红利',
    my_paco_bonus: '我的NFT红利',
    paco_bonus_claimed: '已领NFT红利',
    bonus_unaccalimed: '未领红利',
    withdrawal: '提款',
    bid_temple: '圣殿众筹红利',
    bid_CryptoPaco: 'CryptoPacoNFT红利',
    person_num: '人数',
    current_bonus: '本期红利',
    total_bonus: '累计红利',
    first: '第一代',
    second: '第二代',
    third: '第三代',
    fourth: '第四代',
    fifth: '第五代',
    sixth: '第六代',
    seventh: '第七代',
    eighth: '第八代',
    ninth: '第九代',
    tenth: '第十代',

    gen1: '第一代',
    gen2: '第二代',
    gen3: '第三代',
    gen4: '第四代',
    gen5: '第五代',
    gen6: '第六代',
    gen7: '第七代',
    gen8: '第八代',
    gen9: '第九代',
    gen10: '第十代',
    copy_title: '我的邀请连结',
    total: '总计',
    arena_bonus: '竞技场红利',
    world_bonus: '世界董事红利',


    // 众筹游戏
    bid_title_1: '第',
    bid_title_2: '期',
    bid_title_3: '众筹',
    bid_title_4: '轮次',
    bided: '已投入',
    period: '期数',
    bid_amount: '总众筹金额',
    own: '当前拥有',
    total: "总计",
    initial_period: '初始期数',
    initial_principal: '当期投入本金',
    current_amount: '当前金额',
    expected_profit: '预计利润',
    withdraw: '出金',
    withdrawable: '可出金',
    apply_withdraw: '申请出金',
    waiting: '等待中',
    bid_user_num: '用户数',
    bid_trade_num: '交易数',
    operate: '操作',
    progress_percent: "完成进度",
    progress_done: "已完成",
    progress_undone: "未完成",
    counting: "计算中",


    // 提交
    bid_modal_1_text_1: '您是否确认参加众筹',
    bid_modal_1_text_2: '参与众筹往后每期将自动复投',
    bid_modal_2_text: '众筹成功',
    bid_modal_3_text: '参与众筹最低金额为',
    bid_modal_4_text_1: '您已超出众筹参与金额，请按',
    bid_modal_4_text_2: '获得CryptoPacoNFT',
    bid_modal_4_text_3: '并质押或于下期可再次参与众筹',
    bid_modal_5_text_1: '本期众筹已满额，下期请尽早抢购',
    // 出金
    bid_modal_6_text_1: '您可选择出金方式',
    bid_modal_6_text_2: '出金将收取3%智能合约调用费',
    full_staked: '100%出金',
    obliged_staked: '强制出金',
    full_staked_text: '您需要等待3期众筹成功后出金',
    obliged_staked_text_1: '强制出金三期退回70%众筹金额，',
    obliged_staked_text_2: '您可出金的金额为',
    withdrawTitle: "众筹失败，您可以透过下方领取本金及利息",
    withdrawTitle2: "*A new round of crowdfunding games is about to start, the first phase starts with an amount of 10,000 USDT, please snap up",
    withdraw70: "期众筹失败，你能收回金额",
    withdraw100: "投入后当期众筹失败，将100%收回金额",

    upgrade:"达到升级条件后再次进行众筹游戏即可升级角色"

  },
  claimDialog: {
    claim1: "提取成功",
    claim2: "共提取",
    confirm: "确认",
  }
}