

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useNavigate
} from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Web3 from "web3"



import { unit, needChainId, usdtAddress, heroAddress, petAddress, petStakingAddress, heroStakingAddress } from "../../global/constants"


import sprite1 from '../../assets/images/sprite1.png'


import warrior1 from '../../assets/images/warrior1.png'

import demon1 from '../../assets/images/demon1.png'

import { useSelector, useDispatch } from 'react-redux';


const Animation = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()

    const dispatch = useDispatch();

    const loading = useSelector(state => state.loading);
    const walletAddress = useSelector(state => state.walletAddress);
    const petAbi = require("../../assets/contract/pet.json");
    const heroAbi = require("../../assets/contract/hero.json");
    const petStakingAbi = require("../../assets/contract/petStaking.json");
    const heroStakingAbi = require("../../assets/contract/heroStaking.json");


    var web3 = new Web3(Web3.givenProvider);




    var PetContract = new web3.eth.Contract(
        petAbi,
        petAddress
    );

    var HeroContract = new web3.eth.Contract(
        heroAbi,
        heroAddress
    );

    var PetStakingContract = new web3.eth.Contract(
        petStakingAbi,
        petStakingAddress
    );
    var HeroStakingContract = new web3.eth.Contract(
        heroStakingAbi,
        heroStakingAddress
    );

    const selectItem = useRef({})

    const [mintHeroList, setMintHeroList] = useState([])
    const [showClickText, setShowClickText] = useState(true)

    const [showAd1, setShowAd1] = useState(false)
    const [showAd2, setShowAd2] = useState(false)
    const [showAd3, setShowAd3] = useState(false)

    let card_box = document.querySelector('.card-box');
    let toggle = document.querySelector('.toggle');
    let animation_modal = document.querySelector('.animation-modal');

    const temp = JSON.parse(localStorage.getItem('mintCard'))


    function cards() {
        console.log("cards")
        card_box.classList.add('active');
        toggle.classList.add('change');
    };
    function check() {
        animation_modal.classList.add('check');

    };

    function openCard() {
        setShowClickText(false)
        setTimeout(cards, 500);
        setTimeout(check, 2700);

    }


    async function stakNFT() {

        if (loading == true) {
            return
        }

        const chainId = await web3.eth.getChainId();

        if (chainId != needChainId) {
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }


        try {
            dispatch({
                type: "UPDATE_LOADING",
                payload: { loading: true }
            });
            //console.log(walletAddress, selectItem.current.token_id)
            //console.log(selectItem.current.token_address, heroAddress)
            //console.log(selectItem.current.token_address)

            console.log(selectItem.current)


            if (temp.assets[0].token_address.toUpperCase() == petAddress.toUpperCase()) {
                let approveNFT = await PetContract.methods.isApprovedForAll(walletAddress, petStakingAddress).call()
                if (approveNFT == false) {
                    await PetContract.methods.setApprovalForAll(petStakingAddress, true).send({ from: walletAddress, gasPrice: await web3.eth.getGasPrice() })
                }
                await PetStakingContract.methods.stakPacoMount(walletAddress, selectItem.current).send({
                    from: walletAddress,
                    gasPrice: await web3.eth.getGasPrice()
                })
            }
            else if (temp.assets[0].token_address.toUpperCase() == heroAddress.toUpperCase()) {
                let approveNFT = await HeroContract.methods.isApprovedForAll(walletAddress, heroStakingAddress).call()
                if (approveNFT == false) {
                    await HeroContract.methods.setApprovalForAll(heroStakingAddress, true).send({ from: walletAddress, gasPrice: await web3.eth.getGasPrice() })
                }
                await HeroStakingContract.methods.stakCryptoPaco(walletAddress, selectItem.current).send({
                    from: walletAddress,
                    gasPrice: await web3.eth.getGasPrice()
                })
            }


            //get721Token()
            //navigate('/mynft')


            //return toast.success(t("toast.buy success"));


        } catch (error) {
            console.log(error);
            if (error.code == 4001) {
                toast.warning(t("toast.stake cancel"));
            }
            else {
                toast.error(t("toast.stake error"));
            }
        } finally {
            //  dispatch({
            //      type: "UPDATE_LOADING",
            //      payload: { loading: false}
            //  });
        }

    }

    async function stakingNFTAction() {


        if (temp.assets.length == 1) {
            selectItem.current = [temp.assets[0].token_id]
            await stakNFT()
        }
        else if (temp.assets.length == 3) {
            selectItem.current = [temp.assets[0].token_id, temp.assets[1].token_id, temp.assets[2].token_id]
            await stakNFT()

        }

        //toast.success(t("toast.buy success"));
        navigate('/mynft')

        dispatch({
            type: "UPDATE_LOADING",
            payload: { loading: false }
        });






    }


    useEffect(() => {
        card_box = document.querySelector('.card-box');
        toggle = document.querySelector('.toggle');
        animation_modal = document.querySelector('.animation-modal');
        //setTimeout(cards, 500);
        //setTimeout(check, 2700);
        const temp = JSON.parse(localStorage.getItem('mintCard'))

        if (temp != null) {
            console.log(temp)
            setMintHeroList(temp.assets)
            if (temp.assets.length == 1) {
                // setMintHeroList([demon1])
            }
            else if (temp.assets.length == 3) {
                //  setMintHeroList([demon1,warrior1,sprite1])
            }
        }


    }, []);




    return <div id="app">
        <div className="wrap content_block">

            <div className="animation">

                <div className="card-box">

                    <div className="toggle " >
                        <span className="front-face"></span>
                        <span className="back-face"></span>
                    </div>
                    <div className="animation-modal">


                        <div className="modal-content">
                            <div className="border">
                                <Link className="close" to="/game"><svg width="44" height="44" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path fill="currentColor" d="m12 13.414l5.657 5.657a1 1 0 0 0 1.414-1.414L13.414 12l5.657-5.657a1 1 0 0 0-1.414-1.414L12 10.586L6.343 4.929A1 1 0 0 0 4.93 6.343L10.586 12l-5.657 5.657a1 1 0 1 0 1.414 1.414L12 13.414Z" /></g></svg></Link>
                                <div className="content-text ">

                                    <div className="img-items !my-4">
                                        {
                                            mintHeroList.map(i => <div key={i}><img src={i.img} alt="" /> </div>
                                            )}
                                    </div>

                                    <p>{t("message.getNFT_text_1")}<br />{t("message.getNFT_text_4")}<br/><span className="text-[#E1BD7C]">【{t("message.upgrade")}】</span></p>


                                   
                                    {/* <p>{t("message.getNFT_text_1")}<br />{t("message.getNFT_text_2")}「{t("message.myNFT")}」{t("message.getNFT_text_3")}</p> */}
                                    <div className="btn-box">
                                        <a onClick={() => stakingNFTAction()} className="btn yellow-btn">{t("message.yes")}</a>
                                        <Link to="/game" className="btn black-btn">{t("message.no")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ul onClick={() => openCard()}>
                        {
                            showClickText ?
                                <div style={{ "textShadow": "0px 0px 15px #AAAA00 , 2px 2px 3px #000000" }} className=" pointer-events-none text-[#ffc517] font-bold text-2xl absolute left-1/2 bottom-0 transform -translate-x-1/2 -translate-y-1/2 z-[9999]">
                                    {t('message.clickOpen')}
                                </div> : ""

                        }

                        <li style={{ "--i": "0" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "1" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "2" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "3" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "4" }}><a href="javascript:;"></a></li>
                    </ul>
                    <ul onClick={() => openCard()}>
                        <li style={{ "--i": "0" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "1" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "2" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "3" }}><a href="javascript:;"></a></li>
                        <li style={{ "--i": "4" }}><a href="javascript:;"></a></li>

                    </ul>



                </div>

            </div>
        </div>
    </div>
}

export default Animation