//import Web3 from "web3";

import { useState, useEffect } from "react";

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from 'react-redux';

import { relationshipAddress, needChainId } from "../global/constants"

import Web3 from "web3"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate
} from "react-router-dom";

const providerOptions = {
  // walletconnect: {
  //   package: WalletConnect, // required
  //   options: {
  //   //  infuraId: 'process.env.INFURA_KEY' // required
  //   }
  // }
};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "light"
});

var web3 = new Web3(Web3.givenProvider);



export default function Wallet() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const relationshipAbi = require("../assets/contract/relationship.json");




  var relationshipContract = new web3.eth.Contract(
    relationshipAbi,
    relationshipAddress
  );


  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();


  const [loading, setLoading] = useState(false);

  const connectWallet = async () => {
    try {
      if (typeof window.ethereum === 'undefined') {
        toast.error(t('toast.noNetwork'))
        return
      }

      const provider = await web3Modal.connect();
      const library = await new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      //const accounts = [""]
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      //alert(accounts)
      checkRole(accounts[0])
      dispatch({
        type: "UPDATE_WALLET",
        payload: { walletAddress: accounts[0] }
      });
      setChainId(network.chainId);
    } catch (error) {
      toast.error(t('toast.noNetwork'))
      setError(error);

    }
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    dispatch({
      type: "UPDATE_WALLET",
      payload: { walletAddress: null }
    });
    dispatch({
      type: "UPDATE_ROLE",
      payload: { role: 'norole' }
    });
    refreshState();
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };


  function btnfunction() {


    if (!account) {
      connectWallet()
    }
    else {
      disconnect()
    }
  }

  async function checkRole(selAddress) {
    try {
      // dispatch({
      //   type: "UPDATE_LOADING",
      //   payload: { loading: true }
      // });

      let isHolySpirit = await relationshipContract.methods.isHolySpirit(selAddress).call()
      if (isHolySpirit) {
        dispatch({
          type: "UPDATE_ROLE",
          payload: { role: 'holySpirit' }
        });
        return
      }

      let isProphet = await relationshipContract.methods.isProphet(selAddress).call()
      if (isProphet) {
        dispatch({
          type: "UPDATE_ROLE",
          payload: { role: 'prophet' }
        });
        return
      }
      let isEnvoys = await relationshipContract.methods.isEnvoys(selAddress).call()
      if (isEnvoys) {
        dispatch({
          type: "UPDATE_ROLE",
          payload: { role: 'envoys' }
        });
        return
      }
      let isArmy = await relationshipContract.methods.isArmy(selAddress).call()
      console.log(isArmy)
      if (isArmy) {
        dispatch({
          type: "UPDATE_ROLE",
          payload: { role: 'brave' }
        });
        return
      }
      let isAdventurer = await relationshipContract.methods.isAdventurer(selAddress).call()
      console.log(isAdventurer)
      if (isAdventurer) {
        dispatch({
          type: "UPDATE_ROLE",
          payload: { role: 'adventurer' }
        });
        return
      }


      dispatch({
        type: "UPDATE_ROLE",
        payload: { role: 'norole' }
      });




    } catch (error) {
      console.log(error);
      dispatch({
        type: "UPDATE_ROLE",
        payload: { role: "norole" }
      });

    } finally {

    }

  }



  useEffect(() => {


    if (window && typeof window.ethereum !== 'undefined' && window.ethereum.on) {
      window.ethereum.on("accountsChanged", (accounts) => {
        //console.log('accountsChanged')
        //if (account == undefined){
        //connectWallet()
        //}
        window.location.reload();
      })

      window.ethereum.on("chainChanged", (chainId) => {
        //console.log('accountsChanged')
        if (web3.utils.hexToNumber(chainId) != needChainId) {
          toast.error(t('message.needChain'))
          navigate('/')

        }
      })

      // window.ethereum.on("disconnect", (accounts) => {
      //   //console.log('disconnect')
      //   disconnect()
      // })
      if (localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')) {
        connectWallet()
      }
      else {
        disconnect()
      }
    }


  }, [])
  return <a className="btn yellow-btn" onClick={() => btnfunction()}><label className="menu-wallet" >{!account ? t('message.linkWallet') : account.substring(0, 4) + "..." + account.substring(account.length - 4, account.length)}</label></a>



}

