import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from "react-i18next";

import CountUp from 'react-countup';
import { useSelector, useDispatch } from 'react-redux';
import claimGIF from '../assets/images/claimGIF.gif'
import bgImg from '../assets/images/bg5.png'



const ClaimDialog = (props) => {
  //const { onClose, selectedValue, open } = props;
  const { t } = useTranslation();

  const claimDialog = useSelector(state => state.claimDialog);
  const dispatch = useDispatch()



  const handleClose = () => {
    //onClose();

    dispatch({
      type: "UPDATE_CLAIMDIALOG",
      payload: { claimDialog: null }
    });
  };

  //const theme = useTheme();

  return <Dialog
    PaperProps={{
      style: {
        width: "400px",
        backgroundColor: 'transparent'
      },
    }}
    onClose={handleClose} open={claimDialog != null}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <img className=' absolute left-0 top-0 object-cover w-full h-full ' src={bgImg} />
    {
      claimDialog != null ?
        <div className='relative'>
          <div className=" rounded-xl z-10  border border-main border-solid">

            <DialogTitle className="flex justify-center items-center  ">

              <img className="w-[120px] object-contain" src={claimGIF} />
            </DialogTitle>
            <DialogContent>
              <div className="text-center pt-2 pb-4">
                <p className='text-white  font-bold mb-2'>{t("claimDialog.claim1")}</p>
                <p className='text-gray-300 text-xs mb-2'>{t("claimDialog.claim2")}</p>

                <CountUp
                  start={0}
                  end={claimDialog.value}
                  duration={2.75}
                  decimals={2}
                  delay={0}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <p className=' text-green-500 font-bold text-3xl text-center'>+<span ref={countUpRef} /> <span className='text-sm font-normal'>{claimDialog.currency}</span></p>
                    </div>
                  )}
                </CountUp>

              </div>

              <div>
                <button onClick={() => handleClose()} className="btn yellow-btn w-full text-white ">{t("claimDialog.confirm")}</button>
              </div>
            </DialogContent>

          </div>
        </div>
        : ""
    }
  </Dialog>

}

export default ClaimDialog