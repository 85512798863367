import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';

const Loading = () => {
    const { t } = useTranslation();
    const loading = useSelector(state => state.loading);
    

    return <>
        {
            loading ?
                <div className="fixed flex z-[99999] left-0 top-0 bg-black bg-opacity-75 w-screen h-screen">
                 <div className="m-auto text-[#FFCE3E] font-black text-3xl">
                     {t('message.loading')}...

                 </div>
                </div>
                : ""
        }

    </>

}
export default Loading