import { brandLink } from "../global/constants"
import { useTranslation } from "react-i18next";
import moment from "moment"

const Footer = () =>{
  const { t } = useTranslation();
  

    return <footer>
      <div className="container">
        <div className="txt">
            <p>Copyright © {moment().year()} CryptoPaco All Rights Reserved</p>
            <p className="copy">Incubated By <a href="https://www.photonic.com.tw/" target="_block">Photonic</a></p>
        </div>
    </div>
    </footer>
}
export default Footer